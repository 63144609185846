import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import ProgressChart from "../../components/ProgressChart/index";
import ProgressCount from "../../components/ProgressCount/index";
import DateFilter from "../../components/DateFilter/index";
import SliderFilter from "../../components/SliderFilter";
import Iconone from "../../assets/img/i1.svg";
import Icontwo from "../../assets/img/i2.svg";
import Iconthree from "../../assets/img/i3.svg";
import Iconfour from "../../assets/img/i4.svg";
import {
  setAirlines,
  setHeadCount,
  setFooterHome,
  setEhrEvent,
} from "../../store/actions/Compensate";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import ContentLoader from "react-content-loader";
import { SocketContext } from "../../socket";
import moment from "moment";
import { ChartExample } from "../../components/shared/PieChart/m-pei-chart";
import { ThemeContext } from "../../providers/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from '../../assets/img/pslogo.png';
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";

/* const notify = {
    employee_id: 503613,
    cost_center_number: 54986,
    severity_description: "Warning",
    exception_description: "Employee had Perfect Attendance",
    transaction_date: "27-03-2023",
    hours: 7,
} */

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 280 97.82"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" width="45.5" height="46" />
    <rect x="46.5" y="2" rx="2" ry="2" width="45.5" height="46" />
    <rect x="93" y="2" rx="2" ry="2" width="45.5" height="46" />
    <rect x="139.5" y="2" rx="2" ry="2" width="45.5" height="46" />
    <rect x="186" y="2" rx="2" ry="2" width="45.5" height="46" />
    <rect x="232.5" y="2" rx="2" ry="2" width="45.5" height="46" />
    <rect x="0" y="49" rx="2" ry="2" width="45.5" height="46" />
    <rect x="46.5" y="49" rx="2" ry="2" width="45.5" height="46" />
    <rect x="93" y="49" rx="2" ry="2" width="45.5" height="46" />
    <rect x="139.5" y="49" rx="2" ry="2" width="45.5" height="46" />
    <rect x="186" y="49" rx="2" ry="2" width="45.5" height="46" />
    <rect x="232.5" y="49" rx="2" ry="2" width="45.5" height="46" />
  </ContentLoader>
);

const LinkLoader = () => (
  <ContentLoader viewBox="0 0 280 10">
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="0" ry="0" width="140" height="2" />
    <rect x="0" y="6" rx="0" ry="0" width="70" height="2" />

    <rect x="145" y="1" rx="0" ry="0" width="140" height="2" />
    <rect x="145" y="6" rx="0" ry="0" width="70" height="2" />
  </ContentLoader>
);

function Home() {
  const dispatch = useDispatch();
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [metaData, setMetaData] = useState([]);
  const [count, setCount] = useState(0);
  const [sliderFilterData, setSliderFilterData] = useState({});
  const [gridcolor, setGridcolor] = useState("");
  const [activefiltertab, setActivefiltertab] = useState("realtime");
  const [filterdate, setFilterdate] = useState({});
  const [csvUpdateEvent, seCsvUpdateEvent] = useState("true");
  const [boxColor, setBoxColor] = useState({ color: "" });
  const [isLoader, setIsLoader] = useState(true);
  const [isRealtime, setIsRealtime] = useState(true);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("psdata"));

  const socket = useContext(SocketContext);
  const selector = useSelector((state) => state);
  var param = {
    fromdate: { date: moment(new Date()).format("DD-MM-YYYY"), shift: "03:00" },
    todate: { date: moment(new Date()).format("DD-MM-YYYY"), shift: "07:00" },
  };
  const getusers = selector.compensate.getusers;
  const headcount = selector.compensate.headcount;
  const footerhome = selector.compensate.footerhome;
  const detail_data = selector.compensate.departmentdetail;
  const multichart = detail_data?.left_sidebar
    ? detail_data.left_sidebar.sidebar_1
    : {};
  window?.localStorage?.setItem("activefiltertab", "realtime");

  useEffect(() => {
    fetchDetails();
  }, [sliderFilterData]);

  useEffect(() => {
    socket.on("csvUpdate", (socketData) => {
      if (window?.localStorage?.getItem("activefiltertab") === "realtime") {
        // alert("ok")
        fetchDetails();
      }
    });
  }, [socket]);


  const getPreAndCurrShift = async () => {
    try {
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/dashboard/getCurrentShift"
      );
      const data = decryptedFunction(resp?.data?.responseData);
      const result = data.data;
      //console.log("getPreviousAndCurrentShift", result);
      //dispatch(getPreviousAndCurrentShift(result));
      return result;
      //dispatch(setEditDepartmentDetail(data.data.directData));
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchDetails(searchkey) {
    try {
      setIsLoader(true);
      dispatch(setAirlines([]));
      if (activefiltertab == "realtime") {
        setIsRealtime(true);
        const dataawait = await getPreAndCurrShift();
        param = dataawait?.filterDates;
      } else {
        setIsRealtime(false);
        param["fromdate"] = {
          date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
          shift: "03:00",
        };
        param["todate"] = {
          date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
          shift: "23:00",
        };
      }
      if (searchkey) {
        param["searchKeyword"] = searchkey;
      }
      //param["searchKeyword"] = "icu";
      const fromdatearr = param.fromdate.date.split("-");
      const fromdate = `${fromdatearr[1]}/${fromdatearr[0]}/${fromdatearr[2]}`;
      const todatearr = param.todate.date.split("-");
      const todate = `${todatearr[1]}/${todatearr[0]}/${todatearr[2]} ${param.todate.shift}`;
      setFilterdate({ fromdate, todate });

      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        API_CALL_URL_PRIVATE + "/dashboard/getData",
        { payloadData: encryptData }
      );
       const data = decryptedFunction(response?.data?.responseData);
      dispatch(setAirlines(data?.data?.content ? data?.data?.content : []));
      dispatch(setHeadCount(data?.data?.header));
      dispatch(setFooterHome(data?.data?.footer));

      if (!searchkey) {
        // if (data?.data?.content.length === 1){
        //   const slug = data?.data?.content[0]?.slug;
        //   navigate(`/realtime/department-details/${slug}/realtime`);
        // }
        if (userData?.data?.realtime_landing === 0) {
          const slug = data?.data?.content[0]?.slug;
          navigate(`/realtime/department-details/${slug}/realtime`);
        }
      }

      if (data.success) {
        setIsLoader(false);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log({ error });
      // debugger
    }
  }

  function isactivefilter(addclass) {
    setGridcolor(addclass);
  }

  const searchcallback = (searchkey) => {
    fetchDetails(searchkey);
  };


  const getMetaData = async () => {
    try {
     const param = {
       application_id: 101,
     };
     const encryptData = dataEnCrypt(param);
     const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
       payloadData: encryptData,
     });
     const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // if (userData?.data?.customer_masters?.show_new_realtime_dashboard) {
    //   navigate(`/realtime-new`);
    // }

    getMetaData();

  }, []);

  useEffect(() => {
    document.title = metaData?.meta_title || 'RealTime';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  return (
    <PageLayout
      data={headcount}
      issearch={true}
      isshow={false}
      searchcallback={searchcallback}
      isLoader={isLoader}
      isCallback={true}
    >
      <div className="row gx-2 mt-2">
        <div className="col-md-12">
          <div className="row gx-2 gy-2 db-grid">
            {!isLoader ? (
              getusers.length > 0 ? (
                getusers.map((item, index) => {
                  return (
                    <ChartExample
                      data={item}
                      data1={item}
                      isRealtimeToday={headcount?.isRealTime}
                      showCompilanceRing={item?.show_compliance_ring}
                      showProductivityRing={item?.show_productivity_ring}
                      isDashboard={true}
                    />
                    // <ProgressChart key={index} gridcolor={gridcolor} isPieChart="false" data={item} />
                  );
                })
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <p className="text-secondary display-6">
                      No Data Found
                  </p>
                </div>
              )
            ) : (
                <MyLoader themeMode={themeMode} />
            )}
          </div>
          {userData?.data?.user_type ===  0 &&
          <div className="row mt-2">
            {Object.keys(filterdate).length > 0 ? (
              <div className="col-md-12">
                <div className="recordshowing">
                  Record showing : {filterdate.fromdate}
                </div>
              </div>
            ) : null}
            <div className="col-md-12">
              <DateFilter
                fromdate={filterdate.fromdate}
                seCsvUpdateEvent={seCsvUpdateEvent}
                setSliderFilterData={setSliderFilterData}
                setBoxColor={setBoxColor}
                isactivefilter={isactivefilter}
                active={activefiltertab}
                activetab={(tab) => setActivefiltertab(tab)}
              />
             </div>
          </div>
          }
        </div>
      </div>
    </PageLayout>
  );
}

export default Home;
