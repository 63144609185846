import React from 'react'
import Realtime from "../Home/index";
import RealtimeStatus from "../Realtime/realtime";


const Home = () => {
  const userData = JSON.parse(localStorage.getItem("psdata"));

  return (
    <>
      {/* RealTime Landing page button/toggle */}
      <Realtime />

      {/* {
    userData?.data?.customer_masters?.show_new_realtime_dashboard ? 
    <RealtimeStatus/> : <Realtime/>
      } */}
    </>
  );
}

export default Home