import React, { useContext, useEffect } from "react";
import { THEME_TYPE } from "../../constants/theme";
import { ThemeContext } from "../../providers/ThemeProvider";

const ThemeSwitcher = ({checked}) => {
    const [themeMode, setTheme] = useContext(ThemeContext);
    const defaultLanguage = localStorage.getItem("psLang");

    const handleThemeChange = (e) => {
        if (themeMode.theme === THEME_TYPE.LIGHT) {
            setTheme({ theme: THEME_TYPE.DARK });
        }
        else {
            setTheme({ theme: THEME_TYPE.LIGHT });
        }
        localStorage.setItem("psLang", themeMode.theme);
    };







    return (
        <div className="switch-container">
            <label className="switch">
                <input
                    data-testid="theme-changer"
                    type="checkbox"
                    checked={themeMode.theme === THEME_TYPE.DARK ? true : false}
                    // defaultChecked={themeMode.theme === THEME_TYPE.DARK}
                    onClick={() => handleThemeChange()}
                />
                <span className="slider round"></span>
            </label>
            <span className="text-color bold">Dark mode</span>
        </div>
    );
};

export default ThemeSwitcher;
