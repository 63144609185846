import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import "font-awesome/css/font-awesome.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

// import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

import { Form, InputGroup } from "react-bootstrap";
import PageLayout from "../../../../layouts/PageLayout";
import FooterModal from "../../../../components/ActiveDailyManagementComponents/shared/footerModal";

import { get, isEmpty } from "lodash";
import { FormGroup } from "react-bootstrap";

import { useParams } from "react-router-dom";
import axios from "../../../../axios";
import Axios from "axios";
import { API_CALL_URL_PRIVATE } from "../../../../constants/compensate";
import ContentLoader from "react-content-loader";
import { getTableRecordWithSorting } from "../../../../services";
import DescIcon from "../../../../assets/img/desc.png";
import AscIcon from "../../../../assets/img/asc.png";
import PsLogo from "../../../../assets/img/pslogo.png";
import Bi4 from "../../../../assets/img/overtime/agency.svg";
// import Bi4 from "../../../assets/img/bi4.png";
import { ThemeContext } from "../../../../providers/ThemeProvider";
import Select from "react-select";
import Button from "@atlaskit/button";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../../Styled/DropdownStyle";
import InfoModal from "../AgencyModal";
import InfoModal2 from "../AgencyDetailModal";
import {
  formatNumberWithFraction,
} from "../../../../utils/helper";
import COMMENT from "../../../../assets/img/comment.png";
import CheckApproved from "../../../../assets/img/check-mark2.png";
import CheckRejected from "../../../../assets/img/check-rejected.png";
import SHIFT_FORM from "../../../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../../../utils/helper";

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="195" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 200, height: 20, marginRight: 8, marginBottom: 17 }}>
    <ContentLoader
      viewBox="0 0 300 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" style={{ width: "100%" }} height="100" />
    </ContentLoader>
  </div>
);

function ExceptionsDetails({ active, fromdate, activetab, history }) {
  const dispatch = useDispatch();
  const buisnessHeader = useSelector(
     (state) => state.compensate.buisnessHeader
   );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const location = useLocation();
  const { state } = location;
  const { department, costid } = useParams();
  const time = fromdate ? new Date(fromdate) : "";
  const [costArrData, setCostArrData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [costData, setCostData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const [dropDateData, setDropDateData] = useState({});
  const [dropCostData, setDropCostData] = useState({});
  const [constCenter, setConstCenter] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(true);
  const [totalvalue, setTotalvalue] = useState({});
  const [totalvalueTwo, setTotalvalueTwo] = useState({});
  const [showdropdown, setShowdropdown] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortExceType, setSortExceType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const [id, setId] = useState(null);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [cosCenterSlug, setCosCenterSlug] = useState(state?.slug);
  const [columnDates, setColumnDates] = useState([]);
  const [displayDate, setDisplayDate] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [overtimeApiData, setOvertimeApiData] = useState([]);

  //searchbox
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [isDropdownOpenThree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [dropdownValueThree, setDropdownValueThree] = useState("");
  const [selectedOption, setSelectedOption] = useState("All");
  const [comLoading, setComLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [cancelToken, setCancelToken] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [dailyParameter, setDailyParameter] = useState({});

  // supervisor
  const [supvisorData, setSupvisorData] = useState({});
  const [searchSupvisor, setSearchSupvisor] = useState([]);

  const [incidentalAll, setIncidentalAll] = useState({
    isAll: true,
    isNonIncidental: false,
    isIncidental: false,
    isContract: false,
  });

  useEffect(() => {
    getCostCenterNumber();
    getRefreshSchedule();
  }, []);



  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(columnData, bindname, []) : columnData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    if (bindname) {
      setTableData({ ...columnData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }
    setsortName(key);
     activityLogTable(SHIFT_FORM.AGENCY_DETAIL_TABLE[key] ?? "", sortType);
  };


  const getCostCenterNumber = async () => {
    const para = {
      slug: department,
      facility_id: 1,
    };
    const encryptData = dataEnCrypt(para);
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getCostCenterData",
      { payloadData: encryptData }
    );
    const data = decryptedFunction(resp?.data?.responseData);
    const ccn = data.data?.cost_center?.cost_center_number;
    const secondApiResponse = await getMetaData(ccn);
    const dropDataResponse = await getDropData(ccn);
    setId(ccn);
    //fetchDetails();
  };

  const getDropData = async (ccn) => {
    setComLoading(true);
    setTableLoading(true);
    try {
      const paramCostData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      const encryptCostData = dataEnCrypt(paramCostData);
      const costResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
        { payloadData: encryptCostData }
      );
      const costCenterData = decryptedFunction(
        costResponse?.data?.responseData
      );

       const periodResponse = await axios.post(
         `${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`
       );
       const periodData = decryptedFunction(periodResponse?.data?.responseData);

        const paramApproverData = {
          cost_center_number: +ccn,
        };

      const encryptApproverData = dataEnCrypt(paramApproverData);
      const shcApproverResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/shcApproversLit`,
        { payloadData: encryptApproverData }
      );
      const shcApproveData = decryptedFunction(
        shcApproverResponse?.data?.responseData
      );

      setCostArrData(costCenterData?.data?.costCenterResponse);

      const matchingCostCenter =
        costCenterData?.data?.costCenterResponse?.filter(
          (val) => department === val.cost_center_slug
        );

         if (isEmpty(matchingCostCenter)) {
           history(`/activedailymanagement/not-found`);
         }

      setDropdownValue({
        label: `${matchingCostCenter[0].cost_center_number} - ${matchingCostCenter[0].cost_center_name}`,
        value: matchingCostCenter[0]?.cost_center_number,
        slug: matchingCostCenter[0]?.cost_center_slug
      });

      const matchingPayDates = periodData?.data?.payPeriodEndDates[0];
        if (state?.urldate) {
          setDropdownValueTwo({
            label: state?.urldate?.label,
            value: state?.urldate?.value,
          });
        } else {
          setDropdownValueTwo({
            label: matchingPayDates?.display_date,
            value: matchingPayDates?.payload_date,
          });
        }

      setCostData(
        costCenterData?.data?.costCenterResponse.map((val) => {
          return {
            label: `${val.cost_center_number} - ${val.cost_center_name}`,
            value: val.cost_center_number,
            slug: val.cost_center_slug,
          };
        })
      );
      setDateData(
        periodData?.data?.payPeriodEndDates.map((val) => {
          return {
            label: val.display_date,
            value: val.payload_date,
          };
        })
      );
      setSupvisorData(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );

      setSearchSupvisor(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );
      setSearchData(
        costCenterData?.data?.costCenterResponse.map((val) => {
          return {
            label: `${val.cost_center_number} - ${val.cost_center_name}`,
            value: val.cost_center_number,
          };
        })
      );
      setDefaultValue(
        costCenterData?.data?.costCenterResponse.filter(
          (val) => val.cost_center_slug === department
        )
      );
      setConstCenter(
        costCenterData?.data?.costCenterResponse.filter(
          (val) => val.cost_center_slug === department
        )[0]?.cost_center_number
      );
      setDateVal(periodData?.data?.payPeriodEndDates[0]?.payload_date);
      setComLoading(false);
    } catch (error) {
      console.log(error);
      setComLoading(false);
      setTableLoading(false);
    }
  };

   const getBuisnesUnitFilter = async () => {
     setComLoading(true);
     setDropdownValue(undefined);
     setDropdownValueTwo(undefined);
     setTableLoading(true);
     try {
        const paramCostData = {
          page_type: "overtime",
          businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
        };
        const encryptCostData = dataEnCrypt(paramCostData);
        const costResponse = await axios.post(
          `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
          { payloadData: encryptCostData }
        );

        const costCenterData = decryptedFunction(
          costResponse?.data?.responseData
        );

       const matchingCostCenter =
         costCenterData?.data?.costCenterResponse[0];

       setDropdownValue({
         label: `${matchingCostCenter.cost_center_number} - ${matchingCostCenter.cost_center_name}`,
         value: matchingCostCenter?.cost_center_number,
         slug: matchingCostCenter?.cost_center_slug,
       });

       const url = `/activedailymanagement/agency-details/${matchingCostCenter?.cost_center_slug}`;

       navigate(url, {
         state: state?.urlslug
           ? { urlslug: matchingCostCenter?.cost_center_slug }
           : null,
       });
      //  history(url);

     
      const paramApproverData = {
        cost_center_number: +matchingCostCenter?.cost_center_number,
      };

      const encryptApproverData = dataEnCrypt(paramApproverData);

      const shcApproverResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/shcApproversLit`,
        { payloadData: encryptApproverData }
      );
      const shcApproveData = decryptedFunction(
        shcApproverResponse?.data?.responseData
      );

       const periodResponse = await axios.post(
         `${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`
       );

       const periodData = decryptedFunction(periodResponse?.data?.responseData);

       const matchingPayDates = periodData?.data?.payPeriodEndDates[0];
          if (state?.urldate) {
            setDropdownValueTwo({
              label: state?.urldate?.label,
              value: state?.urldate?.value,
            });
          } else {
            setDropdownValueTwo({
              label: matchingPayDates?.display_date,
              value: matchingPayDates?.payload_date,
            });
          }

       setCostData(
         costCenterData?.data?.costCenterResponse.map((val) => {
           return {
             label: `${val.cost_center_number} - ${val.cost_center_name}`,
             value: val.cost_center_number,
             slug: val.cost_center_slug,
           };
         })
       );

       setDateData(
         periodData?.data?.payPeriodEndDates.map((val) => {
           return {
             label: val.display_date,
             value: val.payload_date,
           };
         })
       );

       setSupvisorData(
         shcApproveData?.data?.getSchApproverData.map((val) => {
           return {
             label: val.employee_name,
             value: val.approver_id,
           };
         })
       );

       setSearchSupvisor(
         shcApproveData?.data?.getSchApproverData.map((val) => {
           return {
             label: val.employee_name,
             value: val.approver_id,
           };
         })
       );
       setComLoading(false);
     } catch (error) {
       console.log(error);
     }
   };

  const getMetaData = async (ccn) => {
    try {
      const param = {
        application_id: 105,
        cost_center_number: ccn,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
     const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getApproverList = async (e) => {
    try {
       const paramApproverData = {
         cost_center_number: +e,
       };

       const encryptApproverData = dataEnCrypt(paramApproverData);
       const shcApproverResponse = await axios.post(
         `${API_CALL_URL_PRIVATE}/admGeneral/shcApproversLit`,
         { payloadData: encryptApproverData }
       );
       const shcApproveData = decryptedFunction(
         shcApproverResponse?.data?.responseData
       );

      setSupvisorData(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );

      setSearchSupvisor(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };


  useEffect(() => { }, [isDropdownOpen, dropdownValue]);

  // Detail API's

  const getOvertimeDetails = async (
    filter,
    center,
    payEndDate,
    employeId,
    searchKeyword,
    cancelToken
  ) => {
    setTableLoading(true);
    try {
      const paramData = {
        searchKeyword: searchKeyword,
        reportFilter: filter, //All, Non-Incidental, Incidental, Contract
        cost_center_number: center,
        payperiod_end_date: payEndDate,
        approver_id: employeId,
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/agency/getDetailData`,
        { payloadData: encryptData },
        { cancelToken: cancelToken }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setColumnDates(data.data.column_dates);
      setDisplayDate(data.data.tableHead);
      setColumnData(data.data.tableData);
      setOvertimeApiData(data?.data);
      setTableLoading(false);
      // setComLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dropdownValue && dropdownValueTwo &&
      getOvertimeDetails(
        selectedOption,
        dropdownValue?.value,
        dropdownValueTwo?.value,
        dropdownValueThree?.value
      );
  }, [selectedOption, dropdownValue, dropdownValueTwo, dropdownValueThree]);


    const handleInputChange = (newValue) => {
      setTableLoading(true);
      setSearchBoxValue(newValue);

      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      getOvertimeDetails(
        selectedOption,
        dropdownValue?.value,
        dropdownValueTwo?.value,
        dropdownValueThree?.value,
        newValue,
        newCancelToken.token
      );
    };


  const matchDatesWithPayrolls = (dates, payrolls) => {
    return dates.map((date) => {
      const payrollEntry = payrolls?.find((payroll) => payroll.date === date);
      return payrollEntry ? payrollEntry.agency_hours_daily : 0;
    });
  };

   const matchDatesWithPayrollsColor = (dates, payrolls) => {
     return dates.map((date) => {
       const payrollEntry = payrolls?.find((payroll) => payroll.date === date);
       return payrollEntry
         ? {
             agency_hours_daily: payrollEntry?.agency_hours_daily,
             comment_count: payrollEntry?.comment_count,
             manager_approved: payrollEntry?.manager_approved,
             manager_rejected: payrollEntry?.manager_rejected,
           }
         : 0;
     });
   };
  

  const customArr = () => {
    const personArr = columnData?.map((val, ind) => {
      const matchedOvertimeHours = matchDatesWithPayrolls(
        columnDates,
        val?.payrolls
      );
      const sum = matchedOvertimeHours.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

      return {
        employee_name: val.employee_name,
        payrolls: matchedOvertimeHours,
        rowsTotal: sum,
      };
    });

    const sumValaArr = sumArrays(personArr);
    // setSumArr(sumValaArr);
    return sumValaArr;
  };
  const customArrTwo = () => {
    const personArr = columnData?.map((val, ind) => {
      const matchedOvertimeHours = matchDatesWithPayrolls(
        columnDates,
        val?.payrolls
      );
      const sum = matchedOvertimeHours?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

      return {
        employee_name: val.employee_name,
        payrolls: matchedOvertimeHours,
        rowsTotal: sum,
      };
    });
    const totalSum = personArr?.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.rowsTotal;
    }, 0); // Initialize the accumulator to 0

    return totalSum;
  };

  function sumArrays(employees) {
    if (!Array.isArray(employees) || employees.length === 0) {
      return [];
    }

    const maxLength = Math.max(
      ...employees.map((emp) => emp.payrolls?.length || 0)
    );

    const sums = new Array(maxLength).fill(0);

    employees.forEach((emp) => {
      emp.payrolls?.forEach((value, index) => {
        sums[index] += value;
      });
    });

    return sums;
  }

  const overTimeTotal = () => {
    const totalSum = columnData?.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.overtime_worked_hours;
    }, 0);
    const equalTotal = totalSum / columnData?.length;
    return equalTotal;
  };
  const incidentalTotal = () => {
    const totalSum = columnData?.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.incidental;
    }, 0);
    const equalTotal = totalSum / columnData?.length;
    return equalTotal;
  };
  useEffect(() => {
    overTimeTotal();
  });

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  const getBackColor = (e) => {
    // if (e > 0.1) {
    //   return "#f34727"; //red
    // } else if (e > 0 && e <= 0.1) {
    //   return "#fac710"; //yellow
    // } else if (e === 0) {

    //  // return "#272729";
    //   return themeMode.theme === 'DARK' ? '#272729' : '#d1d1d1'; //black


    // } else {
    //   return "#f34727"; //red
    // }

    if (e === 0) {
      return themeMode.theme === 'DARK' ? '#272729' : 'rgba(209, 209, 209, 0.28)'; //black
    } else {
      return '#d1d1d1'
    }

  };
  const getBackColors = (e) => {
    if (e > 1) {
      return "#f34727";
    } else if (e <= 1) {
      return "#027b1b";
    } else {
      return "#f34727";
    }
  };
  // overtime detail
  const [dailyData, setDailyData] = useState([]);
  const overTimeDetail = async (employeeId, centerNum, date, dateType) => {
    try {
     const paramData = {
       employee_id: employeeId,
       cost_center_number: centerNum,
       when_posted: date,
       date_type: dateType ? dateType : null,
     };
     const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/agency/getDailyDetailData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setDailyData(data.data);
        setDailyParameter({
          employee_id: employeeId,
          cost_center_number: centerNum,
          when_posted: date,
          date_type: dateType ? dateType : null,
        });
    } catch (error) {
      console.log(error);
    }
  };
  function convertDateFormats(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year.slice(-2); // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year; // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${shortYear}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  const getOvertimeColor = (e) => {
    if (e > 4) {
      return "#f34727";
    } else {
      //  return "#027b1b";
      return themeMode.theme === 'DARK' ? '#027b1b' : '#3bfb62';
    }
  };

    useEffect(() => {
      if (mounted) {
        getBuisnesUnitFilter();
      } else {
        setMounted(true);
      }
    }, [buisnessHeader]);

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
  };

   const activityLogTable = async (table_column, sortType) => {
     try {
      const paramData = {
        activity_id: 47,
        page_type: "Agency Detail",
        application_id: 105,
        table_name: "Agency Detail",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
        payperiod_end_date: dropdownValueTwo?.label,
        cost_center_number: dropdownValue?.value,
        approver_id: dropdownValueThree?.value,
        reportFilter: selectedOption,
      };
       const encryptData = dataEnCrypt(paramData);
       const { data } = await axios.post(
         `${API_CALL_URL_PRIVATE}/activity-log/create`,
         { payloadData: encryptData }
       );
     } catch (err) {
       console.log(err);
     }
   };

  return (
    <PageLayout>
      <div className="overflow-section">
        <Row className="gx-2 mt-0 align-items-center">
          <Col md={{ span: 2 }}>
            <Link
              to={
                state?.urlslug
                  ? `/activedailymanagement/adm-summary/${dropdownValue?.slug}`
                  : `/activedailymanagement/agency-summary`
              }
              state={{
                urlslug: dropdownValue?.slug,
                urldate: {
                  label: dropdownValueTwo?.label,
                  value: dropdownValueTwo?.value,
                },
              }}
              className="f-14 white-text-dark-mode"
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
            {/* <div
              style={{ cursor: "pointer" }}
              className="f-14 white-text-dark-mode"
              onClick={() => history(-1)}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </div> */}
          </Col>
          <Col md={{ span: 8 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su  mobile-m-4">
              <div className="">
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                  <img src={Bi4} alt="" height={38} /> &nbsp; Agency Detail
                </h3>
              </div>
            </div>
          </Col>
          <Col md={2} className="h-50p">
            {/* <div>
              <Select
                styles={customStyles}
                inputValue={searchBoxValue}
                onInputChange={(inputValue, { action }) => {
                  if (action === "input-change") {
                    setSearchBoxValue(inputValue);

                  }
                }}
                placeholder={"Search..."}
                isSearchable
                onBlurResetsInput={false}
              />
            </div> */}
            <div className="input-group mb-3">
              <input
                type="text"
                value={searchBoxValue}
                className="form-control py-2 bg-transparent border-light"
                placeholder="Search..."
                onChange={(e) => {
                  handleInputChange(e.target.value);
                }}
              />
            </div>
          </Col>

          <InfoModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              getOvertimeDetails(
                selectedOption,
                dropdownValue?.value,
                dropdownValueTwo?.value,
                dropdownValueThree?.value
              );
            }}
            tableData={dailyData}
            overtimeDailyFun={overTimeDetail}
            overtimeDailyParameter={dailyParameter}
          />
          <InfoModal2
            show={modalShow1}
            onHide={() => setModalShow1(false)}
            tableData={dailyData}
          />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>
        <Row className="gx-2 align-items-center justify-content-center exception-header">
          <Col md="12">
            <div className="d-flex flex-wrap justify-content-center">
              {comLoading ? (
                <LinkLoader themeMode={themeMode} />
              ) : (
                <div className="custom-select-v cap i ">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpen}
                    onClose={toggleOpen}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpen}
                        isSelected={isDropdownOpen}
                      >
                        {dropdownValue ? dropdownValue.label : ""}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpen}
                      onChange={(e) => {
                        toggleOpen();
                        setDropdownValue(e);
                        getApproverList(e.value);
                        navigate(
                          `/activedailymanagement/agency-details/${e.slug}`,
                          {
                            state: state?.urlslug ? { urlslug: e?.slug } : null,
                          }
                        );
                      }}
                      options={costData}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      value={dropdownValue}
                      // value="ICE 1"
                    />
                  </CustomDropdown>
                </div>
              )}
              {comLoading ? (
                <LinkLoader themeMode={themeMode} />
              ) : (
                <div className="custom-select-v cap">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpentwo}
                    onClose={toggleOpenTwo}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpenTwo}
                        isSelected={isDropdownOpentwo}
                      >
                        {dropdownValueTwo
                          ? convertDateFormat(dropdownValueTwo?.value)
                          : ""}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpentwo}
                      onChange={(e) => {
                        toggleOpenTwo();
                        setDropdownValueTwo(e);
                      }}
                      options={dateData}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      //  value={dropdownValue}
                    />
                  </CustomDropdown>
                </div>
              )}
              {comLoading ? (
                <LinkLoader themeMode={themeMode} />
              ) : (
                <div className="custom-select-v cap lt position-relative">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpenThree}
                    onClose={toggleOpenThree}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpenThree}
                        isSelected={isDropdownOpenThree}
                      >
                        {dropdownValueThree
                          ? dropdownValueThree.label
                          : "All Approvers"}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpenThree}
                      onChange={(e) => {
                        toggleOpenThree();
                        setDropdownValueThree(e);
                      }}
                      options={supvisorData}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      //  value={dropdownValue}
                      value="All Approvers"
                    />
                  </CustomDropdown>
                  {!isEmpty(dropdownValueThree) && (
                    <span
                      onClick={() => {
                        setDropdownValueThree("");
                      }}
                      className="custom-cross"
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      x
                    </span>
                  )}
                </div>
              )}
            </div>
          </Col>
          {/* <Col md="12">
            <div className="bxh d-flex justify-content-center align-items-center mt-2 filter-btn">
              <div className="d-flex">
                <div
                  className={
                    incidentalAll.isAll
                      ? "legend-text cursor-pointer active ft-btn"
                      : "legend-text cursor-pointer ft-btn"
                  }
                  onClick={() => {
                    setIncidentalAll({
                      isAll: true,
                      isNonIncidental: false,
                      isIncidental: false,
                      isContract: false,
                    });
                    setSelectedOption("All");
                  }}
                >
                  All
                </div>
                <div
                  className={
                    incidentalAll.isNonIncidental
                      ? "redt legend-text cursor-pointer active ft-btn"
                      : "redt legend-text cursor-pointer ft-btn"
                  }
                >
                  <span className="circle"></span>Variance
                </div>
                <div
                  className={
                    incidentalAll.isIncidental
                      ? "yellowt legend-text cursor-pointer active ft-btn"
                      : "yellowt legend-text cursor-pointer  ft-btn"
                  }
                  onClick={() => {
                    setIncidentalAll({
                      isAll: false,
                      isNonIncidental: false,
                      isIncidental: true,
                      isContract: false,
                    });
                    setSelectedOption("Incidental");
                  }}
                >
                  <span className="circle"></span>Incidental
                </div>
                <div
                  className={
                    incidentalAll.isNonIncidental
                      ? "purplet legend-text cursor-pointer active ft-btn"
                      : "purplet legend-text cursor-pointer ft-btn"
                  }
                >
                  <span className="circle"></span>Below Schedule
                </div>
                <div
                  className={
                    incidentalAll.isNonIncidental
                      ? "greent legend-text cursor-pointer active ft-btn"
                      : "greent legend-text cursor-pointer ft-btn"
                  } 
                >
                  <span className="circle"></span>No Variance
                </div>
                <div
                  className={
                    incidentalAll.isNonIncidental
                      ? "oranget legend-text cursor-pointer active ft-btn"
                      : "oranget legend-text cursor-pointer ft-btn"
                  }
                >
                  <span className="circle"></span>Missed Schedule
                </div>
                <div
                  className={
                    incidentalAll.isNonIncidental
                      ? "whitet legend-text cursor-pointer active ft-btn"
                      : "whitet legend-text cursor-pointer ft-btn"
                  }
                >
                  <span className="circle"></span>Schedule
                </div>
              </div>
            </div>
          </Col> */}

          {/* <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            dataFooter={scheduleData}
            show={footerShow}
            onHide={() => setFooterShow(false)}
          /> */}
        </Row>
        <Row className="gx-2">
          <Col md={12} className="mt-2">
            <div className="bg-white p-3 h-100 min-665">
              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2 exceptions-table table-hover scroll-table long-table big-table overtime-details-table"
              >
                {displayDate?.length > 0 ? (
                  comLoading ? (
                    ""
                  ) : (
                    <thead>
                      <tr>
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("employee_name")}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <div>Employee</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "employee_name" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        {displayDate?.map((val, ind) => {
                          return (
                            <th
                              key={ind}
                              className="text-center cursor-pointer"
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <div>
                                  {val?.date}
                                  <br />
                                  <span> {val?.shortDay}</span>
                                </div>
                              </div>
                            </th>
                          );
                        })}
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("total_agency_hours")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div> Total </div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "total_agency_hours" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        {/* <th className="text-center cursor-pointer"
                            onClick={() => sortingHandle('agency_worked_hours')}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <div> Agency % Of Worked Hours </div>
                              <div className="ms-2">
                                {sortType === "asc" && sortName === "agency_worked_hours" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                              </div>
                            </div>
                          </th> */}
                      </tr>
                    </thead>
                  )
                ) : (
                  ""
                )}
                <tbody className="h-530">
                  {tableLoading ? (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  ) : columnData?.length > 0 ? (
                    columnData?.map((valVal, ind) => {
                      const matchedOvertimeHours = matchDatesWithPayrollsColor(
                        columnDates,
                        valVal?.payrolls
                      );
                      const matchedOvertimeSum = matchDatesWithPayrolls(
                        columnDates,
                        valVal?.payrolls
                      );
                      const sum = matchedOvertimeSum.reduce(
                        (accumulator, currentValue) => {
                          return accumulator + currentValue;
                        },
                        0
                      );
                      return valVal?.payrolls?.length > 0 ? (
                        <tr className="w-45td" key={ind}>
                          <td className="text-start">{valVal.employee_name}</td>
                          {matchedOvertimeHours?.map((val, ind) => {
                            return (
                              <td
                                className={`text-center ${
                                  val?.comment_count > 0 ? "ChatIcon" : "noIcon"
                                } `}
                                key={ind}
                              >
                                <span
                                  className="yellobox"
                                  style={{
                                    backgroundColor: getBackColor(
                                      val?.agency_hours_daily || 0
                                    ),
                                    color:
                                      val?.agency_hours_daily > 0
                                        ? themeMode.theme === "DARK"
                                          ? "#000"
                                          : "#000"
                                        : "transparent",
                                    fontWeight: "bold",
                                    cursor:
                                      val?.agency_hours_daily > 0
                                        ? "pointer"
                                        : "not-allowed",
                                  }}
                                  onClick={() => {
                                    if (val?.agency_hours_daily > 0) {
                                      setModalShow(true);
                                      const currentDate = columnDates[ind];
                                      overTimeDetail(
                                        valVal.employee_id,
                                        dropdownValue?.value,
                                        currentDate
                                      );
                                    }
                                  }}
                                >
                                  {val?.agency_hours_daily?.toFixed(1)}
                                  {val?.agency_hours_daily > 0 &&
                                  val?.comment_count > 0 ? (
                                    <>
                                      {val?.manager_approved && (
                                        <img
                                          src={CheckApproved}
                                          alt=""
                                          className="commenticon"
                                          style={{ filter: "brightness(1)" }}
                                        />
                                      )}
                                      {val?.manager_rejected && (
                                        <img
                                          src={CheckRejected}
                                          alt=""
                                          className="commenticon"
                                          style={{ filter: "brightness(1)" }}
                                        />
                                      )}
                                      {!val?.manager_approved &&
                                        !val?.manager_rejected && (
                                          <img
                                            src={COMMENT}
                                            alt=""
                                            className="commenticon"
                                          />
                                        )}
                                    </>
                                  ) : (
                                    <img alt="" />
                                  )}
                                </span>
                              </td>
                            );
                          })}
                          <td className="text-center">
                            <span
                              className="yellobox"
                              style={{
                                backgroundColor: "#2d9bf0",
                                fontWeight: "bold",
                                color:
                                  "themeMode.theme === 'DARK' ? '#FFF' : '#000'",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setModalShow(true);
                                const currentDate = dropdownValueTwo?.value;
                                overTimeDetail(
                                  valVal.employee_id,
                                  dropdownValue?.value,
                                  currentDate,
                                  "payperiod_end_date"
                                );
                              }}
                            >
                              {formatNumberWithFraction(sum)}
                              {/* {sum?.toFixed(1)} */}
                            </span>
                          </td>

                          {/* <td className="text-center">
                              <span
                                className="yellobox"
                                style={{
                                  backgroundColor: "#2d9bf0",
                                  fontWeight: "bold",
                                  color: "themeMode.theme === 'DARK' ? '#FFF' : '#000'",
                                  cursor: "pointer"
                                }}
                              >
                                {formatNumberWithFraction(valVal?.agency_worked_hours)}
                              </span>
                            </td> */}
                        </tr>
                      ) : (
                        ""
                      );
                    })
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <p className="text-secondary display-6">No Data Found</p>
                    </div>
                  )}
                </tbody>
                {columnData?.length > 0 ? (
                  <tbody className="ftr">
                    {tableLoading ? (
                      <div>
                        <TableLoader themeMode={themeMode} />
                      </div>
                    ) : totalvalue ? (
                      <tr className="bt">
                        <td className="text-center">
                          <strong>Total</strong>
                        </td>
                        {customArr()?.map((val, ind) => {
                          return (
                            <td className="text-center" key={ind}>
                              {/* <strong style={{ color: getBackColors(val) }}> */}
                              <strong>{val?.toFixed(1)}</strong>
                            </td>
                          );
                        })}
                        <td className="text-center">
                          <strong>
                            {formatNumberWithFraction(customArrTwo())}
                          </strong>
                        </td>
                        {/* <td className="text-center">
                            <strong>
                              {formatNumberWithFraction(overtimeApiData?.workedHoursPercentageT)}%
                            </strong>
                          </td> */}
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                ) : (
                  ""
                )}
              </Table>
            </div>
          </Col>

          <Col md={12} className="ft-text mt-2 mb-2 px-1">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              {/* <span>{headData?.current_file_msg}</span> */}
              <div className="col-12 text-end bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
              {/* <span className="col-6"><span className=" text-start  p-2 bg-white d-block">{headData?.upcomming_file_msg}</span></span> */}
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default ExceptionsDetails;
