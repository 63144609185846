import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/o.png";
import Ico2 from "../../assets/img/t.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from '../../assets/img/beds.png';
import Icontwo from '../../assets/img/compliance.png';
import Iconthree from '../../assets/img/variance.png';
import Iconfour from '../../assets/img/hpuoc.png';
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from '../../assets/img/pslogo.png';
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../providers/ThemeProvider";


const BoxLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="195" />
  </ContentLoader>
);

function Applications(props) {
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const [dashboardData, setDashboardData] = useState([]);
  const [themeMode, setTheme] = useContext(ThemeContext);

  const boxdata = props.data;
  // const isLoader = props.isLoader;
  const { issearch } = props;
  const [metaData, setMetaData] = useState([]);
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [isLoader, setIsLoader] = useState(false);



  return (
    <PageLayout>
      <div className="row gx-2 mt-2">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-center">
            <h2
              className="fw-normal color-black"
              style={{ textTransform: "capitalize" }}
            >
              You do not have permission to access any page, please contact the admin !
            </h2>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Applications;
