import React, { useState } from "react";
import axios from "axios";
import { API_CALL_URL_PRIVATE } from "./constants/compensate";

// import { compose } from "redux";
import { toast } from "react-toastify";

const instance = axios.create({
    baseURL: API_CALL_URL_PRIVATE,
});

//customer and admin secure
// instance.defaults.headers.common["language"] = window.localStorage.getItem("rcml-lang") || "en";
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
if (localStorage.accessToken) {
    const JWT_token = localStorage.accessToken;
    instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;

}

instance.interceptors.request.use(
    async (config) => {
        const JWT_token = localStorage.accessToken;
        if (JWT_token) {
        config.headers["Authorization"] = `Bearer ${JWT_token}`;
        }

        return config;
    },
    (error) => {

        toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
        });
        if (error.response.status === 401) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userData");
            //window.location.pathname = "/";
        }
        return Promise.reject(error);
    }
);

export default instance;
