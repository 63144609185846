
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import './index.css';
import Ico2 from "../../../assets/img/info.png";
import Ico3 from "../../../assets/img/warning.png";
import Ico4 from "../../../assets/img/critical.png";

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="max-w-620"
    >
      <Modal.Header closeButton className="custom-font-size">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fw-normal color-black f-18"
        >Label Defination
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-font-size text-indent-15">
       
        <p><span style={{color: "#559150"}}>Green</span> : Varience {">"}= 100% Performance </p>
        <p><span style={{color: "#dfea05"}}>Yellow</span> : Varience {">"}= 98% and {"<"} 100% Performance </p>
        <p><span style={{color: "#cc0d11"}}>Red</span> : Varience {"<"} 98% Performance </p>
        <p><span style={{color: "#ea343f"}}>X</span> : Varience {">"}= $15,000 </p>

      </Modal.Body>
    </Modal>
  );
}

export default InfoModal

