import React, { useEffect, useContext } from "react";
import ContentLoader from "react-content-loader";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import {
    setUpdateDate,
    setUpdateIndex,
    setUpdateStack,
} from "../../store/actions/Compensate";
import {
    formatNumberWithFraction,
} from "../../utils/helper";
import { ThemeContext } from "../../providers/ThemeProvider";

const GraphLoader = ({ themeMode }) => (
    <ContentLoader
        viewBox="0 0 280 100"
        backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
        foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
        {/* Only SVG shapes */}
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="100" />
    </ContentLoader>
);

const StackedBarChart = ({ data, graphClick }) => {
    const dispatch = useDispatch();
    const [themeMode, setTheme] = useContext(ThemeContext);

    // if (!data || !Array.isArray(data) || data.length === 0) {
    //   return <GraphLoader themeMode={themeMode} />;
    // }

    const series =

        data[0]?.data?.map((product) => ({
            name: product.name,
            data: product.data,
        })) || [];

    const categories = data[0]?.graphDisplayDateWithDay || [];
    const categoriesTwo = data[0]?.dates || [];
    const yMax = data[0]?.yMax;

    const handleClick = (event, chartContext, config) => {
        // Handle the click event here
        // console.log(chartContext);

        // const clickedDataPoint = data[config.dataPointIndex];
        if (config.dataPointIndex !== undefined) {
            // Calculate which bar was clicked based on the cursor position
            if (graphClick) {
                const clickedDataPoint = categoriesTwo[config?.dataPointIndex];
                if (clickedDataPoint) {
                    const clickedDate = clickedDataPoint;
                    dispatch(setUpdateStack(clickedDate));
                    graphClick(clickedDate);
                }
            }
        }
    };

     const formatYaxisfun = (num) => {
       if (num <= 0.09) {
         return formatNumberWithFraction(num, 3);
       } else if (num > 0.09 && num <= 0.9) {
         return formatNumberWithFraction(num, 2);
       } else {
         return formatNumberWithFraction(num, 1);
       }
     };

    const options = {
      chart: {
        type: "bar",
        // stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: handleClick,
        },
        zoom: {
          enabled: true,
        },
      },
      colors:
        themeMode.theme === "DARK"
          ? ["#ffffff", "#1c87cf"]
          : ["#000000", "#1c87cf"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          // columnWidth: '55%',
          // barHeight: '70%',
          // borderRadius: 10,
          // endingShape: 'rounded',
          dataLabels: {
            position: "top", // top, center, bottom
            //  orientation: 'vertical',
          },
        },
      },
      dataLabels: {
        enabled: true,
        hideOverflowingLabels: true,

        formatter: function (val) {
          // return val + "%";
          return val === 0 ? "" : formatNumberWithFraction(val, 2);
        },
        offsetX: 0,
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
          padding: {
            top: 30,
          },
        },
      },
      xaxis: {
        categories: categories.map((dataPoint) => [
          dataPoint?.date,
          dataPoint?.shortDay,
        ]),
        labels: {
          formatter: function (val) {
            // Check if the value is a number before formatting
            return !isNaN(val) ? parseFloat(val).toFixed(1) : val;
          },
          style: {
            fontSize: "12px", // Reduce the font size as needed
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
          // rotate: -25,
          // rotateAlways: true,
          hideOverlappingLabels: false,
          showDuplicates: true,
          offsetY: 0,
          maxHeight: 80,
          show: true,
        },
      },
      yaxis: {
        max: yMax,
        tickAmount: 5,
        labels: {
          formatter: function (val) {
            // Format y-axis values to 1 decimal place
            return formatYaxisfun(val);
          },
          style: {
            fontSize: "12px", // Reduce the font size as needed
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
        },
      },
      legend: {
        show: false,
        position: "top",
        offsetY: 0,
        fontSize: "14px",
        horizontalAlign: "right",
        textTransformation: "capitalize",
        // width: "750",
        // markers: {
        //   width: 12,
        //   height: 12,
        //   strokeWidth: 0,
        //   strokeColor: "#fff",
        //   fillColors: undefined,
        //   radius: 12,
        //   customHTML: undefined,
        //   onClick: undefined,
        //   offsetX: 0,
        //   offsetY: 0,

        // },
        // itemMargin: {
        //   horizontal: 2,
        //   vertical: 0,
        // },
        itemMargin: {
          horizontal: 90,
          vertical: 0,
          //  with: 200
        },
        labels: {
          useSeriesColors: true, // To use the same color as the series
          style: {
            textTransformation: "capitalize", // Capitalize the first letter
            //display: "inline-block", // Display the label inline with the marker
            textAlign: "center", // Center-align the text
            fontSize: "4px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        show: false,
        //  text: "Exceptions By Date",
        floating: true,

        align: "center",
        margin: 55,
        offsetX: 0,
        offsetY: -12,
        style: {
          color: "#4B4D4D",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "F37 Moon",
        },
      },
      grid: {
        show: true, // Remove both x-axis and y-axis grid lines
        borderColor: themeMode.theme === "DARK" ? "#454545" : "#d3d3d3",
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "12px",
          color: "#000",
        },

        x: {
          show: false,
        },
        y: {
          formatter: function (val) {
            // return val + "%"
            return formatNumberWithFraction(val, 2);
          },
        },
        annotations: {
          points: "dsdsd",
        },
      },
    };
    return (
        <div id="chart" className="height-80v">
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                // height="100%"
            height={280}
            />
        </div>
    );
};

export default StackedBarChart;
