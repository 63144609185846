import React, { useEffect, useState, useCallback, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { API_CALL_URL_PRIVATE } from "../../../constants/";
import axios from "../../../axios";
import { SocketContext } from "../../../socket";
import { getPreviousAndCurrentShift } from "../../../store/actions/Compensate";
import BedIcon from "../../../assets/img/bed-tb.png";
import AcuityIcon from "../../../assets/img/acuity.png";
import ChargeIcon from "../../../assets/img/charge.png";
import CnaIcon from "../../../assets/img/cna.png";
import OrientationIcon from "../../../assets/img/orientation.png";
import NurseIcon from "../../../assets/img/nurse.png";
import ReseIcon from "../../../assets/img/res-brk-en.png";
import UnitIcon from "../../../assets/img/unit_sec.png";
import SitterIcon from "../../../assets/img/sitter.png";
import AdmitsIcon from "../../../assets/img/admits.png";
import DischargesIcon from "../../../assets/img/discharges.png";
import TpatientsIcon from "../../../assets/img/telemetry_patients.png";
import DripsIcon from "../../../assets/img/drips.png";

// ----------------------Dark View Icon
import BedIcon1 from "../../../assets/img/bed-tb1.png";
import AcuityIcon1 from "../../../assets/img/acuity1.png";
import ChargeIcon1 from "../../../assets/img/charge1.png";
import CnaIcon1 from "../../../assets/img/cna1.png";
import OrientationIcon1 from "../../../assets/img/orientation1.png";
import NurseIcon1 from "../../../assets/img/nurse1.png";
import ReseIcon1 from "../../../assets/img/res-brk-en1.png";
import UnitIcon1 from "../../../assets/img/unit_sec1.png";
import SitterIcon1 from "../../../assets/img/sitter1.png";
import AdmitsIcon1 from "../../../assets/img/admits1.png";
import DischargesIcon1 from "../../../assets/img/discharges1.png";
import TpatientsIcon1 from "../../../assets/img/telemetry_patients1.png";
import DripsIcon1 from "../../../assets/img/drips1.png";
import IsolationLight from "../../../assets/img/Isolation_light.png";
import IsolationDark from "../../../assets/img/Isolation_dark.png";

import { ThemeContext } from "../../../providers/ThemeProvider";
import { THEME_TYPE } from "../../../constants/theme";
import SHIFT_FORM from "../../../constants/shiftForm";

import moment from "moment";

const init = {
  acuity: "",
  wit: "",
  actual_admit: "",
  ant_admit: "",
  charge: "",
  cna: "",
  comment: "",
  cost_center_number: "",
  currentShiftNumber: "",
  one_five: "",
  one_six: "",
  one_four: "",
  id: "",
  nurses: "",
  one_one: "",
  two_one: "",
  orientation: "",
  res_brk_rn: "",
  shift_time: "",
  sitter: "",
  telemetry: "",
  one_three: "",
  shift_date: "",
  total_patients: "",
  total_patients_two: "",
  one_two: "",
  unit_sec: "",
  vaso_active_drips: "",
  isolation_patients: "",
};

function UpdateModal({
  isShow,
  callbackfunc,
  reloaddata,
  data_id,
  getprevAndCurrentShift,
  gridData,
  cost_center_number,
  cost_center_name,
  numbers,
  getPreAndCurr,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const shiftLength = 4;
  const [formdata, setFormdata] = useState(init);
  const userType = JSON.parse(localStorage.getItem("psdata"))?.data?.user_type; //userType 0 for admin
  const socket = useContext(SocketContext);
  const [successMsg, setSuccessmsg] = useState("");
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const detail_data = selector.compensate.departmentdetail;
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const [variance, setVariance] = useState({});
  const [totalvariance, setTotalVar] = useState(0);
  const [dataid, setDataid] = useState(null);
  //console.log("sdsdds", contentone);
  const [gridUpdateData, setGridUpdateData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [totalPatientvariance, setTotalPatientVar] = useState(0);
  const activedatefilter = selector.compensate?.activedatefilter;
  const [submitedBy, setSubmitedBy] = useState("");
  const [isKeyEmpty, setIsKeyEmpty] = useState(false);
  const previousShiftData =
    selector.compensate.getPreviousShiftData?.directData;
  const [rnTotal, setRnTotal] = useState(0);
  const [rnAvailableData, setRnAvailableData] = useState({});
  const [witRn, setWitRn] = useState(0);
   const [defaultMode, setDefaultMode] = useState(true);


  const setvarianceValue = (event) => {

    if (event.target.name == "charge") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_charge
            ? gridUpdateData?.required_charge
            : 0),
      });
      const { charge, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_charge
            ? gridUpdateData?.required_charge
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "cna") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_cna ? gridUpdateData?.required_cna : 0),
      });
      const { cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_cna ? gridUpdateData?.required_cna : 0));
      setTotalVar(sum);
    } else if (event.target.name == "orientation") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.orientation ? gridUpdateData?.orientation : 0),
      });
      const { orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.orientation ? gridUpdateData?.orientation : 0));
      setTotalVar(sum);
    } else if (event.target.name == "nurses") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_nurses
            ? gridUpdateData?.required_nurses
            : 0),
      });
      const { nurses, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_nurses
            ? gridUpdateData?.required_nurses
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "res_brk_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.required_res_brk_rn
            ? gridUpdateData?.required_res_brk_rn
            : 0),
      });
      const { res_brk_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.required_res_brk_rn
            ? gridUpdateData?.required_res_brk_rn
            : 0));
      setTotalVar(sum);
    } else if (event.target.name == "unit_sec") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.unit_sec ? gridUpdateData?.unit_sec : 0),
      });
      const { unit_sec, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.unit_sec ? gridUpdateData?.unit_sec : 0));
      setTotalVar(sum);
    } else if (event.target.name == "sitter") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value -
          (gridUpdateData?.sitter ? gridUpdateData?.sitter : 0),
      });
      const { sitter, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value -
          (gridUpdateData?.sitter ? gridUpdateData?.sitter : 0));
      setTotalVar(sum);
    }
  };
  useEffect(() => {
    setGridUpdateData(gridData);
    setIsKeyEmpty(false);
    // setRnTotal(0);
    // getRnTotal();
    // console.log("hyere");
  }, [gridData]);

  useEffect(() => {
    getEditData(data_id.id);
  }, [data_id]);

  const setpartValue = (event) => {
    if (event.target.name == "two_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { two_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_two") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_two, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_three") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_three, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_four") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_four, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_five") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_five, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_six") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_six, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "wit") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { wit, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    }
  };

  const getEditData = async (parmid) => {
    try {
      if (parmid) {
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getDirectDataById",
          { id: parmid }
        );
        const { data } = await resp;
        const gridapidata = data.data.gridData;
        //console.log("response update data", previousShiftData);
        //console.log("response update data", data.data);
        setTotalPatientVar(data.data.directData?.total_patients);
        setPatientData({
          two_one: data.data.directData?.two_one,
          one_one: data.data.directData?.one_one,
          one_two: data.data.directData?.one_two,
          one_three: data.data.directData?.one_three,
          one_four: data.data.directData?.one_four,
          one_five: data.data.directData?.one_five,
          one_six: data.data.directData?.one_six,
          wit: data.data.directData?.wit,
        });
        setFormdata({
          ...data.data.directData,
          total_patients_two: data.data.directData.total_patients,
        });
        setSubmitedBy(data.data?.submitted_by);
        const totalvar =
          data.data.directData?.charge -
          (gridapidata?.required_charge ? gridapidata?.required_charge : 0) +
          (data.data.directData?.cna -
            (gridapidata?.required_cna ? gridapidata?.required_cna : 0)) +
          (data.data.directData?.orientation -
            (gridapidata?.orientation ? gridapidata?.orientation : 0)) +
          (data.data.directData?.nurses -
            (gridapidata?.required_nurses ? gridapidata?.required_nurses : 0)) +
          (data.data.directData?.res_brk_rn -
            (gridapidata?.required_res_brk_rn
              ? gridapidata?.required_res_brk_rn
              : 0)) +
          (data.data.directData?.unit_sec -
            (gridapidata?.unit_sec ? gridapidata?.unit_sec : 0)) +
          (data.data.directData?.sitter -
            (gridapidata?.sitter ? gridapidata?.sitter : 0));
        setTotalVar(totalvar);

        setVariance({
          charge:
            (data.data.directData?.charge ? data.data.directData?.charge : 0) -
            (gridapidata?.required_charge ? gridapidata?.required_charge : 0),
          cna:
            (data.data.directData?.cna ? data.data.directData?.cna : 0) -
            (gridapidata?.required_cna ? gridapidata?.required_cna : 0),
          orientation:
            (data.data.directData?.orientation
              ? data.data.directData?.orientation
              : 0) - (gridapidata?.orientation ? gridapidata?.orientation : 0),
          nurses:
            (data.data.directData?.nurses ? data.data.directData?.nurses : 0) -
            (gridapidata?.required_nurses ? gridapidata?.required_nurses : 0),
          res_brk_rn:
            (data.data.directData?.res_brk_rn
              ? data.data.directData?.res_brk_rn
              : 0) -
            (gridapidata?.required_res_brk_rn
              ? gridapidata?.required_res_brk_rn
              : 0),
          sitter:
            (data.data.directData?.sitter ? data.data.directData?.sitter : 0) -
            (gridapidata?.sitter ? gridapidata?.sitter : 0),
          unit_sec:
            (data.data.directData?.unit_sec
              ? data.data.directData?.unit_sec
              : 0) - (gridapidata?.unit_sec ? gridapidata?.unit_sec : 0),
        });
        getRnTotal(data.data.directData);
      } else {
        setFormdata(init);
        setTotalPatientVar(0);
      }

      //console.log(variance, 'variance variance');
      //dispatch(setEditDepartmentDetail(data.data.directData));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
    setIsKeyEmpty(false);
    setpartValue(event);
    setvarianceValue(event);
    setRnHourValue(event);
  };

  const setRnHourValue = (event) => {
    if (event.target.name == "two_one") {
      const rnHourSum = 2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { two_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_one") {
      const rnHourSum = 1 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
      //setFormdata(...formdata, one_one);
    } else if (event.target.name == "one_two") {
      const rnHourSum = 0.5 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_two, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_three") {
      const rnHourSum = 0.33 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_three, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_four") {
      const rnHourSum = 0.25 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_four, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_five") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_five, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_six") {
      const rnHourSum = 0.16666 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_six, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "nurses") {
      const { ...rest } = rnAvailableData;
      const sum =
        Number(event.target.value) * shiftLength -
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) * 4;
      setRnTotal(sum?.toFixed(2));
    }
  };

  const getRnTotal = async (shiftdata) => {
    const rnHourtwoOne = 2 * Number(shiftdata?.two_one) ?? 0;
    const rnHourOneOne = 1 * Number(shiftdata?.one_one) ?? 0;
    const rnHourOneTwo = 0.5 * Number(shiftdata?.one_two) ?? 0;
    const rnHourOneThree = 0.33 * Number(shiftdata?.one_three) ?? 0;
    const rnHourOneFour = 0.25 * Number(shiftdata?.one_four) ?? 0;
    const rnHourOneFive = 0.2 * Number(shiftdata?.one_five) ?? 0;
    const rnHourOneSix = 0.16666 * Number(shiftdata?.one_six) ?? 0;

    const rnSumAll =
      rnHourtwoOne +
      rnHourOneOne +
      rnHourOneTwo +
      rnHourOneThree +
      rnHourOneFour +
      rnHourOneFive +
      rnHourOneSix;

    const rnHourNurse = Number(shiftdata?.nurses) ?? 0;
    const totalRnSum = rnHourNurse * 4 - rnSumAll * 4;
    console.log("finalVal", totalRnSum);

    const updatedRnAvailableData = {
      ...rnAvailableData,
      two_one: rnHourtwoOne,
      one_one: rnHourOneOne,
      one_two: rnHourOneTwo,
      one_three: rnHourOneThree,
      one_four: rnHourOneFour,
      one_five: rnHourOneFive,
      one_six: rnHourOneSix,
    };
    // await new Promise(resolve => setTimeout(resolve, 2));
    setRnAvailableData(updatedRnAvailableData);
    setRnTotal(totalRnSum?.toFixed(2));
  };

  const getDirectDataById = async (title, sum, event) => {
    try {
      // alert(title)
      //console.log(contentId, "contentId");
      let el = document.querySelectorAll(".apicall");
      // if (event.target.value != "") {
      //   el.forEach((item) => item.setAttribute("disabled", true));
      // }
      var dt = moment(previousShiftData?.shift_time, ["h:mm:ss A"]).format(
        "HH:mm:ss"
      );
      const getgridparams = {
        cost_center_number: cost_center_number,
        shift_time: dt,
        census: sum != null ? sum : totalPatientvariance,
      };
      let addgriddata = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getGridData",
        getgridparams
      );
      let addgridresult = await addgriddata;
      const gridresult = addgridresult.data.data.gridData;
      console.log("gridresult", gridresult);
      setGridUpdateData(gridresult);
      //setDirectData(data.data)
      let StaffingDataObj = {
        charge:
          (formdata?.charge ? formdata?.charge : 0) -
          (gridresult?.required_charge ? gridresult?.required_charge : 0),
        cna:
          (formdata?.cna ? formdata?.cna : 0) -
          (gridresult?.required_cna ? gridresult?.required_cna : 0),
        orientation:
          (formdata?.orientation ? formdata?.orientation : 0) -
          (gridresult?.orientation ? gridresult?.orientation : 0),
        nurses:
          (formdata?.nurses ? formdata?.nurses : 0) -
          (gridresult?.required_nurses ? gridresult?.required_nurses : 0),
        res_brk_rn:
          (formdata?.res_brk_rn ? formdata?.res_brk_rn : 0) -
          (gridresult?.required_res_brk_rn
            ? gridresult?.required_res_brk_rn
            : 0),
        sitter:
          (formdata?.sitter ? formdata?.sitter : 0) -
          (gridresult?.sitter ? gridresult?.sitter : 0),
        unit_sec:
          (formdata?.unit_sec ? formdata?.unit_sec : 0) -
          (gridresult?.unit_sec ? gridresult?.unit_sec : 0),
      };
      const updateFormdata = async () => {
        return new Promise((resolve) => {
          setFormdata((prevFormdata) => {
            const updatedFormdata = {
              ...prevFormdata,
              total_patients: sum,
              [event.target.name]: event.target.value,
            };

            resolve(updatedFormdata);
            return updatedFormdata;
          });
        });
      };
      await updateFormdata();
      setVariance(StaffingDataObj);
      setTotalVar(Object.values(StaffingDataObj).reduce((a, b) => a + b, 0));
      // el.forEach((item) => item.removeAttribute("disabled"));
    } catch (error) {
      console.log(error);
    }
  };

  //console.log("updated data", formdata);

  const getFirstEmptyKey = (formdata) => {
    const keysToIgnore = [
      "cost_center_number",
      "shift_date",
      "shift_time",
      "total_patients",
      "id",
      "currentShiftNumber",
    ];

    const emptyKeys = Object.keys(formdata)
      .filter((key) => !keysToIgnore.includes(key))
      .filter(
        (key) =>
          formdata[key] === undefined ||
          formdata[key] === null ||
          formdata[key] === ""
      );

    if (emptyKeys.length > 1) {
      return `Please Enter Missing Data`;
    } else if (emptyKeys.length === 1) {
      return "Please Enter Missing" + " " + SHIFT_FORM.VALIDATION_NAMES[emptyKeys[0]];
    } else {
      return false;
    }
  };

  const submitHandle = async () => {
    let submitdata = formdata;
    let ischecked = [];
    submitdata["id"] = submitdata._id;
    submitdata["grid_variance"] = totalvariance;
    submitdata["charge_variance"] = variance?.charge;
    submitdata["cna_variance"] = variance?.cna;
    submitdata["nurses_variance"] = variance?.nurses;
    submitdata["rn_available_hours"] = rnTotal;
    submitdata["wit_rn"] = witRn;
    submitdata["res_brk_rn_variance"] = variance?.res_brk_rn;
    submitdata["sitter_variance"] = variance?.sitter;
    submitdata["unit_sec_variance"] = variance?.unit_sec;
    submitdata["orientation_variance"] = variance?.orientation;
    const checkval = Object.keys(submitdata).map((key) => {
      if (key != "notes") {
        if (submitdata[key] === null || submitdata[key] === "") {
          //console.log("checked");
          ischecked.push(false);
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          //console.log(el, "checked");
          if (el != null) {
            el.classList.add("redborder");
          }
        } else {
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          //console.log(el, "checked");
          if (el != null) {
            el.classList.remove("redborder");
            const getResult = getFirstEmptyKey(formdata);
            setIsKeyEmpty(getResult);
          }
          ischecked.push(true);
        }
      }
    });
    const checked = ischecked.every((element) => element === true);
    if (!checked) {
      return false;
    }

    if (+submitdata?.total_patients_two !== submitdata?.total_patients) {
      setIsKeyEmpty(
        "Please check Total Patients and Patient Acuity. Values do not match."
      );
      return false;
    }
    try {
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/addupdateDirectEntery",
        submitdata
      );
      const { data } = await resp;
      socket.emit("csvUpdate", { csvAdd: false, csvUpdate: true });
      // socket.emit("csvUpdate",{csvAdd:false,csvUpdate:true})
      // setSuccessmsg(data.message);
      //   if (data.message == "Data updated succesfully") {
      reloaddata(data?.response?._id);
      callbackfunc(false, true, data.message);
      //   }
      //callbackfunc(false, true, data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const closeHandle = () => {
    reloaddata();
    callbackfunc(false);
  };

  useEffect(() => {
    const witValue = formdata?.wit;
    const rnTotalValue = formdata?.nurses;

    if (isNaN(witValue) || isNaN(rnTotalValue) || rnTotalValue === 0) {
      setWitRn(0);
    } else {
      const witRnDivider = witValue / rnTotalValue;
      if (isFinite(witRnDivider)) {
        const roundedValue = +witRnDivider.toFixed(2);
        setWitRn(roundedValue);
      } else {
        setWitRn(0);
      }
    }
  }, [formdata?.wit, formdata?.nurses]);

  console.log("formdata", formdata);
  console.log("rntotal", rnTotal);
  console.log("rnAvailableData", rnAvailableData);

  return (
    <Modal
      show={isShow}
      onHide={() => callbackfunc(false, false, successMsg)}
      transparent={true}
      centered
    >
      <div className=" modal-dialog-centered modal-lg">
        {defaultMode ? (
          <div className="modal-content pb-5">
            <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
              <h3 className="modal-title fw-400">
                Direct Entry Tool is disabled.
              </h3>
              <button
                type="button"
                onClick={closeHandle}
                className="btn-close ms-5 position-absolute right-20 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body"></div>
          </div>
        ) : (
          <div className="modal-content pb-4">
            <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
              <h5 class="modal-title fw-400 text-uppercase">
                Editing - {cost_center_name}&nbsp;-&nbsp;
                <span class="">
                  {activedatefilter?.shift_date} {activedatefilter?.shift_time}{" "}
                  INTERVAL
                </span>
              </h5>
              {/* <!-- <button type="button" className="ms-auto btn btn-blue text-capitalize f-12 fw-500"><i className="fa fa-plus-circle" aria-hidden="true"></i> Pull From Previous Shift (7AM)</button> --> */}
              <button
                type="button"
                onClick={closeHandle}
                className="btn-close ms-5 position-absolute right-20 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body inputnumbericonhide py-0 para-5">
              <div className="row">
                <div className="col-md-3 border-end w1">
                  <div className=" text-center">
                    <h6 className="fw-400 fw lh-1">Patient Data</h6>
                  </div>
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        {userType === 0 && <th>Previous</th>}
                        {/* <br /> {getprevAndCurrentShift?.previousShift} */}

                        <th>{activedatefilter?.shift_time}</th>
                      </tr>
                      {process.env.React_App_ENVIRONMENT === "development" && (
                        <tr>
                          <td className="t">WIT</td>
                          <td class="img">
                            <span class="circle-i">WIT</span>
                          </td>
                          {userType === 0 && (
                            <td>
                              <input
                                className="form-control"
                                name="wit"
                                value={previousShiftData?.wit}
                                type="text"
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                          )}
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.wit === "" ? "redborder" : ""
                              }`}
                              name="wit"
                              value={formdata?.wit != null ? formdata.wit : ""}
                              type="number"
                              onChange={handleChange}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="t">Acuity</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? AcuityIcon1
                                : AcuityIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="acuity1"
                              value={previousShiftData?.acuity}
                              type="text"
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.acuity === "" ? "redborder" : ""
                            }`}
                            name="acuity"
                            value={
                              formdata?.acuity != null ? formdata.acuity : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Total Patients</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? BedIcon1 : BedIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="total_patients_two"
                              value={previousShiftData?.total_patients_two}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.total_patients_two === ""
                                ? "redborder"
                                : ""
                            }`}
                            name="total_patients_two"
                            value={
                              formdata?.total_patients_two != null
                                ? formdata.total_patients_two
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">2:1</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="two_one"
                              value={
                                previousShiftData?.two_one
                                  ? previousShiftData?.two_one
                                  : 0
                              }
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.two_one === "" ? "redborder" : ""
                            }`}
                            name="two_one"
                            value={
                              formdata?.two_one != null ? formdata.two_one : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:1</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="one_one"
                              value={previousShiftData?.one_one}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_one === "" ? "redborder" : ""
                            }`}
                            name="one_one"
                            value={
                              formdata?.one_one != null ? formdata.one_one : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:2</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="one_two1"
                              value={previousShiftData?.one_two}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_two === "" ? "redborder" : ""
                            }`}
                            name="one_two"
                            value={
                              formdata?.one_two != null ? formdata.one_two : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:3</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="one_three1"
                              value={previousShiftData?.one_three}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_three === "" ? "redborder" : ""
                            }`}
                            name="one_three"
                            value={
                              formdata?.one_three != null
                                ? formdata.one_three
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:4</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="one_four"
                              value={previousShiftData?.one_four}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_four === "" ? "redborder" : ""
                            }`}
                            name="one_four"
                            value={
                              formdata?.one_four != null
                                ? formdata?.one_four
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:5</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="one_five"
                              value={previousShiftData?.one_five}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_five === "" ? "redborder" : ""
                            }`}
                            name="one_five"
                            value={
                              formdata?.one_five != null
                                ? formdata.one_five
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:6</span>
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              name="one_six"
                              value={
                                previousShiftData?.one_six
                                  ? previousShiftData?.one_six
                                  : 0
                              }
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_six === "" ? "redborder" : ""
                            }`}
                            name="one_six"
                            value={
                              formdata?.one_six != null ? formdata.one_six : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Total Patients</td>
                        <td class="img last-i">
                          <img
                            src={
                              themeMode.theme === "DARK" ? BedIcon1 : BedIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && <td></td>}
                        <td>
                          <hr className="totalline my-1" />
                          <div
                            type="button"
                            className={`tooltip left-tool position-relative ${
                              totalPatientvariance < 0 ? "textdanger" : ""
                            }`}
                          >
                            {totalPatientvariance}
                            <span class="tooltiptext">Total</span>
                          </div>
                        </td>
                      </tr>
                      {process.env.React_App_ENVIRONMENT === "development" && (
                        <tr>
                          <td className="t ml-4">RN Available Hours:</td>
                          <td></td>
                          {userType === 0 && <td></td>}
                          <td>
                            <input
                              className="form-control"
                              // name="one_six"
                              value={rnTotal}
                              type="text"
                              // disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        </tr>
                      )}
                      {process.env.React_App_ENVIRONMENT === "development" && (
                        <tr>
                          <td className="t ml-4">WIT/RN:</td>
                          <td></td>
                          {userType === 0 && <td></td>}
                          <td>
                            <input
                              className="form-control"
                              // name="one_six"
                              value={witRn}
                              type="text"
                              // disabled
                              // onChange={handleWitRn}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div class="col mt-4">
                    <div class="f-14 text-start">
                      Last Updated By: {submitedBy}
                    </div>
                  </div>
                </div>
                <div className="col-md-5 w2">
                  <div className=" text-center">
                    <h6 className="fw-400 fw lh-1">Staffing</h6>
                  </div>
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        {userType === 0 && <th>Previous</th>}
                        <th>{activedatefilter?.shift_time}</th>
                        <th>Grid</th>
                        <th>Variance</th>
                      </tr>
                      <tr>
                        <td className="t">Charge:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? ChargeIcon1
                                : ChargeIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.charge}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            name="charge"
                            value={formdata?.charge}
                            className={`form-control ${
                              formdata?.charge === "" ? "redborder" : ""
                            }`}
                            onChange={handleChange}
                            type="number"
                            aria-label="input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.required_charge
                                ? gridUpdateData.required_charge
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.charge > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.charge}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Charge</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">CNA:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? CnaIcon1 : CnaIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.cna}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className="form-control"
                            name="cna"
                            value={formdata?.cna != null ? formdata?.cna : ""}
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.required_cna
                                ? gridUpdateData?.required_cna
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.cna > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.cna}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">CNA</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Orientation:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? OrientationIcon1
                                : OrientationIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.orientation}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.orientation === "" ? "redborder" : ""
                            }`}
                            name="orientation"
                            type="number"
                            value={formdata?.orientation}
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.orientation
                                ? gridUpdateData?.orientation
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.orientation > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.orientation}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Orientation</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">RN:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? NurseIcon1
                                : NurseIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.nurses}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.nurses === "" ? "redborder" : ""
                            }`}
                            name="nurses"
                            type="number"
                            value={formdata?.nurses}
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.required_nurses
                                ? gridUpdateData?.required_nurses
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.nurses > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.nurses}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">RN</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Res/Brk RN:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? ReseIcon1 : ReseIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.res_brk_rn}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.res_brk_rn === "" ? "redborder" : ""
                            }`}
                            name="res_brk_rn"
                            type="number"
                            value={formdata?.res_brk_rn}
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.required_res_brk_rn
                                ? gridUpdateData?.required_res_brk_rn
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.res_brk_rn > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.res_brk_rn}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Res/Br/k RN</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Unit Sec:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? UnitIcon1 : UnitIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.unit_sec}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.unit_sec === "" ? "redborder" : ""
                            }`}
                            name="unit_sec"
                            type="number"
                            value={formdata?.unit_sec}
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.unit_sec
                                ? gridUpdateData?.unit_sec
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.unit_sec > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.unit_sec}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Unit Sec</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Sitter:</td>
                        <td class="img last-i">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? SitterIcon1
                                : SitterIcon
                            }
                            alt=""
                          />
                        </td>
                        {userType === 0 && (
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={previousShiftData?.sitter}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        )}
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.sitter === "" ? "redborder" : ""
                            }`}
                            name="sitter"
                            type="number"
                            value={formdata?.sitter}
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridUpdateData?.sitter
                                ? gridUpdateData?.sitter
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.sitter > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              disabled
                              value={variance?.sitter}
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Sitter</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {userType === 0 && <td></td>}
                        <td>
                          <hr className="totalline my-1" />
                          <div
                            type="button"
                            className={`tooltip left-tool position-relative ${
                              totalvariance > 0 ? "textdanger" : ""
                            }`}
                          >
                            {totalvariance}
                            <span class="tooltiptext">Total</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-4 border-start">
                  <div className=" text-center">
                    <h6 className="fw-400 fw lh-1">Additional Data Tracking</h6>
                  </div>
                  <div className="height-417">
                    <table className="w-100 mb-2 m-g-2">
                      <tbody>
                        <tr className="hd">
                          <th></th>
                          <th></th>
                          {userType === 0 && <th>Previous</th>}
                          {/* <th> Previous <br /> {contentone.shift}</th> */}
                          <th>{activedatefilter?.shift_time}</th>
                        </tr>
                        <tr>
                          <td className="t">
                            # Anticipated Admits/Transfers in this Shift
                          </td>
                          <td class="img">
                            {/* <img src={AdmitsIcon} alt="" /> */}
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? AdmitsIcon1
                                  : AdmitsIcon
                              }
                              alt=""
                            />
                          </td>
                          {userType === 0 && (
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={previousShiftData?.ant_admit}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                          )}
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.ant_admit === "" ? "redborder" : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="ant_admit"
                              value={formdata?.ant_admit}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">
                            # Actual Admits/Transfers in previous Shift
                          </td>
                          <td class="img">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? DischargesIcon1
                                  : DischargesIcon
                              }
                              alt=""
                            />
                          </td>
                          {userType === 0 && (
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={previousShiftData?.actual_admit}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                          )}
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.actual_admit === "" ? "redborder" : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="actual_admit"
                              value={formdata?.actual_admit}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">Telemetry Patients</td>
                          <td class="img">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? TpatientsIcon1
                                  : TpatientsIcon
                              }
                              alt=""
                            />
                          </td>
                          {userType === 0 && (
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={previousShiftData?.telemetry}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                          )}
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.telemetry === "" ? "redborder" : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="telemetry"
                              value={formdata?.telemetry}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">Vaso-Active Drips</td>
                          <td class="img last-i">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? DripsIcon1
                                  : DripsIcon
                              }
                              alt=""
                            />
                          </td>
                          {userType === 0 && (
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={previousShiftData?.vaso_active_drips}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                          )}
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.vaso_active_drips === ""
                                  ? "redborder"
                                  : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="vaso_active_drips"
                              value={formdata?.vaso_active_drips}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">Isolation Patients</td>
                          <td class="img last-i">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? IsolationDark
                                  : IsolationLight
                              }
                              alt=""
                            />
                          </td>
                          {userType === 0 && (
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={previousShiftData?.isolation_patients}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                          )}
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.isolation_patients === ""
                                  ? "redborder"
                                  : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="isolation_patients"
                              value={formdata?.isolation_patients}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="col-md-12">
                        <div class="col">
                          <div className="m-f14">
                            Comments / Barriers / Action Plan{" "}
                          </div>
                          <div className="mt-1">
                            <textarea
                              className="form-control full"
                              onChange={handleChange}
                              name="comment"
                              value={formdata?.comment}
                              placeholder="Type Here..."
                              style={{ height: "70px" }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button
                        type="button"
                        onClick={closeHandle}
                        className="btn btn-red text-capitalize  f-12 fw-400 w-100"
                      >
                        {" "}
                        Cancel
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={submitHandle}
                        className="btn btn-success text-capitalize  f-12 fw-400 w-100 finsh"
                      >
                        {" "}
                        Update
                      </button>
                    </div>
                  </div>
                  {isKeyEmpty && (
                    <div className="real-validation m-f14 mt-2">
                      {isKeyEmpty}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UpdateModal;
