import React, { useEffect, useState, useCallback, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import DataTable from "../../components/DataTable";
import { ChartExample } from "../shared/PieChart/m-pei-chart";
import AddModal from "../ShiftModal/AddModal";
import SuccessModal from "../shared/SuccessModal";
import UpdateModal from "../ShiftModal/UpdateModal";
import PageLayout from "../../layouts/PageLayout";
import axios from "../../axios";
import { SocketContext } from "../../socket";
import { activedatefilter } from "../../store/actions/Compensate";
import { autoFillgetDirectDataById, getdataService } from "../../services";
import moment from "moment";
import ContentLoader from "react-content-loader";
import "./index.css";
import PREV from "../../assets/img/prev.png";
import PREVWHITE from "../../assets/img/left-white.png";
import NEXT from "../../assets/img/next.png";
import NEXTWHITE from "../../assets/img/next-white.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import BarChart from "../../components/shared/BarChart";
import BltChart from "../../components/shared/BltChart";
import DynamicModal from "../../components/ShiftModal/DynamicModal";
import DynamicUpdateModal from "../../components/ShiftModal/DynamicUpdateModal";
const MyLoader = () => (
  <div className="col-md-4 width-1">
    <ContentLoader viewBox="0 0 100 100">
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

function CurrentPrevModal({
  isShow,
  callbackfunc,
  id,
  data1,
  activedate,
  allDate,
  reloaddata,
  isLoader,
  graphControl,
  isCostcenter,
  reloadGraph,
  formShiftData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );
  const detail_data = selector.compensate.departmentdetail;
  const gridshiftdata = selector.compensate.gridshiftdatamodal.gridData;
  const directShiftData = selector.compensate.gridshiftdatamodal.directData;
  const multichart = detail_data?.left_sidebar
    ? detail_data.left_sidebar.sidebar_1
    : {};
  const selectedshift = selector.selectedshift;
  const dataTable = detail_data?.right_sidebar?.tableData
    ? detail_data.right_sidebar.tableData
    : [];
  const headcount = detail_data?.header ? detail_data.header : {};
  const cost_name = detail_data?.left_sidebar?.sidebar_1?.cost_center_name;
  const getprevAndCurrentShift = selector.compensate.previousAndCurrentShift;
  const lastShiftEntry = directShiftData;
  const lastShiftGridData = detail_data?.gridData;
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const form_control = detail_data?.content
    ? detail_data.content.form_control
    : {};

  const [addmodalshow, setAddmodalshow] = useState(false);
  const [showsucess, setShowsucess] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const [sucessmsg, setSucessmsg] = useState("");
  const [selectedcontentid, setSelectedcontentid] = useState({});
  // const [formShiftData, setFormShiftData] = useState([]);
  const [workDriverData, setWorkDriverData] = useState([]);

  useEffect(() => {
    setCurrentDate(activedate);
  }, [activedate]);

  // useEffect(() => {
  //   setWorkDriverData(dataTable);
  // }, [formShiftData]);

  const reportnotecallback = (obj, type) => {
    dispatch(activedatefilter(obj));
    if (type == 1) {
      autoFillgetDirectData(obj.id, false);
      setShowupdate(true);
      //handleShowUpdate();
    } else {
      autoFillgetDirectData(obj._id, false);
      setAddmodalshow(true);
    }
  };

  const autoFillgetDirectData = async (contentId, isdate) => {
    try {
      const resp = autoFillgetDirectDataById(dispatch, contentId, isdate);
      if (contentId) {
        setSelectedcontentid({ id: contentId });
      } else {
        setSelectedcontentid({ id: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const callBackhide = (value, issuceess, msg) => {
    setAddmodalshow(value);
    if (issuceess) {
      setShowsucess(true);
      setSucessmsg(msg);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
      //setShowsuccess(false);
    }
  };

  const addupdatereportnotecallback = () => {
    const datearr = currentDate.split("/");
    const filterdate = `${datearr[1]}-${datearr[0]}-${datearr[2]}`;
    const params = {
      cost_center_number: id,
      fromdate: {
        date: filterdate,
        shift_time: "03:00",
      },
      todate: {
        date: filterdate,
        shift_time: "23:00",
      },
    };
    const resp = getdataService(dispatch, params);
    reloaddata();
  };

  const currentPrevClick = (currenDate) => {
    const datearr = currenDate.split("/");
    const filterdate = `${datearr[1]}-${datearr[0]}-${datearr[2]}`;
    const params = {
      cost_center_number: id,
      fromdate: {
        date: filterdate,
        shift_time: "03:00",
      },
      todate: {
        date: filterdate,
        shift_time: "23:00",
      },
    };
    const resp = getdataService(dispatch, params);
    reloaddata();
  };

  const closeHandle = () => {
    reloaddata();
    reloadGraph();
    callbackfunc(false);
  };

  const findAdjacentDates = (targetDate) => {
    const index = allDate.findIndex((item) => item.date === targetDate);
    if (index !== -1) {
      const previousDate = index > 0 ? allDate[index - 1].date : null;
      const nextDate =
        index < allDate.length - 1 ? allDate[index + 1].date : null;

      return { previousDate, nextDate };
    } else {
      return { previousDate: null, nextDate: null };
    }
  };

  const handlePreviousClick = () => {
    const { previousDate } = findAdjacentDates(currentDate);
    if (previousDate) {
      currentPrevClick(previousDate);
      setCurrentDate(previousDate);
    }
  };

  const handleNextClick = () => {
    const { nextDate } = findAdjacentDates(currentDate);
    if (nextDate) {
      currentPrevClick(nextDate);
      setCurrentDate(nextDate);
    }
  };

  // const getBarData = () => {
  //   setWorkDriverData(dataTable);
  // };


   const processChartData = (dataKey) => {
     let linechartseries = [];
     if (costCenterData?.interval === 24) {
       const firstItem = dataTable?.[0] || {};
       firstItem &&
         linechartseries.push({
           shift_time: firstItem?.shift_date_md,
           value: +firstItem?.[dataKey],
         });
     } else {
       linechartseries = dataTable?.map((item) => ({
         shift_time: item.shift_time,
         value: +item[dataKey],
       }));
     }
     const allZeros = linechartseries.every((obj) => +obj.value === 0);
     return allZeros
       ? linechartseries.map((obj) => ({ ...obj, value: undefined }))
       : linechartseries;
   };

   const processDidBlt = (dataKey) => {
     let didBltseries = [];
     if (costCenterData?.interval === 24) {
       const firstItem = dataTable?.[0] || {};
       firstItem &&
         didBltseries.push({
           shift_time: firstItem?.shift_date_md,
           value:
             firstItem?.[dataKey] !== null
               ? firstItem?.[dataKey]
                 ? 1
                 : 1.01
               : null,
         });
     } else {
       didBltseries = dataTable?.map((item) => ({
         shift_time: item.shift_time,
         value: item[dataKey] !== null ? (item[dataKey] ? 1 : 1.01) : null,
       }));
     }
     //  const didBltseries = dataTable?.map((item) => ({
     //    shift_time: item.shift_time,
     //    value: item[dataKey] !== null ? (item[dataKey] ? 1 : 1.01) : null,
     //  }));
     const allDidZeros = didBltseries.every((obj) => +obj.value === 0);
     return didBltseries;
   };

  return (
    <div className="kkkkkkkkkkkkkkk">
      <Modal
        show={isShow}
        className="modelcurrprev left-right-btn"
        onHide={closeHandle}
        transparent={true}
        centered
      >
        <button
          type="button"
          onClick={closeHandle}
          className="btn-close ms-5 closeabsolute right-20"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <Modal.Body className="p-0 px-5 position-relative">
          {allDate[0]?.date !== currentDate && (
            <button
              className="border-0 prev"
              onClick={() => {
                handlePreviousClick();
              }}
            >
              <img src={themeMode.theme === "DARK" ? PREVWHITE : PREV} />
            </button>
          )}

          {allDate[allDate.length - 1]?.date !== currentDate && (
            <button
              className="border-0 next"
              onClick={() => {
                handleNextClick();
              }}
            >
              <img src={themeMode.theme === "DARK" ? NEXTWHITE : NEXT} />
            </button>
          )}
          <PageLayout
            data={headcount}
            issearch={false}
            isshow={true}
            cost_name={
              cost_name && currentDate
                ? `${cost_name?.toUpperCase()} - ${currentDate}`
                : ""
            }
            isLoader={false}
            callbackfunc={""}
            isCallback={false}
            cost_center_number={null}
            filterdate={""}
            islogostrip={true}
            costCenterDropdow={true}
            emergencyVisits={
              costCenterData?.cost_center_category === 6 ? true : false
            }
            uos24Interval={costCenterData?.interval === 24 ? true : false}
          >
            <div className="row mt-2 gx-2">
              <div className="col-md-12">
                <div className="row gx-2 h-100">
                  <div className="col-md-3 width-22 ">
                    <div className="white-bg shadow-box h-100 1">
                      <div class="box-heading text-center">
                        {/* Completion and Productivity Status */}
                        {costCenterData?.interval === 24
                          ? "Productivity "
                          : "Completion and Productivity Status"}
                      </div>
                      {Object.keys(multichart).length > 0 && !isLoader ? (
                        <ChartExample
                          data={multichart}
                          callbackfunc={reportnotecallback}
                          data1={multichart}
                          isdetail={true}
                          directId={id}
                          isCostcenter={true}
                          showCompilanceRing={
                            costCenterData?.show_compliance_ring
                          }
                          showProductivityRing={
                            costCenterData?.show_productivity_ring
                          }
                          isRealtimeToday={headcount?.isRealTime}
                        />
                      ) : (
                        <MyLoader />
                      )}
                      <div>
                        <div className="col-md-12 mt-3"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 width-53">
                    <div className="h-100">
                      <DataTable
                        cost_center_name={multichart.cost_center_name}
                        callbackfunc={reportnotecallback}
                        reloaddata={addupdatereportnotecallback}
                        directId={id}
                        realtimeshift={selectedshift}
                        isLoader={isLoader}
                        data={
                          costCenterData?.interval === 24
                            ? [dataTable[0]] || []
                            : dataTable
                        }
                        cost_center_number={id}
                      />
                    </div>
                  </div>

                  <div class="col-md-3 plr-4 width-22">
                    <div class="white-bg shadow-box pb-0 h-25p h-100">
                      <div class="box-heading text-center mb-2">
                        Workdrivers
                      </div>
                      {formShiftData
                        ?.sort((a, b) => a.display - b.display)
                        ?.map((val, ind) => {
                          return (
                            <div key={ind} class="row mt-">
                              <div class="col-md-12">
                                <div
                                  class="text-start white-text-dark-mode f-16 font-w-500"
                                  style={{
                                    color:
                                      "themeMode.theme === 'DARK' ? 'white' : 'black',",
                                  }}
                                >
                                  {val?.field_label}
                                </div>
                                {val?.field_name === "did_blt_cover_obs" ? (
                                  <>
                                    <BltChart
                                      barchartseries={processDidBlt(
                                        val?.field_name
                                      )}
                                      barchartcat={[]}
                                      toolData={val?.field_label}
                                      color={"#458FFF"}
                                      isLoader={isLoader}
                                    />
                                  </>
                                ) : (
                                  <BarChart
                                    barchartseries={processChartData(
                                      val?.field_name
                                    )}
                                    barchartcat={[]}
                                    toolData={val?.field_label}
                                    color={"#458FFF"}
                                    isLoader={isLoader}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
        </Modal.Body>
      </Modal>

      {/* Add Modals */}

      {form_control ? (
        <DynamicModal
          isShow={addmodalshow}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          callbackfunc={callBackhide}
          contentId={selectedcontentid}
          previousShiftData={lastShiftEntry}
          getprevAndCurrentShift={getprevAndCurrentShift}
          addgridData={lastShiftGridData}
          reloaddata={addupdatereportnotecallback}
          cost_center_number={id}
          acuityNurse={multichart?.accepted_acuity_per_nurse}
          formContent={multichart}
          getPreAndCurr={[]}
        />
      ) : (
        <AddModal
          isShow={addmodalshow}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          callbackfunc={callBackhide}
          contentId={selectedcontentid}
          previousShiftData={lastShiftEntry}
          getprevAndCurrentShift={getprevAndCurrentShift}
          addgridData={lastShiftGridData}
          reloaddata={addupdatereportnotecallback}
          cost_center_number={id}
          acuityNurse={multichart?.accepted_acuity_per_nurse}
          getPreAndCurr={[]}
        />
      )}

      {/* Update Modals */}

      {form_control ? (
        contentone.id !== undefined ? (
          <DynamicUpdateModal
            isShow={showupdate}
            cost_center_name={multichart.cost_center_name}
            numbers={headcount?.box3?.total_rn_variance}
            previousShiftData={lastShiftEntry}
            getprevAndCurrentShift={getprevAndCurrentShift}
            // gridData={lastShiftGridData} //old
            gridData={gridshiftdata}
            callbackfunc={() => setShowupdate(false)}
            reloaddata={addupdatereportnotecallback}
            data_id={selectedcontentid}
            cost_center_number={id}
            acuityNurse={multichart?.accepted_acuity_per_nurse}
            formContent={multichart}
            getPreAndCurr={[]}
          />
        ) : null
      ) : contentone.id !== undefined ? (
        <UpdateModal
          isShow={showupdate}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          previousShiftData={lastShiftEntry}
          getprevAndCurrentShift={getprevAndCurrentShift}
          // gridData={lastShiftGridData} //old
          gridData={gridshiftdata}
          callbackfunc={() => setShowupdate(false)}
          reloaddata={addupdatereportnotecallback}
          data_id={selectedcontentid}
          cost_center_number={id}
          acuityNurse={multichart?.accepted_acuity_per_nurse}
          getPreAndCurr={[]}
        />
      ) : null}

      <SuccessModal
        isShow={showsucess}
        callbackfunc={() => setShowsucess(false)}
        title="Success"
        content={sucessmsg}
      />
    </div>
  );
}

export default CurrentPrevModal;