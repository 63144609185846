import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

const middlewares = [thunk];
const preloadedState = {};
export const store = createStore(
  reducers,
  preloadedState,
  applyMiddleware(...middlewares)
);
