import React, { useEffect, useContext } from "react";
import ContentLoader from "react-content-loader";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import {
    setUpdateDate,
    setUpdateIndex,
    setUpdateStack,
} from "../../store/actions/Compensate";
import {
    formatNumberWithFraction,
} from "../../utils/helper";
import { ThemeContext } from "../../providers/ThemeProvider";

const GraphLoader = ({ themeMode }) => (
    <ContentLoader
        viewBox="0 0 280 100"
        backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
        foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
        {/* Only SVG shapes */}
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="100" />
    </ContentLoader>
);

const StackedBarChart = ({ data, graphClick }) => {
    const dispatch = useDispatch();
    const [themeMode, setTheme] = useContext(ThemeContext);
    const apiData = data?.graphData?.[0];

    const formatNumber = (value) => {
        if (value >= 1000) {
            const formattedValue = (value / 1000).toFixed(1);
            return `${formattedValue}k`;
        }
        return value.toString();
    };

    function convertToWholeNumberIfDecimal01(number) {
        const numberStr = number.toString();
        if (numberStr.endsWith(".01")) {
            const wholeNumber = Math.floor(number);
            return wholeNumber.toFixed(2);
        }
        return formatNumberWithFraction(number, 2);
    }

      const formatYaxisfun = (num) => {
    if(num <= 0.09){
	    return formatNumberWithFraction(num, 3)
      }else if(num > 0.09 && num <= 0.9){
        return formatNumberWithFraction(num, 2);
      }else{
       return formatNumberWithFraction(num, 1);
      }
  };

    // if (!data || !Array.isArray(data) || data.length === 0) {
    //   return <GraphLoader themeMode={themeMode} />;
    // }

    const series =

        apiData?.data?.map((product) => ({
            name: product?.name,
            data: product?.data
        })) || [];

    const categories = apiData?.graphDisplayDateWithDay[0] || [];
    const categoriesTwo = apiData?.dates || [];
    const yMax = apiData?.yMax;


    const handleClick = (event, chartContext, config) => {
        // Handle the click event here
        // console.log(chartContext);

        // const clickedDataPoint = data[config.dataPointIndex];
        if (config.dataPointIndex !== undefined) {
            // Calculate which bar was clicked based on the cursor position
            if (graphClick) {
                const clickedDataPoint = categoriesTwo[config?.dataPointIndex];
                if (clickedDataPoint) {
                    const clickedDate = clickedDataPoint;
                    dispatch(setUpdateStack(clickedDate));
                    graphClick(clickedDate);
                }
            }
        }
    };

    // // Define chart options
    // const options = {
    //     chart: {
    //         type: "bar",
    //         stacked: true,
    //         toolbar: {
    //             show: false,
    //         },
    //         events: {
    //             dataPointSelection: handleClick,
    //         },
    //         zoom: {
    //             enabled: true,
    //         },
    //     },
    //     colors: ["#e80208", "#f6c140", "#6fb0e0"],
    //     responsive: [
    //         {
    //             breakpoint: 480,
    //             options: {
    //                 legend: {
    //                     position: "top",
    //                     offsetX: -10,
    //                     offsetY: 0,
    //                 },
    //             },
    //         },
    //     ],
    //     plotOptions: {
    //         bar: {
    //             horizontal: false,
    //             borderRadius: 0,
    //             dataLabels: {
    //                 total: {
    //                     enabled: true,
    //                     formatter: function (val, opts) {
    //                         const total = opts.w.config.series.reduce((sum, series) => {
    //                             return Number(sum) + Number(series.data[opts.dataPointIndex]);
    //                         }, 0);
    //                         if (total % 1 > 0.1) {
    //                             return formatNumberWithFraction(total);
    //                         } else {
    //                             return formatNumberWithFraction(total, 0);
    //                         }
    //                     },
    //                     style: {
    //                         fontSize: "14px",
    //                         color: themeMode.theme === "DARK" ? "white" : "black",
    //                     },
    //                 },
    //             },
    //         },
    //     },

    //     xaxis: {
    //         categories: categories.map((dataPoint) => dataPoint),
    //         labels: {
    //             formatter: function (val) {
    //                 // Check if the value is a number before formatting
    //                 return !isNaN(val) ? parseFloat(val).toFixed(1) : val;
    //             },
    //             style: {
    //                 fontSize: "12px", // Reduce the font size as needed
    //                 colors: themeMode.theme === "DARK" ? "white" : "black",
    //             },
    //             rotate: -25,
    //             rotateAlways: true,
    //             hideOverlappingLabels: false,
    //             showDuplicates: true,
    //             offsetY: 0,
    //             maxHeight: 80,
    //             show: true,
    //         },
    //     },
    //     yaxis: {
    //         labels: {
    //             formatter: function (val) {
    //                 // Format y-axis values to 1 decimal place
    //                 return formatNumberWithFraction(val, 0);
    //             },
    //             style: {
    //                 fontSize: "12px", // Reduce the font size as needed
    //                 colors: themeMode.theme === "DARK" ? "white" : "black",
    //             },
    //         },
    //     },
    //     legend: {
    //         show: false,
    //         position: "top",
    //         offsetY: 0,
    //         fontSize: "14px",
    //         horizontalAlign: "right",
    //         textTransformation: "capitalize",
    //         itemMargin: {
    //             horizontal: 90,
    //             vertical: 0,
    //         },
    //         labels: {
    //             useSeriesColors: true,
    //             style: {
    //                 textTransformation: "capitalize", 
    //                 textAlign: "center",
    //                 fontSize: "4px",
    //             },
    //         },
    //     },
    //     fill: {
    //         opacity: 1,
    //     },
    //     title: {
    //         show: false,
    //         //  text: "Exceptions By Date",
    //         floating: true,

    //         align: "center",
    //         margin: 55,
    //         offsetX: 0,
    //         offsetY: -12,
    //         style: {
    //             color: "#4B4D4D",
    //             fontSize: "16px",
    //             fontWeight: "500",
    //             fontFamily: "F37 Moon",
    //         },
    //     },
    //     grid: {
    //         show: true,
    //     },
    //     tooltip: {
    //         enabled: true,
    //         style: {
    //             fontSize: "12px",
    //             color: "#000",
    //         },

    //         x: {
    //             show: false,
    //         },
    //         y: {
    //             formatter: function (val) {
    //                 // return val + "%"
    //                 return val?.toFixed(1);
    //             },
    //         },
    //         annotations: {
    //             points: "dsdsd",
    //         },
    //     },
    // };

    const options = {
      chart: {
        type: "bar",
        // stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: handleClick,
        },
        zoom: {
          enabled: true,
        },
      },
      colors:
        themeMode.theme === "DARK"
          ? ["#1c87cf", "#ffffff"]
          : ["#1c87cf", "#000000"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          columnWidth: "90%",
          barHeight: "90%",
          horizontal: true,
          dataLabels: {
            position: "top", // top, center, bottom
            //  orientation: 'vertical',
          },
        },
      },
      dataLabels: {
        enabled: false,
        hideOverflowingLabels: true,

        formatter: function (val) {
          return formatNumber(val);
          // return val + "%";
          // return val === 0 ? "" : formatNumberWithFraction(val, 0);
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
          padding: {
            top: 30,
          },
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [categories?.date, categories?.shortDay],
        labels: {
          formatter: function (val) {
            // Check if the value is a number before formatting
            return formatYaxisfun(val);
          },
          style: {
            fontSize: "12px", // Reduce the font size as needed
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
          // rotate: -25,
          // rotateAlways: true,
          // hideOverlappingLabels: false,
          // showDuplicates: true,
          offsetY: 0,
          maxHeight: 80,
          show: true,
        },
      },
      yaxis: {
        max: yMax === 0 ? null : yMax,
        tickAmount: 5,
        labels: {
          formatter: function (val) {
            // Format y-axis values to 1 decimal place
            return formatNumberWithFraction(val, 0);
          },
          style: {
            fontSize: "12px", // Reduce the font size as needed
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
        },
      },
      legend: {
        show: false,
        position: "top",
        offsetY: 0,
        fontSize: "14px",
        horizontalAlign: "right",
        textTransformation: "capitalize",
        // width: "750",
        // markers: {
        //   width: 12,
        //   height: 12,
        //   strokeWidth: 0,
        //   strokeColor: "#fff",
        //   fillColors: undefined,
        //   radius: 12,
        //   customHTML: undefined,
        //   onClick: undefined,
        //   offsetX: 0,
        //   offsetY: 0,

        // },
        // itemMargin: {
        //   horizontal: 2,
        //   vertical: 0,
        // },
        itemMargin: {
          horizontal: 90,
          vertical: 0,
          //  with: 200
        },
        labels: {
          useSeriesColors: true, // To use the same color as the series
          style: {
            textTransformation: "capitalize", // Capitalize the first letter
            //display: "inline-block", // Display the label inline with the marker
            textAlign: "center", // Center-align the text
            fontSize: "4px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        show: false,
        //  text: "Exceptions By Date",
        floating: true,

        align: "center",
        margin: 55,
        offsetX: 0,
        offsetY: -12,
        style: {
          color: "#4B4D4D",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "F37 Moon",
        },
      },
      grid: {
        show: true, // Remove both x-axis and y-axis grid lines
        borderColor: themeMode.theme === "DARK" ? "#454545" : "#d3d3d3",
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "12px",
          color: "#000",
        },

        x: {
          show: false,
        },
        y: {
          formatter: function (val) {
            return formatNumberWithFraction(val, 2);
          },
        },
        annotations: {
          points: "dsdsd",
        },
      },
    };
    return (
        <div id="chart" className="height-80v">
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                // height="100%"
            height={200}
            />
        </div>
    );
};

export default StackedBarChart;
