import { useEffect, useState, useContext } from "react";
import ContentLoader from "react-content-loader";
import EditIcon from "../../assets/img/edit.png";
import AddIcon from "../../assets/img/add.png";

import EditIcon1 from "../../assets/img/edit1.png";
import AddIcon1 from "../../assets/img/add1.png";
import LightAddIcon from "../../assets/img/light-add.png";
import DeleteIcon from "../../assets/img/trash.png";


import { useSelector, useDispatch } from "react-redux";
import { getPreviousShiftData } from "../../services";
import moment from "moment";
import { ThemeContext } from "../../providers/ThemeProvider";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import 'react-toastify/dist/ReactToastify.css';
import SuccessModal from "../../components/shared/SuccessModal";
import { formatDecimalNumber } from "../../utils/utils";
import {
  decryptedFunction,
  dataEnCrypt,
  formatNumberWithFraction,
} from "../../utils/helper";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const LinkLoader = ({ themeMode }) => (
  <div
    style={{
      border: "0px solid #eee",
      width: "100%",
      borderRadius: 0,
      marginTop: 0,
      padding: 0,
    }}
  >
    <ContentLoader viewBox="0 0 280 145.6"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="30" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="60" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="90" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="120" rx="5" ry="5" width="280" height="25" />
    </ContentLoader>
  </div>
);

function DataTable({
  data,
  callbackfunc,
  directId,
  isLoader,
  realtimeshift,
  cost_center_number,
  realtimefunc,
  reloaddata,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [showupdate, setShowupdate] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [activeshift, setActiveshift] = useState("");
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const [showsucess, setShowsuccess] = useState(false);
  const [sucessmsg, setSucessmsg] = useState("");
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );


  useEffect(() => setId(directId.id), [directId]);
  useEffect(() => setActiveshift(realtimeshift), [realtimeshift]);

  const rowClickHandler = (obj, type) => {
    getPreviousShiftData(
      dispatch,
      cost_center_number,
      obj.shift_date,
      obj.shift_time
    );
    if (obj.add_edit_button == 1 || obj.add_edit_button == 2) {
      setActiveshift(obj.shift_time);
      setId(obj.id);
      callbackfunc(obj, type);
    }
  };

  const deleteShift = async (item) => {
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to delete this Interval?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ff0000",
      // cancelButtonColor: "#DD6B55"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const paramData = { _id: item };
          const encryptData = dataEnCrypt(paramData);
          const { data } = await axios.post(
            `${API_CALL_URL_PRIVATE}/department/deleteShiftData`,
            { payloadData: encryptData }
          );

          setShowsuccess(true);
          setSucessmsg("Interval Deleted Successfully");
          setTimeout(() => {
            setShowsuccess(false);
          }, 2000);

          if (reloaddata) {
            reloaddata();
          } else {
            realtimefunc(cost_center_number, null);
          }
        } catch (error) {
          toast.error(`${error?.response?.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(error);
        }
      }
    });
  };

  return (
    <div className="white-bg shadow-box h-100">
      <div class="box-heading text-center">Interval Activity</div>
      <div class="overlfow-auto h-607">
        <table class="table activity-tabel mb-0  spacing-table tb1 table-hover new-table-design ">
          <thead>
            <tr>
              <th class="coll text-center width-100p">Interval</th>

              <th class="coll text-center">Submitted</th>
              <th class="coll text-center">
                {costCenterData?.interval === 24 ? "UOS" : "Census"}
              </th>
              <th class="coll text-center">RN Grid Variance</th>
              <th class="coll text-center">Total Grid Var</th>
              {costCenterData?.interval !== 24 ?
              <th class="coll text-center">RN : PT</th> :
               <th class="coll text-center"></th>
              }
              <th class="coll text-center">Comments</th>
            </tr>
          </thead>
          <tbody>
            {!isLoader ? (
              data.length > 0 ? (
                data.map((item, index) => {
                  if (item?.status === "entry_upcoming") {
                    return (
                      <tr>
                        <td
                          key={index}
                          onClick={() => rowClickHandler(item, 2)}
                          className="p-0 noborder  br-8"
                          colSpan={7}
                        >
                          <button
                            className={
                              item?.add_edit_button == 1 &&
                              costCenterData?.interval !== 24
                                ? "btn button-shadow transparent-btn w-100 black-color position-relative"
                                : "btn button-disable transparent-btn grey-shade w-100 black-color d-flex justify-content-center position-relative"
                            }
                            disabled={
                              item?.add_edit_button == 1 &&
                              costCenterData?.interval !== 24
                                ? false
                                : true
                            }
                          >
                            <span
                              className={`position-absolute ${
                                costCenterData?.interval === 24
                                  ? "pos-w"
                                  : "pos-s"
                              }`}
                            >
                              {moment(item?.shift_date).format("MM/DD")} <br />
                              {costCenterData?.interval !== 24 &&
                                item?.shift_time}
                            </span>
                            <span>
                              {item?.comment}{" "}
                              {costCenterData?.interval === 24 && (
                                <span>
                                  <img
                                    src={
                                      themeMode.theme === "DARK"
                                        ? AddIcon1
                                        : AddIcon
                                    }
                                    alt=""
                                    className="align-bottom"
                                  />
                                </span>
                              )}
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  } else if (item?.status == "entry_due") {
                    return (
                      <tr key={index} onClick={() => rowClickHandler(item, 2)}>
                        <td
                          className="p-0 noborder text-center br-8"
                          colSpan={7}
                        >
                          <button className="btn button-shadow transparent-btn w-100 black-color d-flex justify-content-center position-relative">
                            <span
                              className={`position-absolute ${
                                costCenterData?.interval === 24
                                  ? "pos-w"
                                  : "pos-s"
                              }`}
                            >
                              {moment(item?.shift_date).format("MM/DD")} <br />
                              {costCenterData?.interval !== 24 &&
                                item?.shift_time}
                            </span>
                            <span>{item?.comment}</span>
                          </button>
                        </td>
                      </tr>
                    );
                  } else if (item?.status == "entry_missing") {
                    return (
                      <tr key={index} onClick={() => rowClickHandler(item, 2)}>
                        <td
                          className="p-0 noborder text-center  br-8"
                          colSpan={7}
                        >
                          <button className="btn button-shadow transparent-btn w-100 black-color d-flex justify-content-center position-relative">
                            <span
                              className={`position-absolute ${
                                costCenterData?.interval === 24
                                  ? "pos-w"
                                  : "pos-s"
                              }`}
                            >
                              {moment(item?.shift_date).format("MM/DD")} <br />
                              {costCenterData?.interval !== 24 &&
                                item?.shift_time}
                            </span>
                            <span>
                              {item?.comment}{" "}
                              <span>
                                {/* <img
                                  src={AddIcon}
                                  alt=""
                                  className="align-bottom"
                                /> */}
                                <img
                                  src={
                                    themeMode.theme === "DARK"
                                      ? AddIcon1
                                      : AddIcon
                                  }
                                  alt=""
                                  className="align-bottom"
                                />
                              </span>
                            </span>
                          </button>
                        </td>

                        {/* <td>
                                                        {moment(item.shift_date).format("MM/DD")}<br />{item.shift}
                                                    </td>
                                                    <td className='text-center'><span class="f-12 v-m">{item.entered_by}</span></td>
                                                    <td class="text-center">{item.rn_to_pt_ratio}</td>
                                                    <td class="f-row minheight60">
                                                        <div className='flex-grow-1'>
                                                            {item.comment}
                                                        </div>
                                                        <div>
                                                            <span><img src={AddIcon} alt="" /></span>
                                                        </div>
                                                    </td> */}
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index} onClick={() => rowClickHandler(item, 1)}>
                        <td className="text-center width-100p">
                          <div className="">
                            {" "}
                            {moment(item?.shift_date).format("MM/DD")}
                            <br />
                            {costCenterData?.interval !== 24 &&
                              item?.shift_time}
                          </div>
                        </td>

                        <td className="text-center">
                          <span class="f-12 v-m">{item?.entered_by}</span>
                        </td>
                        <td className="text-center">
                          {costCenterData?.interval === 24
                            ? formatNumberWithFraction(item?.census, 1)
                            : formatDecimalNumber(item?.census)}
                        </td>
                        <td className="text-center">
                          {formatDecimalNumber(item?.rn_variance)}
                        </td>
                        <td className="text-center">
                          {formatDecimalNumber(item?.grid_variance)}
                        </td>
                        {costCenterData?.interval !== 24 ? (
                          <td class="text-center">{item?.rn_to_pt_ratio}</td>
                        ) : (
                          <td class="text-center"></td>
                        )}
                        <td class="f-row minheight60 text-center">
                          <div className="flex-grow-1 text-center">
                            {item?.comment}
                          </div>
                          <div className="d-flex">
                            <span
                              class="mb-2"
                              style={{ marginRight: "0.5rem" }} //remove this style
                            >
                              {/* <img src={EditIcon} alt="" className="mt-2" /> */}
                              <img
                                src={
                                  themeMode.theme === "DARK"
                                    ? EditIcon1
                                    : EditIcon
                                }
                                alt=""
                                className="mt-2"
                              />
                            </span>
                            {userData?.data?.user_type === 0 && (
                              <span class="mb-2" style={{ cursor: "pointer" }}>
                                <img
                                  src={
                                    themeMode.theme === "DARK"
                                      ? DeleteIcon
                                      : DeleteIcon
                                  }
                                  alt=""
                                  className="mt-2"
                                  width={19}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteShift(item?.id);
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })
              ) : null
            ) : (
              <LinkLoader themeMode={themeMode} />
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
      <SuccessModal
        isShow={showsucess}
        // callbackfunc={callBacksuccess}
        title="Success"
        content={sucessmsg}
      />
    </div>
  );
}

export default DataTable;