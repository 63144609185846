import "./index.css";
import { useEffect, useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PageLayout from "../../../layouts/PageLayout";
import { get } from "lodash";
import ContentLoader from "react-content-loader";


const BoxLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="195" />
  </ContentLoader>
);

function Applications(props) {
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const [dashboardData, setDashboardData] = useState([]);

  const boxdata = props.data;
  // const isLoader = props.isLoader;
  const { issearch } = props;
  const [metaData, setMetaData] = useState([]);
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [isLoader, setIsLoader] = useState(false);



  return (
    <PageLayout>
      <div className="d-flex align-items-center justify-content-center" style={{marginTop: "30vh"}}>
            <h2
              className="fw-normal color-black"
              
            >
              You are accessing an invalid cost center.
            </h2>
          </div>
      {/* </div> */}
    </PageLayout>
  );
}

export default Applications;
