import "./index.css";
 import  React, { useEffect, useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import PageLayout from "../../layouts/PageLayout";
import ProfilePic from "../../assets/img/profile.png";
import Coming from "../../assets/img/comingsoon.png";

import { ThemeContext } from "../../providers/ThemeProvider";
import { THEME_TYPE } from "../../constants/theme";



function ComingSoon() {
  const [themeMode, setTheme] = useContext(ThemeContext);

 

  return (
    <PageLayout>
      <Row className="gx-2 mt-0 align-items-center">
        <Col md={{ span: 12, offset: 0 }}>
          <div className="bg-white p-3 min-height-profile">
            {/* <h4 className="color-black m-0">Coming Soon</h4> */}

            
<div className="p-5 center-img-view"><img src={Coming}/></div>
            </div>
            </Col>
            </Row>
    </PageLayout>
  );  
}

export default ComingSoon;
