import "./index.css";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getdataService } from "../../services";
import { useParams, useNavigate, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PageLayout from "../../layouts/PageLayout";
import BarChart from "../../components/shared/BarChart";
import CurrentPrevModal from "../../components/CurrentPrevModal";
import { DoughnutChart } from "../../components/shared/DoughnutChart";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import ContentLoader from "react-content-loader";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import { ChartExample } from "../../components/shared/PieChart/m-pei-chartSmall";
import { ThemeContext } from "../../providers/ThemeProvider";
import BltAverageChart from "../../components/shared/BltAverageChart";
import {
  decryptedFunction,
  dataEnCrypt,
  formatNumberWithFraction,
} from "../../utils/helper";
import {
  setPeriodType,
  setRealtimePayPeriod,
} from "../../store/actions/Compensate";

const MyLoader = ({ themeMode }) => (
  <div style={{ display: "table-caption" }}>
    <ContentLoader
      viewBox="0 0 100 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="4.7" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="9.4" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="14.1" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="18.8" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="23.5" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="28.2" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="32.9" rx="0" ry="0" width="100" height="4.5" />
    </ContentLoader>
  </div>
);

const ChartLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader
      viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

function Currentperiod({ id, urlparam, urltype }) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const realtimePayPeriod = useSelector(
    (state) => state.compensate.realtimePayPeriod
  );
  const filterDateList = useSelector(
    (state) => state.compensate.filterDateList
  );
  const detail_data = selector.compensate.departmentdetail;
  const [apidata, setApidata] = useState([]);
  const [period, setPeriod] = useState("currentperiod");
  const [show, setShow] = useState(false);
  const [totalvalue, setTotalvalue] = useState({});
  const [headerdata, setHeaderdata] = useState({});
  const [activedate, setActivedate] = useState("");
  const [radiaBarChart, setRadiaBarChart] = useState([]);
  const [chartName, setChartName] = useState("");
  const [colorData, setColorData] = useState("");
  const [payperiodData, setPayperiodData] = useState([]);
  const [currentPrevDate, setCurrentPrevDate] = useState([]);
  const [dougnutData, setDoughnutData] = useState([]);
  const [formControlData, setFormControltData] = useState({});
  const [formShiftData, setFormShiftData] = useState([]);
  const [workDriverData, setWorkDriverData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );
  const multichart = detail_data?.left_sidebar
    ? detail_data.left_sidebar.sidebar_1
    : {};

  useEffect(() => {
    fetchDetails();
    getBarData();
  }, [id, urltype, realtimePayPeriod]);

  const outerringval = (e) => {
    if (e >= 100) {
      return "#48b461";
    } else if (e < 100 && e >= 98) {
      return "#ffff26";
    } else {
      return "#ff2626";
    }
  };

  async function fetchDetails() {
    if (id) {
      setIsLoader(true);
      const params = {
        cost_center_number: id,
        pay_period_type: urltype === "currentperiod" ? "current" : "previous",
        payperiod_end_date: realtimePayPeriod
      };

      const encryptData = dataEnCrypt(params);
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getPayPeriodData",
        { payloadData: encryptData }
      );
      const data = decryptedFunction(resp?.data?.responseData);

      const sum = data.data?.contentData.reduce(function (
        previousValue,
        currentValue
      ) {
        return {
          uos_total:
            (parseFloat(previousValue.uos_total?.toFixed(1)) || 0) +
            (parseFloat(currentValue.uos_total?.toFixed(1)) || 0),
          worked_hours:
            (parseFloat(previousValue.worked_hours?.toFixed(1)) || 0) +
            (parseFloat(currentValue.worked_hours?.toFixed(1)) || 0),
          target_hours:
            (parseFloat(previousValue.target_hours?.toFixed(1)) || 0) +
            (parseFloat(currentValue.target_hours?.toFixed(1)) || 0),
          hpuos: previousValue.hpuos + currentValue.hpuos,
          hours_variance:
            (parseFloat(previousValue.hours_variance?.toFixed(1)) || 0) +
            (parseFloat(currentValue.hours_variance?.toFixed(1)) || 0),
          rn_to_pt: previousValue.rn_to_pt + currentValue.rn_to_pt,
        };
      });
      setTotalvalue(sum);
      setCurrentPrevDate(data?.data?.prevCurrentPeriodDates);

      setHeaderdata(data.data?.header);
      setRadiaBarChart([
        data.data?.leftSideBar[0]?.compliance,
        data.data?.leftSideBar[0]?.productivity,
      ]);
      setDoughnutData(data.data?.leftSideBar[0]);
      setColorData(outerringval(data.data?.leftSideBar[0]?.compliance));
      setChartName(data.data?.leftSideBar[0]?.cost_center_name);
      setApidata(data.data?.contentData);
      setPayperiodData(data?.data);
      setIsLoader(false);
    }
  }
       
  const currentprevhandlerclick = async (date) => {
    const datearr = date.split("/");
    const filterdate = `${datearr[1]}-${datearr[0]}-${datearr[2]}`;
    const params = {
      cost_center_number: id,
      fromdate: {
        date: filterdate,
        shift: "03:00",
      },
      todate: {
        date: filterdate,
        shift: "23:00",
      },
    };
    const resp = getdataService(dispatch, params);
    //let { data } = await resp;
    setShow(true);
    setActivedate(date);
    //console.log(data);
  };

  const getGraphControl = async () => {
    try {
      if (id != null) {
        const paramData = {
          cost_center_number: id,
        };
        const encryptData = dataEnCrypt(paramData);
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/general/getGraphControl",
          { payloadData: encryptData }
        );

        const data = decryptedFunction(resp?.data?.responseData);

        setFormShiftData(data?.data?.graphControl);

        const convertData = async (dataArray) => {
          return dataArray?.reduce((acc, item) => {
            acc[item?.field_name] = {
              display: item?.display,
              required: item?.required,
              field_label: item?.field_label,
            };
            return acc;
          }, {});
        };

        const convertedFormData = await convertData(data?.data?.graphControl);
        return convertedFormData;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBarData = async () => {
    const getGraph = await getGraphControl();
    setFormControltData(getGraph);
    try {
      const paramData = {
        cost_center_number: id,
        pay_period_type: urltype === "currentperiod" ? "current" : "previous",
      };
      const encryptData = dataEnCrypt(paramData);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/getCPpayperiodGraphData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setWorkDriverData(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const processChartData = (dataKey) => {
    const linechartseries = workDriverData?.map((item) => ({
      shift_time: item?.shift_date_md,
      value: +item[dataKey],
    }));
    const allZeros = linechartseries.every((obj) => +obj.value === 0);
    return allZeros
      ? linechartseries.map((obj) => ({ ...obj, value: undefined }))
      : linechartseries;
  };

  const BltAverageChartData = (dataKey) => {
    const bltShiftTime = workDriverData?.map((item) => item?.shift_date_md);
    const bltYes = workDriverData?.map(
      (item) => item.did_blt_cover_obs_for_yes
    );
    const bltNo = workDriverData?.map((item) => item.did_blt_cover_obs_for_no);
    return { bltShiftTime, bltYes, bltNo };
  };

  return (
    <PageLayout
      data={headerdata}
      issearch={false}
      isshow={true}
      isLoader={isLoader}
      callbackfunc={""}
      isCallback={false}
      cost_center_number={null}
      filterdate={""}
    >
      <div className="row mt-2 mb-2 gx-2 current-previous-period-page">
        <div className="col-md-12">
          <div className="row gx-2 h-100">
            <div className="col-md-3  width-22">
              <div className="white-bg shadow-box h-100 11">
                <div
                  className="box-heading text-center"
                  style={{ marginBottom: "-10px" }}
                >
                  Completion and Productivity Status
                  <div class=" text-center pt-2 pb-2">
                    <strong class="m-f14 primary-font color-black">
                      {chartName}
                    </strong>
                  </div>
                </div>
                {!isLoader ? (
                  <DoughnutChart data={dougnutData} />
                ) : (
                  <ChartLoader themeMode={themeMode} />
                )}

                <div className="d-flex text-center flex-wrap">
                  <Link
                    className={
                      "flex-grow-1 me-1 border-btn f-14 cursor-pointer green-b w-100"
                    }
                    to={`/realtime/department-details/${urlparam}/realtime`}
                  >
                    RealTime
                  </Link>
                  <Link
                    className={`flex-grow-1 me-1 border-btn f-14 cursor-pointer green-b ${
                      urltype == "currentperiod" ? "active" : ""
                    }`}
                    to={`/realtime/department-details/${urlparam}/currentperiod`}
                    onClick={() => {
                      dispatch(setPeriodType(filterDateList?.[0]));
                      dispatch(
                        setRealtimePayPeriod(filterDateList?.[0]?.value)
                      );
                    }}
                  >
                    Current Period
                  </Link>
                  <Link
                    className={`flex-grow-1 border-btn f-14 cursor-pointer green-b ${
                      urltype == "previousperiods" ? "active" : ""
                    }`}
                    to={`/realtime/department-details/${urlparam}/previousperiods`}
                    onClick={() => {
                      dispatch(setPeriodType(filterDateList?.[1]));
                      dispatch(
                        setRealtimePayPeriod(filterDateList?.[1]?.value)
                      );
                    }}
                  >
                    Previous Periods
                  </Link>
                </div>
                <div className="recordshowing text-center mt-3">
                  {payperiodData.payPeriodStartDate
                    ? payperiodData?.payPeriodStartDate +
                      " - " +
                      payperiodData?.payPeriodEndDate
                    : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6 width-53">
              <div className="white-bg shadow-box h-100">
                <div className="box-heading text-center">Biweekly Summary</div>
                <div className="overlfow-auto h-607">
                  <table className="borderd-scrollabel-table table  f-16 mb-0 mt-2 table table-bordered tb2 table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">Date</th>
                        <th className="text-center"></th>
                        <th className="text-center">UOS</th>
                        {costCenterData?.interval !== 24 && (
                          <th className="text-center">AVG RN : PT</th>
                        )}
                        <th className="text-center">Worked Hrs</th>
                        <th className="text-center">Target Hrs</th>
                        <th className="text-center">HPUOS</th>
                        <th className="text-center">Hrs Variance</th>
                      </tr>
                    </thead>
                    {!isLoader ? (
                      <tbody>
                        {apidata.length > 0
                          ? apidata.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  data-bs-toggle="modal"
                                  data-bs-target="#depModal"
                                  onClick={() =>
                                    currentprevhandlerclick(item?.date)
                                  }
                                >
                                  <td className="text-center pointer">
                                    {moment(item?.date).format("MM/DD")}
                                  </td>
                                  <td
                                    className="text-center pointer"
                                    style={{
                                      width: "65px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "65px",
                                        marginTop: -13,
                                        marginBottom: -13,
                                      }}
                                    >
                                      <ChartExample
                                        data={item}
                                        isdetail={true}
                                        directId={id}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-center pointer">
                                    {(costCenterData?.cost_center_category ===
                                      6 ||
                                      costCenterData?.interval === 24) &&
                                    item?.uos_total === 0
                                      ? " - "
                                      : costCenterData?.cost_center_category ===
                                        6
                                      ? formatNumberWithFraction(
                                          item?.uos_total,
                                          0
                                        )
                                      : formatNumberWithFraction(
                                          item?.uos_total,
                                          1
                                        )}
                                  </td>
                                  {costCenterData?.interval !== 24 && (
                                    <td className="text-center pointer">
                                      1 : {item?.rn_to_pt?.toFixed(1)}
                                    </td>
                                  )}
                                  <td className="text-center pointer">
                                    {(costCenterData?.cost_center_category ===
                                      6 ||
                                      costCenterData?.interval === 24) &&
                                    item?.uos_total === 0
                                      ? " - "
                                      : formatNumberWithFraction(
                                          item?.worked_hours,
                                          1
                                        )}
                                  </td>
                                  <td className="text-center pointer">
                                    {(costCenterData?.cost_center_category ===
                                      6 ||
                                      costCenterData?.interval === 24) &&
                                    item?.uos_total === 0
                                      ? " - "
                                      : formatNumberWithFraction(
                                          item?.target_hours,
                                          1
                                        )}
                                  </td>
                                  <td className="text-center pointer">
                                    {((costCenterData?.cost_center_category ===
                                      6 ||
                                      costCenterData?.interval === 24) &&
                                      item?.uos_total === 0) ||
                                    (costCenterData?.cost_center_category ===
                                      1 &&
                                      item?.isRealTime)
                                      ? " - "
                                      : formatNumberWithFraction(
                                          item?.hpuos,
                                          3
                                        )}
                                  </td>
                                  <td className="text-center pointer">
                                    {(costCenterData?.cost_center_category ===
                                      6 ||
                                      costCenterData?.interval === 24) &&
                                    item?.uos_total === 0
                                      ? " - "
                                      : formatNumberWithFraction(
                                          item?.hours_variance,
                                          1
                                        )}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                        <tr
                          data-bs-toggle="modal"
                          data-bs-target="#depModal"
                          className="bold-td"
                        >
                          <td className="text-center">Total</td>
                          <td className="text-center"></td>
                          <td className="text-center">
                            {costCenterData?.cost_center_category === 6
                              ? formatNumberWithFraction(
                                  payperiodData?.tableFooter?.uos,
                                  0
                                )
                              : new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }).format(payperiodData?.tableFooter?.uos)}
                          </td>
                          {costCenterData?.interval !== 24 && (
                            <td className="text-center">
                              1 :{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(
                                payperiodData?.tableFooter?.rn_pt_ratio_for_pp
                              )}
                            </td>
                          )}
                          <td className="text-center">
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(payperiodData?.tableFooter?.worked_hours)}
                          </td>
                          <td className="text-center">
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(payperiodData?.tableFooter?.target_hours)}
                          </td>
                          <td className="text-center">
                            {totalvalue?.uos_total === 0
                              ? "0.000"
                              : new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                }).format(payperiodData?.tableFooter?.hpuos)}
                          </td>
                          <td className="text-center">
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(
                              payperiodData?.tableFooter?.hours_variance
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <MyLoader themeMode={themeMode} />
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-3 plr-4 width-22">
              <div class="white-bg shadow-box pb-0 h-25p h-100">
                <div class="box-heading text-center mb-2">
                  {costCenterData?.interval === 24
                    ? "Workdrivers"
                    : "Workdrivers"}
                </div>

                {formShiftData
                  ?.sort((a, b) => a.display - b.display)
                  ?.map((val, ind) => {
                    return (
                      <div key={ind} class="row mt-">
                        <div class="col-md-12">
                          <div
                            class="text-start white-text-dark-mode f-13 font-w-500"
                            style={{
                              color:
                                "themeMode.theme === 'DARK' ? 'white' : 'black',",
                            }}
                          >
                            {val?.field_label}
                          </div>
                          {val?.field_name === "did_blt_cover_obs" ? (
                            <>
                              <div className="bxh d-flex justify-content-center">
                                <div className="bluexyt legend-text">
                                  <span className="circle"></span>Yes
                                </div>
                                <div className="redt legend-text">
                                  <span className="circle"></span>No
                                </div>
                              </div>
                              <BltAverageChart
                                barchartseries={BltAverageChartData(
                                  val?.field_name
                                )}
                                barchartcat={[]}
                                toolData={val?.field_label}
                                color={"#458FFF"}
                                isLoader={isLoader}
                                isCurrentPeriod={true}
                              />
                            </>
                          ) : (
                            <BarChart
                              barchartseries={processChartData(val?.field_name)}
                              barchartcat={[]}
                              toolData={val?.field_label}
                              color={"#458FFF"}
                              isLoader={isLoader}
                              isCurrentPeriod={true}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CurrentPrevModal
        isShow={show}
        callbackfunc={() => setShow(false)}
        id={id}
        isLoader={isLoader}
        activedate={activedate}
        allDate={currentPrevDate}
        reloaddata={fetchDetails}
        reloadGraph={getBarData}
        graphControl={formControlData}
        formShiftData={formShiftData}
        isCostcenter={true}
      />
    </PageLayout>
  );
}

export default Currentperiod;
