import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
const secretKey = "zxdfgfhrhrshrgtratgrsgrgtztryrty67x";

export const capitalizeAllLetters = (inputString) => inputString?.toUpperCase();

export const formatNumberWithFraction = (value, fractionDigits = 1) =>
  value?.toLocaleString("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatNumberRoundOff = (value, fractionDigits = 1) => {
  if (value < 100) {
    // Return the original value without rounding
    return value.toFixed(fractionDigits);
  }

  const roundedValue = Math.ceil(value / 1000) * 1000; // Round to the nearest thousand
  const formattedValue = roundedValue.toLocaleString("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return formattedValue;
};

export const formatDashDate = (shift_date) => {
  const changedate = shift_date.split("/");
  const date = `${changedate[1]}-${changedate[0]}-${changedate[2]}`;
  return date;
};


export const decryptedFunction = (dataToEncrypt) => {
  if (process.env.React_App_ENVIRONMENT == "production") {
//   if (process.env.React_App_ENVIRONMENT == "development") {
    var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
      //  var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
      //  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      //  return decryptedData;

   return dataToEncrypt;
  }
};

export const dataEnCrypt = (data) => {
  if (process.env.React_App_ENVIRONMENT == "production") {
//   if (process.env.React_App_ENVIRONMENT == "development") {
    return AES.encrypt(JSON.stringify(data), secretKey).toString();
  } else {
    // return AES.encrypt(JSON.stringify(data), secretKey).toString();

    return data;
  }
};
