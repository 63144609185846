import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import SuccessImg from '../../../assets/img/testIcon.gif';
import SuccessImgDark from '../../../assets/img/testIcon-dark.gif';

import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../providers/ThemeProvider";

export default function ErrorModal({isShow, callbackfunc, title, content}){
    const [themeMode, setTheme] = useContext(ThemeContext);
    const submitHandle = () => {
        window.location.reload();
    } 

    // const [isShowPop, setIsShowPop ] = useState(isShow);
    
    return (
        <Modal size="sm" show={isShow} onHide={() => callbackfunc(false)} centered>
            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}> <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ border: 'none', backgroundColor: 'transparent', color: 'red' }}
              onClick={() => setIsShowPop(false)}
            >
              X
            </button>
            </div> */}
            <Modal.Body className="text-center">
                <div className="mb-3">
                    {/* <img src={SuccessImg} width="200px" /> */}
                    <img src={themeMode.theme === "DARK" ? SuccessImgDark : SuccessImg} alt=""  width="200px" />
                    </div>
                {content}
            </Modal.Body>
            {/*<Modal.Footer className='justify-content-center'>
                <button onClick={submitHandle} type="button" className="btn btn-danger text-capitalize ms-0 f-18 fw-500"><i className="fa fa-check-circle" aria-hidden="true"></i> Ok</button> 
            </Modal.Footer>*/}
        </Modal>
    )
}