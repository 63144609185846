import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal1 from "../../../components/shared/BiweeklyVarianceChart/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import BiweeklyLineChart from "../../../components/shared/BiweeklyVarianceChart/index";
import BiweeklyLineChart2 from "../../../components/shared/BiweeklyVarianceChart/index2";
import DateFilter from "../../../components/shared/BiweeklyVarianceChart/dateFilter";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import LineChart from "../../../assets/img/grap-line.jpg";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import Button from "@atlaskit/button";


const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

function VarianceTrends({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);
  const [isDropdownOpenfour, setIsDropdownOpenFour] = useState(false);
  const [dropdownValueFour, setDropdownValueFour] = useState(undefined);
  const [sliderFilterData, setSliderFilterData] = useState({});
  const [activefiltertab, setActivefiltertab] = useState("undefined");
  const [filterdate, setFilterdate] = useState({});

  const [selectedOption, setSelectedOption] = useState(null);

  const getTableData = async () => {
    setIsTableLoading(true);
    const parData = {};
    parData.page = page;
    // parData.limit = 25;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data?.data?.cost_centers);

      if (data?.data?.hasNextPage) {
        getTableNextData(data?.data?.nextPage);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getTableNextData = async (pageIn) => {
    const parData = {};
    // parData.limit = 500;
    parData.page = pageIn;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setTableData((prevData) => [
        ...prevData,
        ...(data?.data?.cost_centers || []),
      ]);

      if (data?.data?.hasNextPage) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setTimeout(() => {
        getTableNextData(data?.data?.nextPage);
        // }, 1000);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const fetchDataRecursive = async (
    currentPage = page,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
    if (initialCall) {
      setIsTableLoading(true);
    }

    const parData = {
      page: currentPage,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    if (limit) {
      parData.limit = limit;
    }

    if (buisnessCategory) {
      parData.businessUnit = buisnessCategory;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.cost_centers || [];

    if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
      setTableData(costCenters);
    } else {
      setTableData((prevData) => [...prevData, ...costCenters]);
    }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const getHeadData = async () => {
    setIsLoading(true);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getheaderData`
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`
      );
      const data = decryptedFunction(response?.data?.responseData);

      setCostCenterList(
        data?.data?.costCenterResponse.map((item) => {
          return {
            label: `${item.cost_center_number} - ${capitalizeAllLetters(
              item.cost_center_name
            )}`,
            value: item.cost_center_slug,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    setClickCount(0);
    getMetaData();
    fetchDataRecursive();
    getHeadData();
    // getCostCenterList();
    getRefreshSchedule();
    getBuisnesUnit();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {

    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);


    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 105,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    //  setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      if (key !== "") {
        fetchDataRecursive(page, true, key, 1000, newCancelToken.token);
      } else {
        fetchDataRecursive(page, true, key, null, newCancelToken.token);
      }

      // const { data } = await axios.post(
      //   `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      //   parData
      // );

      // setTableData(data.data?.cost_centers);

      // if (data?.data?.hasNextPage) {
      //   getTableNextData(data?.data?.nextPage);
      // }
      // setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };


  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }

  }, [isBuisnessUnitSet]);


  useEffect(() => {
    if (mounted) {
      fetchDataRecursive();
    } else {
      setMounted(true);
    }
  }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };


      const toggleOpen = () => {
        setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenTwo = () => {
        setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenFour = () => {
        setIsDropdownOpenFour((prevIsOpen) => !prevIsOpen);
      };

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2  mt-2 mb-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 10 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              {/* <div>
                <h3 className="text-center color-black m-0">
                  Biweekly Reporting -
                </h3>
              </div> */}

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      100061102 - 4 WEST
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueTwo?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={costCenterList}
                  />
                </CustomDropdown>
              </div>
              <DateFilter
                // fromdate={filterdate.fromdate}
                // seCsvUpdateEvent={seCsvUpdateEvent}
                 setSliderFilterData={setSliderFilterData}
                // setBoxColor={setBoxColor}
                // isactivefilter={isactivefilter}
                active={activefiltertab}
                 activetab={(tab) => setActivefiltertab(tab)}
              />
               <DateFilter
                // fromdate={filterdate.fromdate}
                // seCsvUpdateEvent={seCsvUpdateEvent}
                 setSliderFilterData={setSliderFilterData}
                // setBoxColor={setBoxColor}
                // isactivefilter={isactivefilter}
                active={activefiltertab}
                 activetab={(tab) => setActivefiltertab(tab)}
              />
         
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      VP
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueTwo?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={costCenterList}
                  />
                </CustomDropdown>
              </div>
              
            </div>
          </Col>
          <Col md={1} className="text-end mobile-center mobile-my-2">
            {process.env.React_App_ENVIRONMENT === "local" &&  (
          <div className="text-center text-mobile-center bg-white rounded">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    setModalShow(true);
                  }}
                >
                  Button
                </span>
              </div>
            )}
          </Col>

          <InfoModal1 show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2 mt-3">
          <Col md={12} className="mt-2 h-610">
            <div className="bg-white p-3  text-center">
              <div class="f-16 text-center mb-minus-10 font-w-500 mb-2">
                All Cost Center : Performance Variance By Pay Period
              </div>
              {/* <img src={LineChart} alt="" style={{margin: "0 auto"}}/> */}
              <BiweeklyLineChart
                // title={"All Cost Center : Actual And Target Worked FTE Trends"}

                color={"#458FFF"}
              />
            </div>
            <div className="bg-white p-3 pt-0  text-center">
            <div class="f-16 text-center mb-minus-10 font-w-500 mb-2">
                All Cost Center : Rolling-4 Performance Variance By Pay Period
              </div>
              {/* <img src={LineChart} alt="" style={{margin: "0 auto"}}/> */}
              <BiweeklyLineChart2
                // title={"All Cost Center : Actual And Target Worked FTE Trends"}

                color={"#458FFF"}
              />
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default VarianceTrends;
