import React, { useState } from "react";
import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageLayout from "../../layouts/PageLayout";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import axios from "../../axios";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../BiWeeklyReporting/DemoApp.css"
import PsLogo from '../../assets/img/pslogo.png';
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";

const application_id = 111;

const ReportManagement = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [embedUrl, setEmbedUrl] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [metaData, setMetaData] = useState([]);

    useEffect(() => {
        getReportId();
        getMetaData();
    }, []);

    const getReportId = async () => {
      try {
        const paramData = {
          application_id: application_id,
        };
        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/embedBiReports/reportingToolId`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        await getAPiData(data?.data[0].report_id);
      } catch (error) {
        console.log(error);
      }
    };

    // console.log(`App ID: 103 and Report ID: ${reportId}`)

    const getAPiData = async (reportId2) => {
      try {
        const paramData = {
          reportId: reportId2,
          application_id: application_id,
        };
        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/embedBiReports/getEmbedToken`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        setAccessToken(data?.data?.accessToken);
        setEmbedUrl(data?.data?.embedUrl[0].embedUrl);
        setReportId(data?.data?.embedUrl[0].reportId);
      } catch (error) {
        console.log(error);
      }
    };

    const getMetaData = async () => {
        try {
             const param = {
               application_id: 111,
             };
             const encryptData = dataEnCrypt(param);
             const response = await axios.post(
               `${API_CALL_URL_PRIVATE}/metaData`,
               {
                 payloadData: encryptData,
               }
             );
             const data = decryptedFunction(response?.data?.responseData);
            setMetaData(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        document.title = metaData?.meta_title || 'Ed Analytics';
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${metaData?.favicon}` || PsLogo;
    }, [metaData]);

    return (
        <>
            <PageLayout>
                <Row className="gx-2 mt-0 align-items-center" style={{height: "100%"}}>
                    <Col
                        md={{ span: 12, offset: 0 }}
                        className=""
                    >
                        <div className="pt-3 min-height-profile kk" style={{ backgroundColor: "#3a3a3a" , minHeight: "auto"}}>
                            {/* <h4 className="color-black m-0">Coming Soon</h4> */}

                            <div className="">
                                <div>
                                    {accessToken && embedUrl && reportId ? (
                                        <PowerBIEmbed
                                            embedConfig={{
                                                type: "report", // Supported types: report, dashboard, tile, visual and qna
                                                id: reportId,
                                                embedUrl: embedUrl,
                                                accessToken: accessToken,
                                                tokenType: models.TokenType.Embed,
                                                settings: {
                                                    panes: {
                                                        filters: {
                                                            expanded: false,
                                                            visible: false,
                                                        },
                                                    },
                                                    background: models.BackgroundType.Transparent,
                                                },
                                            }}
                                            cssClassName="report-container"
                                            eventHandlers={
                                                new Map([
                                                    [
                                                        "loaded",
                                                        function () {
                                                        },
                                                    ],
                                                    [
                                                        "rendered",
                                                        function () {
                                                        },
                                                    ],
                                                    [
                                                        "error",
                                                        function (event) {
                                                        },
                                                    ],
                                                ])
                                            }
                                            // cssClassName={"Embed-container"}
                                            getEmbeddedComponent={(embeddedReport) => {
                                                window.Report = embeddedReport;
                                            }}
                                        />
                                    ) : (
                                        "Loading..."
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </PageLayout>
        </>
    );
};

export default ReportManagement;
