import "./index.css";
import React, { useEffect, useContext, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import DashboardCounter from "../../components/DashboardCounter/index";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { useParams, useNavigate, Link } from "react-router-dom";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

// import Table from "react-bootstrap/Table";
// import InfoModal from "../../components/ActiveDailyManagementComponents/shared/InfoModal";
// import FooterModal from "../../components/ActiveDailyManagementComponents/shared/footerModal";
// import ContentLoader from "react-content-loader";
// import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import ProfilePic from "../../assets/img/profile.png";
import Camera from "../../assets/img/camera.png";
import { Formik, Field, Form } from "formik";
import axios from "../../axios";


import { ThemeContext } from "../../providers/ThemeProvider";
import { THEME_TYPE } from "../../constants/theme";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { profileValidator, passwordValidator } from "../../utils/validator";
import ErrorModal from "../../components/shared/SuccessModal/ErrorModal";
import SuccessModal from "../../components/shared/SuccessModal";
import Overlay from "../../components/Overlay";
import { setUpdateUserData } from "../../store/actions/Compensate";
import { useSelector, useDispatch } from "react-redux";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from '../../assets/img/pslogo.png';
import ImgUpload from '../../assets/img/download.png';
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";
import DelImg from '../../assets/img/delete-image.png';




function Profile() {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const { data } = JSON.parse(localStorage.getItem("psdata"));

  const [disabled, setDisabled] = useState(true);
  const [readOnly, setreadOnly] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sucessmsg, setSucessmsg] = useState("");
  const [showsucess, setShowsuccess] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Error, setError] = useState([]);
  const [profileImage, setProfileImage] = useState(`${data?.profile_image}`);
  const dispatch = useDispatch();
  const callBacksuccess = () => { };

  useEffect(() => {
    getMetaData();
  }, []);

  function handleGameClick() {
    setDisabled(!disabled);
    setreadOnly(!readOnly);
    setIsShown(current => !current);
  }

  const [inputtext, setinputtext] = useState({
    email: "",
    password: ""
  });

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputtext((lastValue) => {
      return {
        ...lastValue,
        [name]: value
      }
    });

  }
  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    }
    else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }


  const handleProfile = async (values) => {

    try {
      const paramData = {
        employee_name: values.employee_name,
        username: values.username,
        employee_email: values.employee_email,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/user/updateProfile`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      localStorage.setItem("psdata", JSON.stringify(data));
      dispatch(setUpdateUserData(data));
      // toast.success(`${data?.message}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setShowsuccess(true);
      setSucessmsg(data?.message);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
    } catch (error) {
      setShowError(true);
      setSucessmsg(error?.response?.data?.message);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
      // toast.error(`${error?.response?.data?.message}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // setErrormsg(error.response.data.message);
      console.log(error);
    }
  };

  const handlePassword = async (values, resetForm) => {

    try {
       const paramData = {
         old_password: values.old_password,
         password: values.password,
       };
       const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/user/updatePassword`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      resetForm();
      setShowsuccess(true);
      setSucessmsg(data?.message);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
    } catch (error) {
       const errorResponse = decryptedFunction(
         error?.response?.data?.responseData
       );
       console.log("errorResponse", errorResponse);
      setShowError(true);
      setSucessmsg(errorResponse?.message);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
      console.log(error);
    }
  };

  const [profileValues, setProfileValues] = useState({
    employee_name: data?.employee_name,
    employee_email: data?.employee_email,
    username: data?.username
  });

  const [passwordValues, setPasswordValues] = useState({
    old_password: "",
    password: "",
    new_password: ""
  });


  const getMetaData = async () => {
    try {

      const param = {
        label: "Settings",
      };

      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = await decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    document.title = metaData?.meta_title || 'PERFECTSHIFT';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);



  const uploadImage = async (file) => {
    setIsLoading(true);
    const maxSizeInKB = 1024;
    const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
    if (file.size > maxSizeInKB * 1024) {
      // alert(`Image size exceeds the maximum limit of ${maxSizeInKB} KB`);
      setError(`Image size exceeds the maximum limit of ${maxSizeInKB} KB`)
      setIsLoading(false);
      throw new Error(`Image size exceeds the maximum limit of ${maxSizeInKB} KB`);
    }

    if (!allowedFormats.includes(file.type)) {
      // alert("Invalid image format. Allowed formats: PNG, JPEG, JPG");
      setError("Invalid image format. Allowed formats: PNG, JPEG, JPG")
      setIsLoading(false);
      throw new Error("Invalid image format. Allowed formats: PNG, JPEG, JPG");
    }

    const formData = new FormData();
    formData.append("profile_image", file);

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/user/updateProfileImage`,
        formData,
        config
      );
      const data = decryptedFunction(response?.data?.responseData);
      localStorage.setItem("psdata", JSON.stringify(data));
      dispatch(setUpdateUserData(data));
      setError("");
      setIsLoading(false);
      setShowsuccess(true);
      setSucessmsg(data?.message);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
      return data.data?.profile_image;
    } catch (err) {
      setIsLoading(false);
      setShowError(true);
      setSucessmsg(err?.response?.data?.message);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
      return err;
    }
  };

    const removeProfileFun = async () => {
         setIsLoading(true);
      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/user/deleteProfileImage`
        );
        const data = decryptedFunction(response?.data?.responseData);
        localStorage.setItem("psdata", JSON.stringify(data));
        dispatch(setUpdateUserData(data));
        setError("");
        setIsLoading(false);
        setShowsuccess(true);
        setSucessmsg(data?.message);
        setTimeout(() => {
          setShowsuccess(false);
        }, 2000);
        setProfileImage("")
      } catch (err) {
        console.log(err);
      }
    };


  return (
    <PageLayout>
      <Row className="gx-2 mt-0 align-items-center">
        <Col md={{ span: 12, offset: 0 }}>
          <div className="bg-white p-3 min-height-profile">
            <h4 className="color-black m-0">Account Settings</h4>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="mt-5">
                <Col sm={3} className="w-220">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first" className="v-tab">
                        Profile
                      </Nav.Link>
                    </Nav.Item>
                    {data?.customer_code !== 101 && (
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="v-tab">
                          Password & Security
                        </Nav.Link>
                      </Nav.Item>
                     )}
                  </Nav>
                </Col>
                <Col sm={9} className="border-left-1">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="">
                        {/* <span class=" text-uppercase size-100 position-relative">
                <img src={ProfilePic}  className="profile-pic"/>
                          <img src={profileImage || Camera} className="camera" />
                         <input
                            type="file"
                            id="pic"
                            name="profile_picture"
                            onChange={async (e) => {
                              // setIsLoading(true);
                              var image = await uploadImage(e.target.files[0]);
                              setProfileImage(image);
                              // setIsLoading(false);
                            }}
                          />
              </span> */}
                        <div className="d-flex align-items-center">
                          <span class=" text-uppercase size-100 position-relative">
                            <div className="profile_picture">
                              <div className="profile_pic">
                                <img
                                  src={profileImage || ProfilePic}
                                  className="profile_pic"
                                />
                                <p>
                                  <input
                                    type="file"
                                    id="pic"
                                    name="profile_picture"
                                    // accept="image/png, image/jpg, image/jpeg"
                                    onChange={async (e) => {
                                      setIsLoading(true);
                                      var image = await uploadImage(
                                        e.target.files[0]
                                      );
                                      setProfileImage(`${image}`);
                                      setIsLoading(false);
                                    }}
                                  />
                                  <label
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    htmlFor="pic"
                                  >
                                    <img class="prof_img" src={ImgUpload} />
                                  </label>
                                </p>
                              </div>
                            </div>
                          </span>

                          <div className="delete-btn d-flex ms-4">
                            {/* <button class="prof_img btn ms-3 mb-0 btm btn-success text-capitalize w-auto f-14 btn btn-primary">
                              Upload Profile
                            </button> */}
                            {/* <p>
                                  <input
                                  className="d-none"
                                    type="file"
                                    id="pic1"
                                    name="profile_picture"
                                    // accept="image/png, image/jpg, image/jpeg"
                                    onChange={async (e) => {
                                      setIsLoading(true);
                                      var image = await uploadImage(
                                        e.target.files[0]
                                      );
                                      setProfileImage(`${image}`);
                                      setIsLoading(false);
                                    }}
                                  />
                                  <label
                                  className="btn ms-3 mb-0 btm btn-success text-capitalize w-auto f-14 cus-btn"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    htmlFor="pic1"
                                  >
                                    Upload Profile
                         
                                  </label>
                                </p> */}
                            {data?.profile_image && (
                              <Button 
                                onClick={() => {
                                  removeProfileFun();
                                }}
                                class="prof_img  ms-0 mb-0 text-capitalize w-auto f-14 " 
                                size="sm"
                                variant="outline-danger"
                              >
                                Remove Picture
                              </Button >
                            )}
                          </div>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontSize: 13,
                            color: "red",
                          }}
                        >
                          {Error}
                        </div>
                        <Formik
                          enableReinitialize
                          initialValues={profileValues}
                          validateOnChange
                          validate={profileValidator}
                          // onSubmit={(values) => handleProfile(values, false)}
                          onSubmit={handleProfile}
                        >
                          {(formikBag) => {
                            return (
                              <Form>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div class="mt-3">
                                      <label>Full Name</label>
                                      <Field name="employee_name">
                                        {({ field }) => (
                                          <input
                                            {...field}
                                            type="text"
                                            name="employee_name"
                                            class="form-control p-2"
                                            id="exampleInputEmail1"
                                            placeholder="Employee Name"
                                            value={
                                              formikBag.values.employee_name
                                            }
                                            onChange={(e) => {
                                              formikBag.setFieldValue(
                                                "employee_name",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                      </Field>
                                      {formikBag.touched.employee_name &&
                                      formikBag.errors.employee_name ? (
                                        <>
                                          <p
                                            style={{
                                              marginTop: 5,
                                              fontSize: 13,
                                              color: "red",
                                            }}
                                          >
                                            {formikBag.errors.employee_name}
                                          </p>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div class="mt-3">
                                      <label>Username</label>
                                      <Field name="username">
                                        {({ field }) => (
                                          <input
                                            {...field}
                                            type="text"
                                            name="username"
                                            class="form-control p-2"
                                            id="exampleInputEmail1"
                                            placeholder="Username"
                                            value={formikBag.values.username}
                                            disabled
                                            onChange={(e) => {
                                              formikBag.setFieldValue(
                                                "username",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                      </Field>
                                      {formikBag.touched.username &&
                                      formikBag.errors.username ? (
                                        <>
                                          <p
                                            style={{
                                              marginTop: 5,
                                              fontSize: 13,
                                              color: "red",
                                            }}
                                          >
                                            {formikBag.errors.username}
                                          </p>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div class="mt-3">
                                      <label>Email</label>
                                      <Field name="employee_email">
                                        {({ field }) => (
                                          <input
                                            {...field}
                                            type="text"
                                            name="employee_email"
                                            class="form-control p-2"
                                            id="exampleInputEmail1"
                                            placeholder="Username or Email"
                                            value={
                                              formikBag.values.employee_email
                                            }
                                            disabled
                                            onChange={(e) => {
                                              formikBag.setFieldValue(
                                                "employee_email",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                      </Field>
                                      {formikBag.touched.employee_email &&
                                      formikBag.errors.employee_email ? (
                                        <>
                                          <p
                                            style={{
                                              marginTop: 5,
                                              fontSize: 13,
                                              color: "red",
                                            }}
                                          >
                                            {formikBag.errors.employee_email}
                                          </p>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div class="col-12 d-flex align-items-center justify-content-end mt-4">
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      // onClick={handleGameClick}

                                      className="btn ms-3 btm btn-success text-capitalize w-auto f-14"
                                    >
                                      Save Details
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>

                        {/* <Form className="profile-form" onSubmit={handleSubmit}>
      <Row className="mb-3 mt-4">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Full Name</Form.Label>
                              <Form.Control type="text" name="employee_name" value={formData.employee_name} 
          readOnly={readOnly} disabled={disabled}
          onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>User Name</Form.Label>
          <Form.Control type="text" value={formData?.username} 
            name="username"
          readOnly={readOnly} disabled={disabled}
          onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" value={formData?.employee_email} 
          name="employee_email"
          readOnly={readOnly} disabled={disabled}
          
          onChange={handleInputChange}
          />
        </Form.Group>
      </Row>
     
     
     {!isShown && (
         <Row className="m-0 mb-3 mt-2 justify-content-end">
         <Button type="submit" variant="primary" onClick={handleGameClick} className="btn btm btn-success text-capitalize w-auto f-14">Edit Details</Button>
        </Row>
                          )}
     {isShown && (
         <Row className="m-0 mb-3 mt-2 justify-content-end">
         <Button type="submit" variant="primary" onClick={handleGameClick}  className="btn btm btn-default text-capitalize w-auto f-14">Cancel</Button>
         <Button variant="primary" onClick={handleGameClick} className="btn ms-3 btm btn-success text-capitalize w-auto f-14">Save Details</Button>
        </Row>
      )}
        </Form> */}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <h4 className="color-black m-0">Change Password</h4>

                      <Formik
                        enableReinitialize
                        initialValues={passwordValues}
                        validateOnChange
                        validate={passwordValidator}
                        onSubmit={(values, { resetForm }) => {
                          handlePassword(values, resetForm);
                        }}
                      >
                        {(formikBag) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-4">
                                  <div class="mt-3">
                                    <label>Old Password</label>
                                    <Field name="old_password">
                                      {({ field }) => (
                                        <input
                                          {...field}
                                          type="password"
                                          autocomplete="off"
                                          name="old_password"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          placeholder="Old Password"
                                          value={formikBag.values.old_password}
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "old_password",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    {formikBag.touched.old_password &&
                                    formikBag.errors.old_password ? (
                                      <>
                                        <p
                                          style={{
                                            marginTop: 5,
                                            fontSize: 13,
                                            color: "red",
                                          }}
                                        >
                                          {formikBag.errors.old_password}
                                        </p>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div class="mt-3">
                                    <label>New Password</label>
                                    <Field name="password">
                                      {({ field }) => (
                                        <input
                                          {...field}
                                          type="password"
                                          autocomplete="off"
                                          name="password"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          placeholder="Password"
                                          value={formikBag.values.password}
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "password",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    {formikBag.touched.password &&
                                    formikBag.errors.password ? (
                                      <>
                                        <p
                                          style={{
                                            marginTop: 5,
                                            fontSize: 13,
                                            color: "red",
                                          }}
                                        >
                                          {formikBag.errors.password}
                                        </p>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div class="mt-3">
                                    <label>Confirm Password</label>
                                    <Field name="new_password">
                                      {({ field }) => (
                                        <input
                                          {...field}
                                          type="password"
                                          autocomplete="off"
                                          name="new_password"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          placeholder="Confirm Password"
                                          value={formikBag.values.new_password}
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "new_password",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    {formikBag.touched.new_password &&
                                    formikBag.errors.new_password ? (
                                      <>
                                        <p
                                          style={{
                                            marginTop: 5,
                                            fontSize: 13,
                                            color: "red",
                                          }}
                                        >
                                          {formikBag.errors.new_password}
                                        </p>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-end mt-4">
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    // onClick={handleGameClick}

                                    className="btn ms-3 btm btn-success text-capitalize w-auto f-14"
                                  >
                                    Save Details
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                      {/* <Form className="profile-form">
       

      <Row className="mb-3 mt-4">
        <Form.Group as={Col} controlId="formGridCity" className="position-relative">
          <Form.Label>Old Password</Form.Label>
          <Form.Control type="password" value="12345678"/>
          <i className={`incr-eye fa fa-eye`}></i>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState" className="position-relative">
          <Form.Label>New Password</Form.Label>
          <Form.Control type={password}  onChange={inputEvent} name="password"  value="12345678"/>
          <i onClick={Eye} className={`incr-eye fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
        </Form.Group>

         <Form.Group as={Col} controlId="formGridZip" className="position-relative">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type="password" value="12345678"/>
          <i className={`incr-eye fa fa-eye`}></i>
        </Form.Group>
      </Row>
      <Row className="m-0 mb-3 mt-2 justify-content-end">
      <Button variant="primary" type="submit" className="btn btm btn-success text-capitalize w-auto f-14">
        Submit
      </Button>
     </Row>
      
             </Form> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Col>
      </Row>
      <ToastContainer />
      <SuccessModal
        isShow={showsucess}
        callbackfunc={callBacksuccess}
        title="Success"
        content={sucessmsg}
      />
      <ErrorModal
        isShow={showError}
        callbackfunc={callBacksuccess}
        title="Error"
        content={sucessmsg}
      />
      {isLoading && <Overlay />}
    </PageLayout>
  );
}

export default Profile;
