import "./index.css";
import { useState, useEffect } from "react";
import Realtime from './realtime';
import Currentperiod from './currentperiod';
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import "font-awesome/css/font-awesome.min.css";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from '../../assets/img/pslogo.png';
import { get, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";
import { useDispatch } from "react-redux";
import {
  setCostCenterData
} from "../../store/actions/Compensate";


function DirectEntry(props) {
  const { idlabel } = useParams() ;
  const { urltype } = useParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCostCenterNumber();
    getMetaData();
    // autoFillgetDirectDataById()
  }, [idlabel, urltype]);

  const getCostCenterNumber = async () => {
    const para = {
      slug: idlabel,
      facility_id: 1,
    };

    const encryptData = dataEnCrypt(para);

    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getCostCenterData",
      { payloadData: encryptData }
    );
    const data = decryptedFunction(resp?.data?.responseData);
    dispatch(setCostCenterData(data?.data?.cost_center));
    const ccn = data.data?.cost_center?.cost_center_number;
     if (!ccn) {
       navigate(`/realtime/not-found`);
     }
    const secondApiResponse = await getMetaData(ccn);
    setId(ccn);
    //fetchDetails();
  };


  const getMetaData = async (ccn) => {
    try {

        const param = {
          application_id: 101,
          cost_center_number: ccn,
        };
        const encryptData = dataEnCrypt(param);
        const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
          payloadData: encryptData,
        });
        const data = decryptedFunction(response?.data?.responseData);

      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    document.title = metaData?.meta_title || 'RealTime';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);






  if(urltype === "realtime"){
    return (
      <div>
        <Realtime urlparam={idlabel} id={id} />
      </div>
    );
  } 
  if(urltype === "currentperiod" || urltype === "previousperiods"){
    return (
      <div>
        <Currentperiod urlparam={idlabel} id={id} urltype={urltype} />
      </div>
    );
  }
  
}

export default DirectEntry;
