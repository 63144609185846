"use strict";
import React, { useEffect, useState, useContext } from "react";
import { AgChartsReact } from "ag-charts-react";
import { getPreviousShiftData } from "../../../services";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { useLocation, useNavigate } from 'react-router-dom';

export const ChartExample = ({
  data,
  callbackfunc,
  data1,
  boxColor,
  isdetail,
  directId,
}) => {
  const [urltype, setUrltype] = useState("realtime");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [hide, setHide] = useState(pathname === '/realtime' ? true : false);
  const colorStatus = {
    entry_on_time: "#48b461",
    entry_missing: "#ff2626",
    entry_upcoming: "#d0cece",
    entry_delayed: "#48b461",
    entry_due: "#FFEF00",
  };

  const inner_ring = data?.inner_ring;
  const arr1 = [];
  const arr2 = [];


  const outerringval = (outer_ring_val) => {
    const seriesarr = [];
    if (outer_ring_val >= 100) {
      arr1.push("#0a9d2c");
      arr1.push("#48b461");
      let caldata = outer_ring_val % 100;
      //setOutervalue(outer_ring_val);
      seriesarr.push({ calval: outer_ring_val, count: caldata });
      seriesarr.push({ calval: outer_ring_val, count: 100 - caldata });
    } else if (outer_ring_val < 100 && outer_ring_val >= 98) {
      arr1.push("#ffff26");
      arr1.push("#ffffb1");
      //setOutervalue(outer_ring_val);
      seriesarr.push({ calval: outer_ring_val, count: outer_ring_val });
      seriesarr.push({ calval: outer_ring_val, count: 100 - outer_ring_val });
    } else {
      arr1.push("#ff2626");
     // arr1.push("#fde9e9"); ////////////////////////////////////////
      arr1.push(themeMode.theme === "DARK" ? "#cdcecf" : "#f1f1f1");
      //setOutervalue(outer_ring_val);
      seriesarr.push({
        name: "Covered",
        calval: outer_ring_val,
        count: outer_ring_val,
      });
      seriesarr.push({
        name: "Not Covered",
        calval: outer_ring_val,
        count: 100 - outer_ring_val,
      });
    }
    return seriesarr;
  };

  const seriesdata = outerringval(data?.inner_productivity);


  if (data?.inner_ring) {
    data?.inner_ring.forEach((v) => {
      arr2.push(v.color);
    });
  }

  const options = {
    series: [
      {
        type: "pie",
        theme: "ag-default-dark",
        parentHeightOffset: 0,
        calloutLabelKey: "",
        data: data?.isRealTime
          ? data?.show_productivity_ring
            ? seriesdata
            : []
          : seriesdata,
        angleKey: "count",
        outerRadiusRatio: data?.isRealTime
          ? data?.show_productivity_ring === 1
            ? 0.83
            : 0.72
          : 0.83,
        innerRadiusRatio: 0.6,
        showInLegend: false,
        strokeWidth: 0,
        fills: ["red", "yellow"],
        tooltip: { renderer: renderer1 },
        highlightStyle: {
          item: {
            fill: "#CCCCCC00",
            //    stroke: 'maroon',
            strokeWidth: 0,
          },
          series: {
            dimOpacity: 1,
            strokeWidth: 0,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "100%",
              padding: 0,
              // background: "#293450",
            },

            dataLabels: {
              show: false,
            },
            pie: {
              expandOnClick: false,
            },
          },
        },
        fills: arr1,
      },
      {
        type: "pie",

        data: data?.isRealTime
          ? data?.show_compliance_ring
            ? inner_ring
            : []
          : inner_ring,
        parentHeightOffset: 0,
        angleKey: "percentage",
        calloutLabelKey: "shift_time",
        calloutLabel: {
          fontSize: 9,
          color: themeMode.theme === "DARK" ? "#FFF" : "black",
          // fontWeight: 'light',
        },
        labels: {
          show: false,
        },
        grid: {
          padding: {
            top: -20,
            bottom: -15,
          },
        },
        outerRadiusRatio: 1.2,
        innerRadiusRatio: 0.85,

        showInLegend: false,
        strokes:
          themeMode.theme === "DARK"
            ? ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"]
            : ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"],
        strokeWidth: 1,
        highlightStyle: {
          item: {
            fill: "transparent",
            scale: 1.5,
            strokeOpacity: "#000",

            strokeWidth: 1,
            //  stroke: '#000',
            stroke: themeMode.theme === "DARK" ? "white" : "black",
          },
          series: {
            dimOpacity: 1,
          },
        },
        tooltip: { renderer: renderer },
        fills: arr2,
      },
    ],
    background: {
      fill: themeMode.theme === "DARK" ? "#191C2400" : "#FFFFFF00",
    },
  };



  const chartOptions = {
    container: { background: { fill: 'yellow' } }, // Set the background color for the chart container
  };

  function renderer(params) {
    //getColorFun(params.color)
    return `<div class="ag-chart-tooltip-title" >
          ${params.datum.message}
        </div>`;
  }

  function renderer1(params) {
    return `<div class="ag-chart-tooltip-title" >
        ${params.datum?.calval}%
        </div>`;
  }


  return (
 
          <div className=" text-center" style={{width: "62px", height: "62px"}}>
          
              <AgChartsReact
                options={options}
                backgroundColor="yellow"
              // nodeMouseEnter={(event) =>
              //   handleHover(event, true, "black")
              // }
              // nodeMouseLeave={(event) => handleHover(event, false)}
              />
             
            </div>

  );
};
