import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SCHDULE from "../../../../assets/img/overtime/scheduling.svg";

import plus from "../../../../assets/img/plus.png";
import Form from "react-bootstrap/Form";
import axios from "../../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../../constants/compensate";
import SuccessModal from "../../../../components/shared/SuccessModal";
import Chart from "react-apexcharts";
import { ThemeContext } from "../../../../providers/ThemeProvider";
import ScheduleBasicChart from "../../../../components/ActiveChart/ScheduleDailyChart";

function InfoModal(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const showComment = props?.tableData?.showCommentButton;
  const [graphData, setGraphData] = useState({});
  const [graphDate, setGraphDate] = useState(null);

  const hoursTotal = () => {
    const totalSum = props?.tableData?.tableData?.reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.hours;
      },
      0
    );
    return totalSum;
  };
  const commentDetail = async (employeeId, centerNum, date) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/overtime/addCommentData`,
        {
          employee_id: employeeId,
          cost_center_number: centerNum,
          when_posted: date,
        }
      );
      setCommentData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year; // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${shortYear}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  const [themeMode, setTheme] = useContext(ThemeContext);
  var options = {
  //   series: [{
  //   data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
  // }],
    chart: {
    type: 'bar',
    height: 150,
    toolbar: {
      show: false,
    },
   
  },
  colors: ["#f6c140", "#f6c140"],
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
     
    },
    
    colors: ["#f6c140", "#f6c140"],
  },
  dataLabels: {
    enabled: false
  },
  grid: {
    show: false,
    padding: {
      left: -5,
      top: -5,
    },
  },
  xaxis: {
    categories: ['ACTUAL', 'SCHEDULED' ],
    labels: {
      show: false
    },
    show: false,
  
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    labels: {
      show: false
    },
    show: false,
  
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
 
  
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
        onClick={props.handlClick}
      >
        <Modal.Header
          closeButton
          className="custom-font-size border-0 cros-position"
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fw-normal color-black h3 align-items-top text-center justify-content-center w-100"
          >
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <img src={SCHDULE} alt="" height={38} /> &nbsp; Scheduled vs
                Worked Hours
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {props?.tableData?.costCenter?.cost_center_number} -{" "}
                {props?.tableData?.costCenter?.cost_center_name}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {props?.tableData?.employee?.employee_name} -{" "}
                {props?.tableData?.employee?.employee_job_title &&
                  `${props?.tableData?.employee?.employee_job_title} - `}
                {props?.tableData?.endDate}
              </div>
            </div>
            {/* </div> */}
            {/* <div></div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-font-size text-indent-15">
          <div class="text-center mb-2 ">
            <div className="bxh d-flex justify-content-center ">
              <div
                className={
                  themeMode.theme === "DARK"
                    ? "whitexyt legend-text"
                    : "blackxyt legend-text"
                }
              >
                <span className="circle"></span>Scheduled
              </div>
              <div className="purplexyt legend-text">
                <span className="circle"></span>Worked
              </div>
            </div>
          </div>
          <div class=" mt-minus-20">
            <div class="bg-white p-3 pt-0 h-100">
              <div>
                <ScheduleBasicChart
                  data={props?.tableData?.graphData}
                  //   GraphLoader
                  graphClick={setGraphDate}
                  className="w-100 "
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
function CommentModal(props) {
  const [reasonValue, setReasonValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [showsucess, setShowsucess] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const commentDetail = async () => {
    if (!reasonValue || !locationValue || !commentValue) {
      setErrorMsg("Please enter missing data");
    } else {
      try {
        const { data } = await axios.post(
          `${API_CALL_URL_PRIVATE}/comment/addComment`,
          {
            employee_id: props?.commentData?.employee_id,
            category: props?.commentData?.category,
            application_id: 105,
            cost_center_number:
              props?.dailyData?.costCenter?.cost_center_number,
            when_posted: props?.commentData?.when_posted,
            comment: commentValue,
            reason: reasonValue,
            location: locationValue,
          }
        );
        props.onHide(false);
        setShowsucess(true);
        setSuccessMessage(data.message);
        setTimeout(() => {
          setShowsucess(false);
        }, 2000);
        setReasonValue("");
        setLocationValue("");
        setCommentValue("");
        setErrorMsg("");
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="max-w-900"
        onHide={() => {
          props.onHide(false);
          setErrorMsg("");
        }}
      >
        <Modal.Header
          closeButton
          className="border-0 custom-cross-btn position-relative"
        >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form inline-form className="comment-form">
            <Row>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Category : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.category}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.show_when_posted}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Employee : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.employee_name}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Overtime Reason : </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="bg-theme"
                        onChange={(e) => setReasonValue(e.target.value)}
                      >
                        <option>Select Reason</option>
                        {props?.commentData?.reason?.map((val, ind) => {
                          return (
                            <option key={ind} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Overtime Location : </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="bg-theme"
                        onChange={(e) => setLocationValue(e.target.value)}
                      >
                        <option>Select Location</option>
                        {props?.commentData?.location?.map((val, ind) => {
                          return (
                            <option key={ind} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col className="mobile-mt-0" style={{ marginTop: "-30px" }}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Additional Notes:</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ textAlign: "left" }}
                        rows={10}
                        onChange={(e) => {
                          setCommentValue(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            onClick={() => {
              props.onHide(false);
              setErrorMsg("");
            }}
            variant="danger"
            className="border-0"
          >
            CANCEL
          </Button>
          <Button
            onClick={commentDetail}
            variant="success"
            className="border-0"
          >
            SUBMIT
          </Button>
          <div style={{ color: "red", width: "100%", textAlign: "right" }}>
            {errorMsg}
          </div>
        </Modal.Footer>
      </Modal>

      <SuccessModal
        isShow={showsucess}
        callbackfunc={setShowsucess}
        title="Success"
        content={successMessage}
      />
    </>
  );
}

export default InfoModal;
