import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SCHDULE from "../../../../assets/img/overtime/scheduling.svg";
import { ThemeContext } from "../../../../providers/ThemeProvider";

import plus from "../../../../assets/img/plus.svg";
import Bi5 from "../../../../assets/img/comment-blue.png";
import Form from "react-bootstrap/Form";
import axios from "../../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../../constants/compensate";
import SuccessModal from "../../../../components/shared/SuccessModal";
import Chart from "react-apexcharts";
import moment from "moment";
import { get, isEmpty } from "lodash";
import ScheduleTimeChart from "../../../../components/ActiveChart/ScheduleTimebarChart";
import {
  formatNumberWithFraction,
} from "../../../../utils/helper";
import Approve from "../../../../assets/img/done.png";
import UnApprove from "../../../../assets/img/done1.png";
import Reject from "../../../../assets/img/close1.png";
import UnReject from "../../../../assets/img/close2.png";

import More from "../../../../assets/img/ui.png";
import { decryptedFunction, dataEnCrypt } from "../../../../utils/helper";

function InfoModal(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [themeMode, setTheme] = useContext(ThemeContext);
  const showComment = props?.tableData?.showCommentButton;
  const [showsucess, setShowsucess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const ScheduleApiData =  props?.tableData;
  const [showMore, setShowMore] = useState(false);
  const CommentApiData = props?.commentSchedule?.commentData;
  const [expandedComments, setExpandedComments] = useState({});


  const hoursTotal = () => {
    const totalSum = props?.tableData?.tableData?.reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.hours;
      },
      0
    );
    return totalSum;
  };
  const commentDetailFun = async (employeeId, centerNum, date) => {
    setCommentData([])
    try {
       const paramData = {
         employee_id: employeeId,
         cost_center_number: centerNum,
         when_posted: date,
       };
       const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/schedule/addCommentData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setCommentData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year; // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${shortYear}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

    const manageReview = async (_id, _manager_review) => {
      try {
         const paramData = {
           _id: _id,
           manager_review: _manager_review,
         };
         const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/comment/managerReview`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        setSuccessMessage(data.message);
        setShowsucess(true);
          props?.overtimeDailyFun(
            props?.overtimeDailyParameter?.employee_id,
            props?.overtimeDailyParameter?.cost_center_number,
            props?.overtimeDailyParameter?.when_posted
          );
        setTimeout(() => {
          setShowsucess(false);
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    };

      const toggleComment = (index) => {
        setExpandedComments((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
      };


  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
        onClick={props.handlClick}
      >
        <Modal.Header
          closeButton
          className="custom-font-size border-0 cros-position"
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fw-normal color-black h3 d-flex align-items-top text-center justify-content-center w-100"
          >
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <img src={SCHDULE} alt="" height={38} /> &nbsp; Schedule Daily
                Detail
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {ScheduleApiData?.cost_center_number} -{" "}
                {ScheduleApiData?.cost_center_name}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {ScheduleApiData?.employee_name} -{" "}
                {ScheduleApiData?.profile_description &&
                  `${ScheduleApiData?.profile_description} - `}
                {ScheduleApiData?.display_date}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-font-size text-indent-15">
          <div class=" mt-minus-20">
            <div class="bg-white p-3 pt-0 h-100">
              <div>
                <ScheduleTimeChart data={props?.tableData} />
              </div>

              <div class="table-responsive">
                <div class="bxh d-flex justify-content-center mt-2">
                  {/* <div class="redt legend-text"><span class="circle"></span>Critical</div> */}
                  <div class="purplexyt legend-text">
                    <span class="circle"></span>ACTUAL
                  </div>
                  <div
                    className={
                      themeMode.theme === "DARK"
                        ? "whitexyt legend-text"
                        : "blackxyt legend-text"
                    }
                  >
                    <span class="circle"></span>SCHEDULED
                  </div>
                </div>
                <Row className="m-0 mt-3 gx-2">
                  <Col sm={{ span: 3 }}>
                    <div className="border p-3 text-center">
                      <h4 className="text-center justify-content-center">
                        SCHEDULED HOURS
                      </h4>
                      <h4 className="text-center justify-content-center fw-light">
                        {ScheduleApiData?.schedule_hours ||
                        ScheduleApiData?.schedule_hours === 0
                          ? ScheduleApiData?.schedule_hours
                          : " - "}
                      </h4>
                    </div>
                  </Col>
                  <Col sm={{ span: 3 }}>
                    <div className="border p-3 text-center">
                      <h4 className="text-center justify-content-center">
                        LUNCH DURATION
                      </h4>
                      <h4 className="text-center justify-content-center">
                        {ScheduleApiData?.lunch_hour ||
                        ScheduleApiData?.lunch_hour === 0
                          ? `${ScheduleApiData?.lunch_hour?.toFixed(2)} Hr`
                          : " - "}
                      </h4>
                    </div>
                  </Col>
                  <Col sm={{ span: 3 }}>
                    <div className="border p-3 text-center">
                      <h4 className="text-center justify-content-center">
                        ACTIVITY DESCRIPTION
                      </h4>
                      <h4 className="text-center justify-content-center">
                        {ScheduleApiData?.activity_description
                          ? ScheduleApiData?.activity_description
                          : " - "}
                      </h4>
                    </div>
                  </Col>
                  <Col sm={{ span: 3 }}>
                    <div className="border p-3 text-center">
                      <h4 className="text-center justify-content-center">
                        SCHEDULE VARIANCE
                      </h4>
                      <h4 className="text-center justify-content-center">
                        {ScheduleApiData?.scheduled_variance ||
                        ScheduleApiData?.scheduled_variance === 0
                          ? `${ScheduleApiData?.scheduled_variance?.toFixed(
                              2
                            )} Hr`
                          : " - "}
                      </h4>
                    </div>
                  </Col>
                </Row>

                {CommentApiData?.length > 0 ? (
                  <>
                    <Modal.Title
                      id="contained-modal-title-vcenter"
                      className="fw-normal color-black h3 d-flex align-items-center justify-content-center w-100 mt-2"
                    >
                      <img src={Bi5} alt="" height={32} /> &nbsp; COMMENTS
                    </Modal.Title>
                    <div class="overflow-auto">
                      <table class="table activity-tabel mb-0  spacing-table tb1 table-hover comment-table">
                        <thead>
                        <tr>
                            <th class="coll text-center cursor-pointer">
                              <div class="d-flex justify-content-center align-items-center">
                                <div>Date<br/>Submitted</div>
                              </div>
                            </th>
                            <th class="coll text-center">Submitted<br/>By</th>
                            <th class="coll text-center">Employee</th>
                            <th class="coll text-center">Category</th>
                            <th class="coll text-center">Activity<br/>Date</th>
                            <th class="coll text-center">Reason</th>
                            <th class="coll text-center">Location</th>
                            <th class="coll text-center">Comment</th>
                            <th class="coll text-center">Approve</th>
                          </tr>
                        </thead>
                        <tbody class="comment-table-height scrollbar h-150">
                          {CommentApiData?.map((val, ind) => {
                             const isExpanded = expandedComments[ind];
                             const truncatedComment = val.comment.substring(
                               0,
                               45
                             );
                             const displayComment = isExpanded
                               ? val.comment
                               : truncatedComment;
                            return (
                              <tr>
                                <td class="text-center ">
                                  {" "}
                                  {moment(val.createdAt).format("MM/DD/YY")}
                                </td>
                                <td class="text-center">
                                  {" "}
                                  {val.submitted_by_name}
                                </td>
                                <td class="text-center">{val.employee_name}</td>
                                <td class="text-center">{val.category}</td>
                                <td class="text-center">
                                  {convertDateFormat(val.transaction_date)}
                                </td>
                                <td class="text-center">{val.reason}</td>
                                <td class="text-center">{val.location}</td>
                                <td class="text-center">
                                  {displayComment}
                                  {val.comment.length > 45 && (
                                    <span
                                      className="shwmore"
                                      onClick={() => toggleComment(ind)}
                                    >
                                      {isExpanded ? (
                                        "Less"
                                      ) : (
                                        <img
                                          src={More}
                                          width={18}
                                          title="Read More"
                                        />
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td class="text-center img-cursor-pointer">
                                {val?.manager_review === 1 ? (
                                  <>
                                    <img
                                      src={Approve}
                                      alt="Approve"
                                      onClick={(e) => {
                                        if (val?.manager_review === 1) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 1);
                                        }
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <img
                                      src={Reject}
                                      alt="Reject"
                                      onClick={(e) => {
                                        if (val?.manager_review === 2) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 2);
                                        }
                                      }}
                                    />
                                  </>
                                ) : val?.manager_review === 2 ? (
                                  <>
                                    <img
                                      src={UnApprove}
                                      alt="UnApprove"
                                      onClick={(e) => {
                                        if (val?.manager_review === 1) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 1);
                                        }
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <img
                                      src={UnReject}
                                      alt="UnReject"
                                      onClick={(e) => {
                                        if (val?.manager_review === 2) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 2);
                                        }
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={UnApprove}
                                      alt="UnApprove"
                                      onClick={(e) => {
                                        if (val?.manager_review === 1) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 1);
                                        }
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <img
                                      src={Reject}
                                      alt="Reject"
                                      onClick={(e) => {
                                        if (val?.manager_review === 2) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 2);
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div class="d-flex text-center flex-wrap justify-content-center ">
                  <div
                    class=" border-btn p-1 cursor-pointer green-b d-flex align-items-center  w-100"
                    onClick={() => {
                      setModalShow(true);
                      commentDetailFun(
                        ScheduleApiData.employee_id,
                        ScheduleApiData?.cost_center_number,
                        ScheduleApiData?.transaction_date
                          ? ScheduleApiData?.transaction_date
                          : moment(ScheduleApiData?.display_date).format(
                              "DD-MM-YYYY"
                            )
                      );
                    }}
                  >
                    <img src={plus} alt="" height={18} /> Add Comment
                  </div>
                </div>
              </div>
              <CommentModal
                show={modalShow}
                commentData={commentData}
                dailyData={ScheduleApiData}
                overtimeDailyFun={props?.overtimeDailyFun}
                overtimeDailyParameter={{
                  employee_id: props?.overtimeDailyParameter?.employee_id,
                  cost_center_number:
                    props?.overtimeDailyParameter?.cost_center_number,
                  when_posted: props?.overtimeDailyParameter?.when_posted
                }}
                onHide={() => {
                  setModalShow(false);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SuccessModal
        isShow={showsucess}
        callbackfunc={setShowsucess}
        title="Success"
        content={successMessage}
      />
    </>
  );
}
function CommentModal(props) {
  const [reasonValue, setReasonValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [showsucess, setShowsucess] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const LocationData =  props?.commentData?.location;
  const ReasonData = props?.commentData?.reason;
  const commentDetail = async () => {

      if ((!isEmpty(LocationData) && !locationValue) || (!isEmpty(ReasonData) && !reasonValue) || !commentValue) {
        setErrorMsg("Please enter missing data");
      } else {
        try {

          const paramData = {
            employee_id: props?.commentData?.employee_id,
            category: props?.commentData?.category,
            application_id: 105,
            cost_center_number: props?.dailyData?.cost_center_number,
            when_posted: props?.commentData?.when_posted,
            comment: commentValue,
            reason_id: +reasonValue,
            location_id: +locationValue,
          };

          const encryptData = dataEnCrypt(paramData);

          const response = await axios.post(
            `${API_CALL_URL_PRIVATE}/comment/addComment`,
            { payloadData: encryptData }
          );
          const data = decryptedFunction(response?.data?.responseData);
          props.onHide(false);
          setShowsucess(true);
          setSuccessMessage(data.message);
          setTimeout(() => {
            setShowsucess(false);
          }, 2000);
          setReasonValue("");
          setLocationValue("");
          setCommentValue("");
          setErrorMsg("");

           props?.overtimeDailyFun(
             props?.overtimeDailyParameter?.employee_id,
             props?.overtimeDailyParameter?.cost_center_number,
             props?.overtimeDailyParameter?.when_posted
           );
        } catch (error) {
        setErrorMsg(
          "Oops! It seems like there was an issue with your form submission"
        );
          console.log(error);
        }
      }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="max-w-900"
        onHide={() => {
          props.onHide(false);
          setErrorMsg("");
        }}
      >
        <Modal.Header
          closeButton
          className="border-0 custom-cross-btn position-relative"
        >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form inline-form className="comment-form">
            <Row>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Category : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.category}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.show_when_posted}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Employee : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.employee_name}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Schedule Reason : </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="bg-theme"
                        onChange={(e) => setReasonValue(e.target.value)}
                      >
                        <option>Select Reason</option>
                        {props?.commentData?.reason?.map((val, ind) => {
                          return (
                            <option key={ind} value={val?.reason_id}>
                              {val?.reason}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Schedule Location : </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="bg-theme"
                        onChange={(e) => setLocationValue(e.target.value)}
                      >
                        <option>Select Location</option>
                        {props?.commentData?.location?.map((val, ind) => {
                          return (
                            <option key={ind} value={val?.location_id}>
                              {val?.location}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col className="mobile-mt-0" style={{ marginTop: "-30px" }}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Additional Notes:</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ textAlign: "left" }}
                        rows={10}
                        onChange={(e) => {
                          setCommentValue(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            onClick={() => {
              props.onHide(false);
              setErrorMsg("");
            }}
            variant="danger"
            className="border-0"
          >
            CANCEL
          </Button>
          <Button
            onClick={commentDetail}
            variant="success"
            className="border-0"
          >
            SUBMIT
          </Button>
          <div style={{ color: "red", width: "100%", textAlign: "right" }}>
            {errorMsg}
          </div>
        </Modal.Footer>
      </Modal>

      <SuccessModal
        isShow={showsucess}
        callbackfunc={setShowsucess}
        title="Success"
        content={successMessage}
      />
    </>
  );
}

export default InfoModal;
