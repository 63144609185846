import { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../../providers/ThemeProvider";
import green from "../../../../assets/img/polygon9.png";
import red from "../../../../assets/img/polygon8.png";
import equal from "../../../../assets/img/equal.png";
import axios from "../../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../../constants/";
import { decryptedFunction, dataEnCrypt } from "../../../../utils/helper";

import moment from "moment";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function CensusLineChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
  modalGraphData,
  type,
  label,
  dataLabel
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [graphTime, setGraphTime] = useState([]);
  const [graphValue, setGraphValue] = useState([]);
  const [graphTrendData, setGraphTrendData] = useState([]);

  const getGraphTrend = async () => {


    try {

      const paramData = {
        cost_center_number: modalGraphData?.cost_center_number,
        graph_type: type,
      };
      const encryptData = dataEnCrypt(paramData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/dashboard/getGraphTrends`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setGraphTrendData(data?.data);
      const shiftTimes = data?.data?.graphData?.map(entry => entry.shift_time);
      setGraphTime(shiftTimes);
      
      const Census = data?.data?.graphData?.map(entry => entry.shift_value);
      setGraphValue(Census);


    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getGraphTrend();
  }, [modalGraphData]);



  var options = {
    chart: {
      // height: 90,
      type: "line",

      dropShadow: {
        enabled: false,
        color: "#0ca788",
        top: 1,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },
    // colors: [color],
    colors: ["#0ca788", "#247BA0"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      //  curve: "smooth",
      width: 3,
    },
    grid: {
      borderColor: themeMode.theme === "DARK" ? "#FFF" : "#DDD",
      row: {
        colors: [
          themeMode.theme === "DARK" ? "#555" : "#f3f3f3",
          "transparent",
        ],
        opacity: 0.0,
      },
      padding: {
        left: 12,
        right: 15,
      },
    },
    markers: {
      size: 7,
      hover: {
        size: 10,
        sizeOffset: 3,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
    },
    xaxis: {
      categories: graphTime,
      color: themeMode.theme === "DARK" ? "#FFF" : "black",
      tickPlacement: "between",
      title: {
        show: false,
      },

      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
          fontSize: "10px",
          // offsetX: -50,
          // offsetY: -30,
        },

        // offsetX: 5,
        // rotate: -45,
      },
    },
    yaxis: {
      show: true,
      color: themeMode.theme === "DARK" ? "#FFF" : "black",
      tickAmount: 5,
      //  min: 0,
      //max: 100
      labels: {
        show: true,

        style: {
          colors: themeMode.theme === "DARK" ? "#FFF" : "black",
          fontSize: "11px",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (val) {
          return val?.toFixed(2);
        },
      },
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: 25,
      offsetX: 5,
      show: false,
    },
    // ==============================================
    annotations: {
      yaxis: [
        {
          y: graphTrendData?.avgData?.average,
          borderColor: "#0ca788",
          borderWidth: 2,
          label: {
            borderColor: "#0ca788",
            textAnchor: "start",
            position: "left",
            style: {
              color: "#fff",
              background: "#0ca788",
            },
            text: `Average ${dataLabel} - ${graphTrendData?.avgData?.average}`,
          },
        },
      ],
    },

    // ============================================
  };
  return (
    <div>
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          <div class="p-2 f-12 text-center mb-minus-10" style={{ color }}>
            <h4>{modalGraphData?.cost_center_name} - {label}</h4>
          </div>
          <div  className="mt-4 mb-3">
            <div className="d-flex justify-content-center mobile-wrap ">
                    <div class=" h-100 bg-white d-flex align-items-center me-5 mobile-margin-0 mobile-w-100 mobile-mb-10">
                        <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2 h-85 px-5 mobile-w-100 mobile-text-center">
                  <h4 class="f-14 w-100 m-0">{graphTrendData?.avgData?.average} - Average {dataLabel}</h4>
                        
                        </div>
                    </div>
                    <div class=" h-100 bg-white d-flex align-items-center" style={{maxWidth: "350px"}}>
                        <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2">
                  {graphTrendData?.avgData?.flag === 1 &&
                    <p class="f-14 text-center w-100 m-0">{dataLabel} is up {graphTrendData?.avgData?.averageDifferencePercentage}
                    </p>
                  }

                  {graphTrendData?.avgData?.flag === 2 &&
                    <p class="f-14 text-center w-100 m-0">{dataLabel} is down {graphTrendData?.avgData?.averageDifferencePercentage}
                    </p>
                  }

                  <p class="f-14 w-100 text-center m-0">
                    {graphTrendData?.avgData?.flag === 0 &&
                      <img src={equal} height="15" style={{ verticalAlign: "text-top" }} />
                    }
                    {graphTrendData?.avgData?.flag === 1 &&
                      <img src={green} height="15" style={{ verticalAlign: "text-top" }} />
                    }
                    {graphTrendData?.avgData?.flag === 2 &&
                      <img src={red} height="15" style={{ verticalAlign: "text-top" }} />
                    }
                    &nbsp; {graphTrendData?.avgData?.averageDifference} Average Daily {dataLabel}</p>
                            <p class="f-14 w-100 text-center m-0"><small>Compared to Previous Pay Period</small></p>
                        </div>
                    </div>
            </div>
            <div className="mobile-w-600" style={{ width: "100%" }}>
              <Chart
                options={options}
                series={[
                  {
                    name: `${dataLabel} Trends`,
                    data: graphValue
                  },
                ]}
                type="line"
              //  width={"100%"}
                height={400}
              />
            </div>
          </div>
        </div>
      ) : (
          <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default CensusLineChart;
