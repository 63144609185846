import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";

import ContentLoader from "react-content-loader";
// import Form from "react-bootstrap/Form";
import PageLayout from "../../../layouts/PageLayout";
import { get, isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import BiweeklyChart from "../../../components/ActiveChart/BiweeklyChart";
import { getTableRecordWithSorting } from "../../../services";
import DescIcon from '../../../assets/img/desc.png';
import AscIcon from '../../../assets/img/asc.png';
import { ThemeContext } from "../../../providers/ThemeProvider";
import { DOMAIN_URL } from "../../../constants/compensate";
import PsLogo from '../../../assets/img/pslogo.png';
import Button from "@atlaskit/button";
import { CustomDropdown, DropdownIndicator, ChevronDown } from '../../../Styled/DropdownStyle';
import Select from "react-select";
import yellow from "../../../assets/img/polygon7.png";
import red from "../../../assets/img/polygon8.png";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";




const TableLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 280 15" style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="15" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 280 50"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 30 }}>
    <ContentLoader viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
)

function MissedMealBreak({ history }) {
  const dispatch = useDispatch();
  const { department } = useParams();
  const [themeMode, setTheme] = useContext(ThemeContext);

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShowMeal] = React.useState(false);

  const [modalShow2, setModalShowAgency] = React.useState(false);

  const [modalShow3, setModalShowOvertime] = React.useState(false);



  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [graph1Data, setGraph1Data] = useState([]);
  const [sortType, setSortType] = useState('asc');
  const [sortName, setsortName] = useState('');
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [graphMaxData, setGraphMaxData] = useState("");
  const [metaData, setMetaData] = useState([]);
  const momoDate = useSelector((state) => state.compensate.updateDate);
  const momoIndex = useSelector((state) => state.compensate.updatedIndex);
  const [scheduleData, setScheduleData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [costCenterList, setCostCenterList] = useState([]);
  const [allCostCenter, setAllCostCenter] = useState([]);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [selectedOptionTwo, setSelectedOptionTwo] = useState(undefined);
  const [filterDateList, setFilterDateList] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [headData, setHeadData] = useState([]);



  const getCostCenterList = async (ccn) => {
    try {
      const { data } = await axios.post(`${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`);
      setCostCenterList(data?.data?.costCenterResponse.map((item) => {
        return {
          label: item.cost_center_name,
          value: item.cost_center_number,
        };
      }))

      setAllCostCenter(data?.data?.costCenterResponse);

      const matchingCostCenter = data.data?.costCenterResponse.filter(
        (val) => val.cost_center_slug === department)[0];
      setDropdownValue({ label: matchingCostCenter?.cost_center_name, value: matchingCostCenter?.cost_center_number })
      return matchingCostCenter;

    } catch (err) {
      console.log(err);
    }
  };

  const getFilterDate = async () => {
    try {
      const { data } = await axios.post(`${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`);
      setFilterDateList(data?.data?.payPeriodEndDates.map((item) => {
        return {
          label: item.display_date,
          value: item.payload_date,
        };
      }))

      const matchingCostCenter = data?.data?.payPeriodEndDates[0];
      setDropdownValueTwo({ label: matchingCostCenter?.display_date, value: matchingCostCenter?.payload_date })
      return matchingCostCenter;

    } catch (err) {
      console.log(err);
    }
  };



  const getTableData = async (eCost, eDate) => {
    setIsTableLoading(true);
    setIsGraphLoading(true);
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/agency/getData`,
        {
          cost_center_number: eCost,
          payperiod_end_date: eDate,
        }
      );
      setIsTableLoading(false);
      setIsGraphLoading(false);
      setTableData(data?.data?.tableData);
      setHeaderData(data?.data?.header);
      setGraphData(data?.data?.graphData);

      const tableData = data.data?.tableData;
      if (tableData && tableData.length > 0) {
        const sum = tableData.reduce(function (previousValue, currentValue) {
          return {
            hours: previousValue.hours + currentValue.hours,
          };
        });

        setTotalvalue(sum);
      } else {
        setTotalvalue(null);
      }
      
      setIsTableLoading(true);
      setIsGraphLoading(true);
      setIsLoading(true);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getCostCenterNumber = async () => {
    setIsTableLoading(true);
    setIsGraphLoading(true);
    setIsLoading(true);
    const para = {
      slug: department,
      facility_id: 1,
    };
    const encryptData = dataEnCrypt(para);
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getCostCenterData",
      { payloadData: encryptData }
    );
    const data = decryptedFunction(resp?.data?.responseData);
    const ccn = data.data?.cost_center?.cost_center_number;
    const dropDataResponse = await fetchData(ccn);
    //fetchDetails();
  };


  const fetchData = async (ccn) => {
    try {
      const Costlist = await getCostCenterList(ccn);
      const Datelist = await getFilterDate();
      await getTableData(Costlist?.cost_center_number, Datelist?.payload_date);
      setIsGraphLoading(false);
      setIsLoading(false);
      setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getCostCenterNumber();
    getMetaData();
    getRefreshSchedule();
  }, []);



  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === 'asc' ? 'desc' : 'asc');

    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  }


  const getMetaData = async () => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`, {
        application_id: 105
      }
      );
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.title = metaData?.meta_title || 'Active Daily Management';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);


  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };


  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {

  }, [isDropdownOpen, dropdownValue]);

  const jumpFun = (e) => {
    let filVal = allCostCenter.filter(
      (val) => val.cost_center_number === e
    );
    const url = `/activedailymanagement/adm-summary/${filVal[0]?.cost_center_slug}`;
    if (filVal[0]?.cost_center_slug) {
      history(url);
    } else {
      return "";
    }
  };

  const getRefreshSchedule = async () => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`
      );
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <PageLayout>
      <div className="activedailymanagement-s">

        {/* <Row>
<Form className="form-inline">
              <Form.Group className="col" controlId="formBasici">
                <Form.Label>Supervisor (Select)</Form.Label>
                <div
                  className="dropdownwithsearch"
                   onClick={opendropdownSupvisor}
                >
                  <InputGroup ref={outsupvisor}  className="mb-3">
                    <Form.Control
                      placeholder="Please Select"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value="llllll"
                      // value={
                      //   dropCostData.label
                      //     ? dropCostData
                      //     : defaultval[0].label
                      // }
                    />
                    <Button className="btn-right">
                      <div class=" css-1hb7zxy-IndicatorsContainer">
                        <span class=" css-1u9des2-indicatorSeparator"></span>
                        <div
                          class=" css-1xc3v61-indicatorContainer"
                          aria-hidden="true"
                        >
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            class="css-tj5bde-Svg"
                          >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                          </svg>
                        </div>
                      </div>
                    </Button>
                  </InputGroup>
                  <div
                    className={
                      supvisordropdown
                        ? "searchlistwrap showing"
                        : "searchlistwrap"
                    }
                  >
                    <div className="searchfield" ref={outsupvisor}>
                      <div className="searchicon">
                        <img
                          src={Searchicon}
                          alt=""
                          width={18}
                          style={{ verticalAlign: "text-bottom" }}
                        />
                      </div>
                      <Form.Control
                        type="text"
                        id="searchfield"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Search"
                        
                        autoComplete="off"
                      />
                    </div>
                    <ul>
                    
                    <li>jjlkkln</li>
                    </ul>
                  </div>
                </div>
             
              </Form.Group>
              </Form>
</Row> */}
        <Row className="gx-2 mt-0 align-items-center">
          <Col md={{ span: 12 }}>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <h4 className="text-center color-black m-0">Agency Summary -
                </h4>
              </div>

              <div
                className="custom-select-v"
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : ""

                      }
                    </Button>
                  }
                >
                  <Select

                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueTwo?.value)
                      // jumpFun(e?.value)
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                  />
                </CustomDropdown>
              </div>

              <div
                className="custom-select-v"
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      {dropdownValueTwo ? dropdownValueTwo.label : ""

                      }
                    </Button>
                  }
                >
                  <Select

                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValueTwo(e);
                      getTableData(dropdownValue?.value, e?.value)
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div>

            </div>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2">

          </Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal show={footerShow}
            dataFooter={headData}
            onHide={() => setFooterShow(false)} />
        </Row>

        < Row className="gx-2">

          <Col md={7} className="mt-2 h-660">
            <div className="bg-white p-3 h-100">

              {/* <div class="text-center mb-2"><div class="box-heading text-center">Biweekly Summary</div></div> */}
              <div class="d-flex text-center flex-wrap mb-2">
                <a class="flex-grow-1 me-1 border-btn f-14 cursor-pointer green-b " href="#">Pay Code</a>
                <a class="flex-grow-1 me-1 border-btn f-14 cursor-pointer green-b active" href="#">Job Description</a>
                <a class="flex-grow-1 border-btn f-14 cursor-pointer green-b " href="#">Employee</a></div>
              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table"
              >
                <thead>
                  <tr className="vertical-align-middle">
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle('employee_id')}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Employee Id</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "employee_id" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle('employee_name')}>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Employee Name</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "employee_name" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                        </div>
                      </div>

                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle('hours')}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Overtime Hours</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "hours" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                        </div>
                      </div>
                    </th>

                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle('comment')}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div> Comment</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "comment" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle('submitted_by')}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Submitted By</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "submitted_by" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="crossi">
                  {!isTableLoading ? (
                    tableData?.length > 0 ? (
                      tableData.map((val, ind) => {
                        return (
                          <tr key={ind} style={{ cursor: "pointer" }} className={selectedRow === val?._id ? 'selected-active-row' : ''}
                          // onClick={() => handleDoubleExceptional(val)}
                          >
                            <td className="text-center">{val?.employee_id}</td>
                            <td className="text-center">{val?.employee_name}</td>
                            <td className="text-center">{val?.hours?.toFixed(1)}</td>
                            <td className="text-center small">{val?.comment}</td>
                            <td className="text-center small">{val?.submitted_by}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>
                <tbody className="ftr">
                  {isTableLoading ? (
                    <div>
                      <TableLoader themeMode={themeMode} />
                    </div>
                  ) : !isEmpty(totalvalue) ? (
                    <tr className="bt">
                      <td className="text-center">
                        <strong>Total</strong>
                      </td>
                      <td className="text-center">
                        <strong>
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }).format(
                            get(totalvalue, "hours", 0)
                          )}
                        </strong>
                      </td>

                      <td className="text-center">

                      </td>
                      <td className="text-center">

                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={5} className="mt-2">
            <div className="border-bottom-5 gr h-half" style={{ borderColor: "#272727" }}>
              <div className=" h-100 bg-white d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-center flex-wrap m-0  w-100">
                  <h4 className="w-100 m-0"> Agency Hours are up %11 </h4>
                  <p className="f-14 w-100 text-center m-0"><img src={yellow} height={15} style={{ verticalAlign: "baseline" }} /> &nbsp; 7.68 per day</p>
                  <p className="w-100 text-center"><small>Compare to rolling trend</small></p>
                </div>
              </div>
            </div>
            <div className="border-top-5 gr h-half" style={{ borderColor: "#272727" }}>
              <div className=" h-100 bg-white d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-center flex-wrap m-0  w-100">
                  <h4 className="w-100 m-0">3 Employes Account for %78 </h4>
                  <p className="f-14 w-100 text-center m-0"><img src={red} height={15} style={{ verticalAlign: "baseline" }} /> &nbsp; Averaging 3.1 hours</p>
                  <p className="w-100 text-center"><small>of Agency Usage </small></p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-2">
            <div className=" h-100 bg-white">
              {isGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <BiweeklyChart
                  data={graphData}
                  maxData={graphMaxData}
                  // chartColor="#D7263D"
                  chartName="HPUOS and Target By Day"
                  // indexNum={momoIndex}
                  tooltip="Critical Exceptions"
                />
              )}
            </div>

          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              {/* <span>{headData?.current_file_msg}</span> */}
              <div className="col-12 text-end text-mobile-center bg-white"><span className="d-inline-block p-2  cursor-pointer" onClick={() => setFooterShow(true)}>{headData?.current_file_msg}</span></div>
              {/* <span className="col-6"><span className=" text-start  p-2 bg-white d-block">{headData?.upcomming_file_msg}</span></span> */}
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default MissedMealBreak;



