
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";
import { Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton className="custom-font-size text-center border-0">
        
      </Modal.Header>
      <Modal.Body className="custom-font-size text-indent-15">
       
     <Row>
      <Col md={12}>
      <Table striped bordered hover>
        <tr>
          <td><strong>Cost Center :</strong>  7th Avenue Surgery</td>
          <td><strong>Name:</strong>  7th Avenue Surgery</td>
          <td><strong>Pay Period End Date :</strong>  1/15/2022</td>
        </tr>
        </Table>
      {/* <Row>
      <Col md={4}><p className=" fw-normal color-black f-18  mb-0"><strong>Cost Center :</strong>  7th Avenue Surgery</p></Col>
      <Col md={4}><p className=" fw-normal color-black f-18  mb-0"><strong>Name:</strong>  7th Avenue Surgery</p></Col>
      <Col md={4}><p className=" fw-normal color-black f-18  mb-0"><strong>Pay Period End Date :</strong>  1/15/2022</p></Col>
      </Row> */}
      </Col>
     </Row>
     <Row>
     <Col md={12} className='mt-5'>
     <h4 className='justify-content-center'> Answer the following based on the most recent rolling - 4 pay period</h4>
     <div className='scroll-area mt-4'>
      <div className='qus-ans'>
        <p className='qus ms-0 mb-3'>1 :- What obstacles are preventing you from meeting your productive FTE Budget?</p>
        <div className='ans'>
        <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control as="textarea" rows={5} className='w-100 text-start'/>
      </Form.Group>
    </Form>
        </div>
      </div>
      <div className='qus-ans'>
        <p className='qus ms-0 mb-3'>2 :- What obstacles are preventing you from meeting your productive FTE Budget?</p>
        <div className='ans'>
        <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control as="textarea" rows={5} className='w-100 text-start'/>
      </Form.Group>
    </Form>
        </div>
      </div>
      <div className='qus-ans'>
        <p className='qus ms-0 mb-3'>3 :- What obstacles are preventing you from meeting your productive FTE Budget?</p>
        <div className='ans'>
        <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control as="textarea" rows={5} className='w-100 text-start'/>
      </Form.Group>
    </Form>
        </div>
      </div>
      <div className='qus-ans'>
        <p className='qus ms-0 mb-3'>4 :- What obstacles are preventing you from meeting your productive FTE Budget?</p>
        <div className='ans'>
        <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control as="textarea" rows={5} className='w-100 text-start'/>
      </Form.Group>
    </Form>
        </div>
      </div>
     </div>
     </Col>


     </Row>
      </Modal.Body>
    </Modal>
  );
}

export default InfoModal

