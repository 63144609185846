
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";
import Overtime from '../../Overtime';

function OvertimeModal(props) {
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className='missed-meal-break'
  >
    <Modal.Header closeButton className='custom-font-size'>
   
    </Modal.Header>
    <Modal.Body className='custom-font-size text-indent-15'>
     <Overtime>

     </Overtime>
    </Modal.Body>
    
  </Modal>
  )
}

export default OvertimeModal

