import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/shared/StopLossChart/howtouse";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import ADMImage from "../../../assets/img/biweekly_report.png";
import Button from "@atlaskit/button";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import LineChart from "../../../components/shared/StopLossChart/lineChart";
import BarChart from "../../../components/shared/StopLossChart/barChart";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

function StopLoss({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");

  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);

  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);

  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);

  const [isDropdownOpenfour, setIsDropdownOpenFour] = useState(false);
  const [dropdownValueFour, setDropdownValueFour] = useState(undefined);

  const [isDropdownOpenfive, setIsDropdownOpenFive] = useState(false);
  const [dropdownValueFive, setDropdownValueFive] = useState(undefined);

  const [isDropdownOpensix, setIsDropdownOpenSix] = useState(false);
  const [dropdownValueSix, setDropdownValueSix] = useState(undefined);

  const [isDropdownOpenseven, setIsDropdownOpenSeven] = useState(false);
  const [dropdownValueSeven, setDropdownValueSeven] = useState(undefined);

  const [selectedOption, setSelectedOption] = useState(null);

  const [costCenterList, setCostCenterList] = useState([
    {
      label: "100061102 - 4 WEST",
      value: "100061102 - 4 WEST",
    },
  ]);
  const [vpList, setvpList] = useState([
    {
      label: "VP NAME 1",
      value: "VP NAME 1",
    },
  ]);
  const [executiveList, setexecutiveList] = useState([
    {
      label: "Executive 1",
      value: "Executive 1",
    },
  ]);
  const [directorList, setdirectorList] = useState([
    {
      label: "Director 1",
      value: "Director 1",
    },
  ]);
  const [managerList, setmanagerList] = useState([
    {
      label: "Manager 1",
      value: "Manager 1",
    },
  ]);


  const [filterDateList, setFilterDateList] = useState([
    {
      label: "08/10/2024",
      value: "08/10/2024",
    },
  ]);
   const [yearDateList, setYearDateList] = useState([
     {
       label: "2024",
       value: "2024",
     },
   ]);

  const getTableData = async () => {
    setIsTableLoading(true);
    const parData = {};
    parData.page = page;
    // parData.limit = 25;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data?.data?.cost_centers);

      if (data?.data?.hasNextPage) {
        getTableNextData(data?.data?.nextPage);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getTableNextData = async (pageIn) => {
    const parData = {};
    // parData.limit = 500;
    parData.page = pageIn;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setTableData((prevData) => [
        ...prevData,
        ...(data?.data?.cost_centers || []),
      ]);

      if (data?.data?.hasNextPage) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setTimeout(() => {
        getTableNextData(data?.data?.nextPage);
        // }, 1000);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const fetchDataRecursive = async (
    currentPage = page,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
    if (initialCall) {
      setIsTableLoading(true);
    }

    const parData = {
      page: currentPage,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    if (limit) {
      parData.limit = limit;
    }

    if (buisnessCategory) {
      parData.businessUnit = buisnessCategory;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.cost_centers || [];

    if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
      setTableData(costCenters);
    } else {
      setTableData((prevData) => [...prevData, ...costCenters]);
    }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const getHeadData = async () => {
    setIsLoading(true);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getheaderData`
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`
      );
      const data = decryptedFunction(response?.data?.responseData);

      // setCostCenterList(
      //   data?.data?.costCenterResponse.map((item) => {
      //     return {
      //       label: `${item.cost_center_number} - ${capitalizeAllLetters(
      //         item.cost_center_name
      //       )}`,
      //       value: item.cost_center_slug,
      //     };
      //   })
      // );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    setClickCount(0);
    getMetaData();
    fetchDataRecursive();
    getHeadData();
    // getCostCenterList();
    getRefreshSchedule();
    getBuisnesUnit();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {

    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);


    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 105,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    //  setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      if (key !== "") {
        fetchDataRecursive(page, true, key, 1000, newCancelToken.token);
      } else {
        fetchDataRecursive(page, true, key, null, newCancelToken.token);
      }

      // const { data } = await axios.post(
      //   `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      //   parData
      // );

      // setTableData(data.data?.cost_centers);

      // if (data?.data?.hasNextPage) {
      //   getTableNextData(data?.data?.nextPage);
      // }
      // setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

 

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };


  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }

  }, [isBuisnessUnitSet]);


  useEffect(() => {
    if (mounted) {
      fetchDataRecursive();
    } else {
      setMounted(true);
    }
  }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

    const toggleOpen = () => {
      setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
    };

    const toggleOpenTwo = () => {
      setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
    };

    const toggleOpenThree = () => {
        setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenFour = () => {
        setIsDropdownOpenFour((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenFive = () => {
        setIsDropdownOpenFive((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenSix = () => {
        setIsDropdownOpenSix((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenSeven = () => {
        setIsDropdownOpenSeven((prevIsOpen) => !prevIsOpen);
      };

    useEffect(() => {}, [isDropdownOpen, dropdownValue]);

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        {/* <Row className="gx-2 mt-0 align-items-center">
          <Col className="d-flex " md={{ span: 2 }}>
            
          </Col>
          <Col md={{ span: 8 }}>
            <Row className="mt-2 justify-content-center">
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
            </Row>
          </Col>
         

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row> */}

        <Row className="gx-2  mt-2 mb-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 9 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
          
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      100061102 - 4 WEST
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueFour?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={costCenterList}
                  />
                </CustomDropdown>
              </div>
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      08/10/2024
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValueTwo(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      2024
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div>
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfive}
                  onClose={toggleOpenFive}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFive}
                      isSelected={isDropdownOpenfive}
                    >
                      Executive
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfive}
                    onChange={(e) => {
                        toggleOpenFive();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueFive?.value);
                    }}
                    options={executiveList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={executiveList}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfour}
                  onClose={toggleOpenFour}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFour}
                      isSelected={isDropdownOpenfour}
                    >
                      VP
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfour}
                    onChange={(e) => {
                        toggleOpenFour();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueFour?.value);
                    }}
                    options={vpList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={vpList}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpensix}
                  onClose={toggleOpenSix}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSix}
                      isSelected={isDropdownOpensix}
                    >
                      Director
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpensix}
                    onChange={(e) => {
                        toggleOpenSix();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueSix?.value);
                    }}
                    options={directorList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={directorList}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenseven}
                  onClose={toggleOpenSeven}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSeven}
                      isSelected={isDropdownOpenseven}
                    >
                      Manager
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenseven}
                    onChange={(e) => {
                        toggleOpenSeven();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueSeven?.value);
                    }}
                    options={managerList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={managerList}
                  />
                </CustomDropdown>
              </div>

             
            </div>
          </Col>
          <Col md={2} className="text-end mobile-center mobile-my-2">
            <small
              className="cursor-pointer border-btn f-12  green-b"
              onClick={() => {
                setModalShow(true)
                // activityLogbutton();
              }}
            >
             How to use this page?{" "}
            </small>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

      

        <Row className="gx-2">
          <Col md={6} className="mt-2 h-690">
            <div className="bg-white p-3 h-100">

            <div class="text-center mb-2"><div class="box-heading text-center">Stop Loss Report Rolling - 4</div></div>

              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table first-t "
              >
                <thead className="">
                  <tr className="vertical-align-middle">
                    <th >
                    <div className="d-flex justify-content-center align-items-center">
                        <div>Cost Center</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>

                    </th>
                    <th >
                    <div className="d-flex justify-content-center align-items-center">
                        <div>Current Period</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                      </th>
                    <th >
                    <div className="d-flex justify-content-center align-items-center">
                        <div>Prior Period</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th >
                        <div className="d-flex justify-content-center align-items-center">
                        <div>Rolling-4</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                       </th>
                    <th ><div className="d-flex justify-content-center align-items-center">
                        <div>Performance<br/>Percentage</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                      </th>
                  </tr>
               
                </thead>

                <tbody className="ftr">
                  <tr>
                    <td className="text-center">PP24</td>
                    <td className="text-center">7/2/2024</td>
                    <td className=" text-center">17</td>
                    <td className=" text-center">-317.1</td>
                    <td className="text-center">12</td>
                    
                  </tr>
                  <tr>
                    <td className="text-center">PP24</td>
                    <td className="text-center">7/2/2024</td>
                    <td className=" text-center">17</td>
                    <td className=" text-center">-317.1</td>
                    <td className="text-center">12</td>
                  
                  </tr>
                  <tr>
                    <td className="text-center">PP24</td>
                    <td className="text-center">7/2/2024</td>
                    <td className=" text-center">17</td>
                    <td className=" text-center">-317.1</td>
                    <td className="text-center">12</td>
                   
                  </tr>
                </tbody>
                <tbody className="ftr">
                 
                    <tr className="bt">
                      <td className="text-center">
                        <strong>Total</strong>
                      </td>
                      <td className="text-center">
                        <strong>
                         
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                        51
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                        -932.4
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                        36
                        </strong>
                      </td>
                    </tr>
               
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div className="border-bottom-5 h-half pt-3">
            <div class="text-center mb-2"><div class="box-heading text-center">Rolling - 4 Pay Period Opportunity</div></div>
            
            <LineChart
                // title={"All Cost Center : Actual And Target Worked FTE Trends"}

                color={"#458FFF"}
              />
            </div>
            <div className="border-top-5 h-half ">
            <div class="text-center mb-2 mt-3"><div class="box-heading text-center">Cost Center With Rolling - 4 Opportunity > $15k</div></div>
            <BarChart
                // title={"All Cost Center : Actual And Target Worked FTE Trends"}

                color={"#458FFF"}
              />
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default StopLoss;
