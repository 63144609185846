import React, { useState, useEffect, useContext } from "react";
import ContentLoader from "react-content-loader";
import ReactApexChart from "react-apexcharts";
import { setExceptionAge, setExceptionIndex } from "../../store/actions/Compensate";
import { ThemeContext } from "../../providers/ThemeProvider";
import { useSelector, useDispatch } from "react-redux";

const GraphLoader = () => (
    <ContentLoader viewBox="0 0 280 150">
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
    </ContentLoader>
);
const AgeChart = ({ data, chartColor, chartName, indexNum, tooltip, maxData }) => {
    const dispatch = useDispatch();
    const [themeMode, setTheme] = useContext(ThemeContext);


    const [isGraphLoading, setIsGraphLoading] = useState(true);
    const [passData, setpassData] = useState("");
    const [maxGraph, setMaxGraph] = useState(maxData !== undefined && maxData);
    const [chartColorOnCLick, setChartColorOnClick] = useState(chartColor);
    const [activeDataPointIndex, setActiveDataPointIndex] = useState(null);
    const [hasReloaded, setHasReloaded] = useState(false);

    const handleClick = (event, chartContext, config) => {
        if (config.dataPointIndex !== undefined) {
            config.w.config.colors = "#b3172b";
            config.w.config.chart.selection.fill.color = "#b3172b";
            // config.w.config.chart.selection.fill.color = "#b3172b";
            const clickedDataPoint = data[config?.dataPointIndex];
            if (clickedDataPoint) {
                const clickedAgeStart = clickedDataPoint?.start;
                const clickedAgeEnd = clickedDataPoint?.end;
                dispatch(setExceptionAge({ start: clickedAgeStart, end: clickedAgeEnd }));
                dispatch(setExceptionIndex(config.dataPointIndex));
                setActiveDataPointIndex(config.dataPointIndex);
            }
        }
    };

    const [chartData, setChartData] = useState({
        series: [
            {
                name: "chartName",
                data: data.map((dataPoint) => ({
                    x: dataPoint.ppe_date, // Use date as it is
                    y: dataPoint.count,
                })),
            },
        ],
        options: {
            chart: {
                background: themeMode.theme === "DARK" ? "#191C24" : "white", // Set the background color here
                type: "bar",
                stacked: false,
                // stacked: true,
                toolbar: {
                    show: false
                },
                events: {
                    // click: function (event, chartContext, config) {
                    //   console.log("helo Moto", event);
                    //   console.log("helo Moto", chartContext);
                    //   console.log("helo Moto", config);
                    //   const clickedDataPoint = data[config.dataPointIndex];
                    //   if (clickedDataPoint) {
                    //     const clickedDate = clickedDataPoint.ppe_date;
                    //     // setClickedDate(clickedDate);
                    //     console.log("Bar Clicked: ", clickedDate);
                    //   }
                    //   // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                    // },
                    dataPointSelection: handleClick,
                },
                // zoom: {
                //   enabled: true,
                // },
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        height: "310",

                    },
                    breakpoint: 700,
                    options: {
                        height: "410",

                    },

                }
            ],
            states: {
                normal: {
                    filter: {
                        type: "none",
                    },
                },
                hover: {
                    filter: {
                        type: "none",
                        // value: 0.15,
                    },
                },
                active: {
                    // allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        // value: 0.35,
                    },
                },
            },

            plotOptions: {
                bar: {
                    columnWidth: '70%',
                    barHeight: '70%',
                    dataLabels: {
                        position: "top", // top, center, bottom

                    },
                },

            },
            dataLabels: {
                enabled: true,
                hideOverflowingLabels: true,
                formatter: function (val) {
                    // return val + "%";
                    return val === 0 ? "" : val;
                },
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
                    padding: {
                        top: 30
                    },
                },

            },
            stroke: {
                colors: ["transparent"],
                width: 2,
            },

            xaxis: {
                categories: data.map((dataPoint) => dataPoint.ppe_date), // Use date as it is
                position: "bottom",
                axisBorder: {
                    show: true,
                },
                axisTicks: {
                    show: true,
                },
                crosshairs: {
                    fill: {
                        // type: "gradient",
                        gradient: {
                            // colorFrom: "#D8E3F0",
                            // colorTo: "#BED1E6",
                            // stops: [0, 100],
                            // opacityFrom: 0.4,
                            // opacityTo: 0.5,
                        },
                    },
                },

                labels: {
                    style: {
                        fontSize: "12px", // Reduce the font size as needed
                        colors: themeMode.theme === "DARK" ? "white" : "black",
                    },
                    // rotate: -25,
                    // rotateAlways: true,
                    hideOverlappingLabels: false,
                    showDuplicates: true,
                    offsetY: 0,
                    maxHeight: 80,
                    show: true,
                },
            },

            yaxis: {
                labels: {
                    style: {
                        fontSize: "12px", // Reduce the font size as needed
                        colors: themeMode.theme === "DARK" ? "white" : "black",
                    },
                },
            },

            title: {
                text: 'chartName',
                floating: true,
                offsetY: -8,
                align: "center",
                style: {
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "F37 Moon",
                },
            },
            grid: {
                show: false, // Remove both x-axis and y-axis grid lines
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class="arrow_box">' +
                        tooltip +
                        " : "
                        +
                        series[seriesIndex][dataPointIndex] +
                        "</span>" +
                        "</div>"
                    );
                }
            },

            colors: [chartColor],
        },
    });



    // ===========================For activedailymanagement bar graph =====================================

    const options = {
        chart: {
            background: themeMode.theme === "DARK" ? "#191C24" : "white", // Set the background color here
            type: "bar",
            stacked: maxGraph < 15 ? true : false,
            // stacked: true,
            toolbar: {
                show: false
            },
            events: {
                // click: function (event, chartContext, config) {
                //   console.log("helo Moto", event);
                //   console.log("helo Moto", chartContext);
                //   console.log("helo Moto", config);
                //   const clickedDataPoint = data[config.dataPointIndex];
                //   if (clickedDataPoint) {
                //     const clickedDate = clickedDataPoint.ppe_date;
                //     // setClickedDate(clickedDate);
                //     console.log("Bar Clicked: ", clickedDate);
                //   }
                //   // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                // },
                dataPointSelection: handleClick,
            },
            zoom: {
                enabled: true,
            },
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    height: "310",

                },
                breakpoint: 700,
                options: {
                    height: "410",

                },

            }
        ],
        states: {
            normal: {
                filter: {
                    type: "none",
                },
            },
            hover: {
                filter: {
                    type: "none",
                    // value: 0.15,
                },
            },
            active: {
                // allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    // value: 0.35,
                },
            },
        },
        // chart: {
        //   //  height: 310,
        //   //   type: "bar",
        //   sparkline: {
        //     enabled: true
        //  },
        //   toolbar: {
        //     show: false,
        //   },
        // },
        plotOptions: {
            bar: {
                columnWidth: '70%',
                barHeight: '70%',
                dataLabels: {
                    position: "top", // top, center, bottom

                },
            },

        },
        dataLabels: {
            enabled: true,
            hideOverflowingLabels: true,
            formatter: function (val) {
                // return val + "%";
                return val === 0 ? "" : val;
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
                padding: {
                    top: 30
                },
            },

        },
        stroke: {
            colors: ["transparent"],
            width: 2,
        },

        xaxis: {
            categories: data.map((dataPoint) => dataPoint.ppe_date), // Use date as it is
            position: "bottom",
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            crosshairs: {
                fill: {
                    // type: "gradient",
                    gradient: {
                        // colorFrom: "#D8E3F0",
                        // colorTo: "#BED1E6",
                        // stops: [0, 100],
                        // opacityFrom: 0.4,
                        // opacityTo: 0.5,
                    },
                },
            },

            labels: {
                style: {
                    fontSize: "12px", // Reduce the font size as needed
                    colors: themeMode.theme === "DARK" ? "white" : "black",
                },
                // rotate: -25,
                // rotateAlways: true,
                hideOverlappingLabels: false,
                showDuplicates: true,
                offsetY: 0,
                maxHeight: 80,
                show: true,
            },

        },

        yaxis: {
            labels: {
                style: {
                    fontSize: "12px", // Reduce the font size as needed
                    colors: themeMode.theme === "DARK" ? "white" : "black",
                },
            },
        },

        title: {
            text: chartName,
            floating: true,
            offsetY: -8,
            align: "center",
            style: {
                color: themeMode.theme === "DARK" ? "white" : "#4B4D4D",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "F37 Moon",
            },
        },
        grid: {
            show: true, // Remove both x-axis and y-axis grid lines
            borderColor:  themeMode.theme === "DARK" ? "#4f4f4f" : "#d3d3d3",
            // "#4f4f4f",
        
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    '<div class="arrow_box">' +
                    tooltip +
                    " : "
                    +
                    series[seriesIndex][dataPointIndex] +
                    "</span>" +
                    "</div>"
                );
            }
        },

        colors: [chartColor],
    }

    const series = [
        {
            name: "chartName",
            data: data.map((dataPoint) => ({
                x: dataPoint.ppe_date, // Use date as it is
                y: dataPoint.count,
            })),
        },
    ]


    useEffect(() => {
        const fetchData = async () => {
            setIsGraphLoading(false);
        };
        fetchData();
    }, []);

    if (isGraphLoading) {
        return <GraphLoader />;
    }

    // if (!data || !Array.isArray(data) || data.length === 0) {
    //   return <GraphLoader />;
    // }

    return (
        <>
            <div id="chart" className="yh">
                <ReactApexChart
                    options={options}
                    series={[
                        {
                            name: chartName,
                            data: data.map((dataPoint, index) => ({
                                x: dataPoint.ppe_date,
                                y: dataPoint.count,
                                fillColor: 
                                index === indexNum ?
                                dataPoint?.selected_color :
                                dataPoint.color
                            })),
                        },
                    ]}
                    // series={chartData.series}
                    type="bar"
                    height="100%"
                />
            </div>
        </>
    );
};

export default AgeChart;
