import BedIcon from "../assets/img/bed-tb.png";
import AcuityIcon from "../assets/img/acuity.png";
import ChargeIcon from "../assets/img/charge.png";
import OrientationIcon from "../assets/img/orientation.png";
import NurseIcon from "../assets/img/nurse.png";
import ReseIcon from "../assets/img/res-brk-en.png";
import UnitIcon from "../assets/img/unit_sec.png";
import SitterIcon from "../assets/img/sitter.png";
import AdmitsIcon from "../assets/img/admits.png";
import DischargesIcon from "../assets/img/discharges.png";
import TpatientsIcon from "../assets/img/telemetry_patients.png";
import DripsIcon from "../assets/img/drips.png";
import TcnaIcon from "../assets/img/shiftIcons/cnaIcon.png";
import hashIcon from "../assets/img/shiftIcons/hashIcon.png";
import monitorIcon from "../assets/img/shiftIcons/monitorIcon.png";
import otherIcon from "../assets/img/shiftIcons/otherIcon.png";
import rrsIcon from "../assets/img/shiftIcons/rrsIcon.png";
import varianceIcon from "../assets/img/shiftIcons/varianceIcon.png";
import workerIcon from "../assets/img/shiftIcons/workerIcon.png";
import twoOneIcon from "../assets/img/shiftIcons/twoOneIcon.png";
import oneOneIcon from "../assets/img/shiftIcons/oneOneIcon.png";
import oneTwoIcon from "../assets/img/shiftIcons/oneTwoIcon.png";
import oneThreeIcon from "../assets/img/shiftIcons/oneThreeIcon.png";
import oneFourIcon from "../assets/img/shiftIcons/oneFourIcon.png";
import oneFiveIcon from "../assets/img/shiftIcons/oneFiveIcon.png";
import oneSixIcon from "../assets/img/shiftIcons/oneSixIcon.png";
import obsIcon from "../assets/img/shiftIcons/obsIcon.png";

// ----------------------Dark View Icon
import BedIcon1 from "../assets/img/bed-tb1.png";
import AcuityIcon1 from "../assets/img/acuity1.png";
import ChargeIcon1 from "../assets/img/charge1.png";
import CnaIcon1 from "../assets/img/cna1.png";
import OrientationIcon1 from "../assets/img/orientation1.png";
import NurseIcon1 from "../assets/img/nurse1.png";
import ReseIcon1 from "../assets/img/res-brk-en1.png";
import UnitIcon1 from "../assets/img/unit_sec1.png";
import SitterIcon1 from "../assets/img/sitter1.png";
import AdmitsIcon1 from "../assets/img/admits1.png";
import DischargesIcon1 from "../assets/img/discharges1.png";
import TpatientsIcon1 from "../assets/img/telemetry_patients1.png";
import DripsIcon1 from "../assets/img/drips1.png";
import IsolationLight from "../assets/img/Isolation_light.png";
import IsolationDark from "../assets/img/Isolation_dark.png";
import TcnaIcon1 from "../assets/img/shiftIcons/cnaIcon1.png";
import hashIcon1 from "../assets/img/shiftIcons/hashIcon1.png";
import monitorIcon1 from "../assets/img/shiftIcons/monitorIcon1.png";
import otherIcon1 from "../assets/img/shiftIcons/otherIcon1.png";
import rrsIcon1 from "../assets/img/shiftIcons/rrsIcon1.png";
import varianceIcon1 from "../assets/img/shiftIcons/varianceIcon1.png";
import workerIcon1 from "../assets/img/shiftIcons/workerIcon1.png";
import twoOneIcon1 from "../assets/img/shiftIcons/twoOneIcon1.png";
import oneOneIcon1 from "../assets/img/shiftIcons/oneOneIcon1.png";
import oneTwoIcon1 from "../assets/img/shiftIcons/oneTwoIcon1.png";
import oneThreeIcon1 from "../assets/img/shiftIcons/oneThreeIcon1.png";
import oneFourIcon1 from "../assets/img/shiftIcons/oneFourIcon1.png";
import oneFiveIcon1 from "../assets/img/shiftIcons/oneFiveIcon1.png";
import oneSixIcon1 from "../assets/img/shiftIcons/oneSixIcon1.png";
import obsIcon1 from "../assets/img/shiftIcons/obsIcon1.png";



const VALIDATION_NAMES = {
  acuity: "Acuity",
  wit: "WIT",
  two_one: "Patient Acuity (2:1)",
  one_one: "Patient Acuity (1:1)",
  one_two: "Patient Acuity (1:2)",
  one_three: "Patient Acuity (1:3)",
  one_four: "Patient Acuity (1:4)",
  one_five: "Patient Acuity (1:5)",
  one_six: "Patient Acuity (1:6)",
  charge: "Charge",
  cna: "CNA",
  orientation: "Orientation",
  nurses: "RN",
  res_brk_rn: "Res/Brk RN",
  unit_sec: "Unit Sec",
  sitter: "Sitter",
  ant_admit: "Anticipated Admits/Transfers in this Shift",
  actual_admit: "Actual Admits/Transfers in previous Shift",
  telemetry: "Telemetry Patients",
  vaso_active_drips: "Vaso-Active Drips",
  isolation_patients: "Isolation Patients",
  comment: "Comments / Barriers / Action Plan",
  of_isolation: "of Isolation (Contact or Contact+)",
  of_rrs_codes: "of RRs / Codes",
  of_video_monitors: "of Video Monitors",
  techs_cna: "Techs / CNA",
  psa_si: "PSA - S.I",
  psa_medical: "PSA - Medical",
  blt: "BLT",
  other: "Other",
  pt_type1_0_20: "Pt Type 1 (0-20)",
  pt_type2_21_28: "Pt Type 2 (21-28)",
  pt_type3_29_68: "Pt Type 3 (29-68)",
  labor_patients_recovery: "Labor Patients / Recovery",
  nicu_cpap: "NICU CPAP",
  nicu_feeder_growers: "NICU Feeder Growers",
  post_op_pp_one_two: "Post-op PP 1:2",
  stable_pp_one_three: "Stable PP 1:3",
  soc_worker_ft: "Soc Worker (FT)",
  soc_worker_pt: "Soc Worker (PT)",
  occ_therapist: "Occ Therapist",
  pp_rn: "PP RN",
  l_and_d_rn: "L&D RN",
  nicu_peds_rn: "NICU / Peds RN",
  triage_rn: "Triage RN",
  triage_rn: "Triage RN",
  submitted_by_text: "Submitted By",
  total_patients_two: "Total Patients Check",
  total_patients_three: "Total Patients Manual",
  observation_patients: "Observation Patients",
  inpatient_boarders: "Inpatient Boarders",
  of_patients_w_video_monitor_last_six_hours:
    "# of Patients w/ Video Monitor - Last 6 Hours",
  of_patients_in_seclusion_last_six_hours:
    "# of Patients in Seclusion - last 6 Hours",
  of_patients_in_restraints_last_six_hours:
    "# of Patients in Restraints - Last 6 Hours",
  of_icu_patients_waiting_in_er: "# of ICU patients waiting in ER",
  of_anticipated_transfers_in_next_four_hours:
    "# of Anticipated Transfers IN (Next 4 Hours)",
  of_anticipated_transfers_out_next_four_hours:
    "# of Anticipated Transfers OUT (Next 4 Hours)",
  of_rns_wa_wit_higher_than_shift_standard:
    "# of RNs w/ a WIT higher than Shift Standard",
  of_pcu_pts_waiting_in_er: "# of PCU Pts Waiting in ER",
  of_c_sections_since_last_time_stamp: "# of C-Sections since last time stamp",
  of_inductions_since_last_time_stamp: "of Inductions since last time stamp",
  of_births_since_last_time_stamp: "# of Births since last time stamp",
  of_triage_patients_since_last_time_stamp:
    "# of Triage Patients Since Last Time Stamp",
  of_pp_hemorrhage_ob_emergency: "# of PP Hemorrhage / OB Emergency",
  of_resuscitations: "# of Resuscitations",
  of_op_dialysis_patients: "# of OP Dialysis Patients",
  total_uos: "Total UOS",
  of_cardiac_procedures: "Of Cardiac Procedures",
  front_desk_reg: "Front Desk Reg",
  ivs_encounters_processed: "IVS Encounters Processed",
  pts_scheduled: "Pts Scheduled",
  new_referral: "New Referral",
  work_queue_calls: "Work Queue Calls",
  procedures: "Procedures",
  echo: "Echo",
  dobutamine_stress_echo: "Dobutamine Stress Echo",
  stress_echo: "Stress Echo",
  transesopageal_echo: "Transesopageal Echo",
  gi: "GI",
  ercp: "ERCP",
  bronchoscopy: "Bronchoscopy",
  planned_procedures: "Planned Procedures",
  of_minutes: "# of Minutes",
  of_cases: "# of Cases",
  items: "Items",
  unprocessed_trays: "Unprocessed trays",
  cases_scheduled_today: "Cases scheduled today",
  cases_picked: "Cases picked",
  actual_minutes: "Actual Minutes",
  planned_minutes: "Planned Minutes",
  iol_pain_local_cases: "IOL, Pain, Local Cases",
  mac_cases: "MAC Cases",
  pacu_cases: "PACU Cases",
  cath_lab_cases: "Cath Lab Cases",
  in_clinic_visits: "In-Clinic Visits",
  level_1_visits: "Level 1 Visits",
  level_2_visits: "Level 2 Visits",
  level_3_visits: "Level 3 Visits",
  level_4_visits: "Level 4 Visits",
  level_5_visits: "Level 5 Visits",
  of_roomed_er_patients: "# of Roomed ER Patients",
  psych_pts_awaiting_placement: "Psych Pts Awaiting Placement",
  capped_pts_awaiting_hospitalist_assignment:
    "Capped Pts awaiting hospitalist assignment",
  admits_awaiting_bed_assignment_boarders:
    "Admits awaiting bed assignment (boarders)",
  pts_in_waiting_room: "Pts in waiting room",
  cx_no_shows_w_i_24_hrs: "# cx/no shows w/i 24 hrs",
  downtime_hrs: "# downtime hrs",
  hrs_non_cath_proc: "# hrs non cath proc",
  of_proc_cx_no_shows_w_i_24_hrs: "# of proc cx/no shows w/i 24 hours",
  hrs_equip_down: "# hrs equip down",
  on_call_hrs_paid: "# on-call hrs paid",
  times_called_in: "# times called in",
  of_hrs_in_surgery: "# of hrs in surgery",
  active_work_queue: "Active Work Queue",
  total_work_queue: "Total Work Queue",
  uncontacted_referral_grater_7_days: "Uncontacted referral > 7 days",
  surgery_hrs: "Surgery Hours",
  fluoro_hrs: "Fluoro Hours",
  ip_same_day_add_ons: "# IP/ same day add-ons",
  actual_rn_worked_hrs: "Actual RN worked hrs",
  uae_echos: "# UEA Echos",
  providers_out: "# Providers Out",
  cancellations_within_24_hrs: "# Cancellations within 24 hrs",
  ip_add_ons: "# IP add-ons",
  dac_hrs_spent: "#DAC hrs spent",
  no_shows: "# no shows",
  cx_w_i_24_hrs: "# cx w/I 24 hrs",
  patients_w_stay_grater_70_min: "# patients w/ stay > 70 min",
  crash_carts_set_up_restocked: "# Crash Carts Set-up/ restocked",
  instruments_returned_unused: "# instruments returned unused",
  flashed_items: "# flashed items",
  procedures_requiring_staff_grater_normal_ortho:
    "# procedures requiring staff > normal ORTHO",
  procedures_requiring_grater_normal_plastics:
    "# procedures requiring > normal PLASTICS",
  procedures_requiring_grater_normal_for_ophthamolgy:
    "# procedures requiring > normal for OPHTHAMOLGY",
  procedures_requiring_grater_normal_general_baritics_other:
    "# procedures requiring > normal GENERAL/BARIATICS/OTHER",
  ip_status_patients_held_over: "# IP status patients held over",
  of_hours_the_rcu_was_open_last_4_hrs:
    "# of hours the RCU was open last 4 hrs",
  one_1_and_1_2_pts_currently_awaiting_placement:
    "1:1 and 1:2 pts currently awaiting placement",
  code_3s_stemi_or_strokes_last_4_hours:
    "Code 3s, Stemi or Strokes last 4 hours",
  of_psa_si_pts: "# of PSA-SI Pts",
  of_lost_doses: "Active cases in the work queue",
  active_cases_in_the_work_queue: "# of PSA-SI Pts",
  of_ir_vas_procedures: "# of IR/Vas Procedures",
  in_chair_minutes: "In-Chair Minutes",
  pain_management: "Pain Management",
  hi_flow_in_us: "# Hi Flow in use",
  of_vents_in_use: "# of Vents in use",
  of_bi_paps_in_use: "# of Bi-Paps in use",
  of_c_paps_in_use_noc_only: "# of C-Paps in use - NOC only",
  of_loaner_trays_processed: "# of Loaner trays processed",
  lab_technologist: "Lab Technologist",
  phlebotomist_clinical_services: "Phlebotomist/ Clinical services",
  education_orientation: "Education/ Orientation",
  contract_labor_tech: "Contract labor tech",
  contract_labor_phlebotomy: "Contract labor phlebotomy",
  histology: "Histology",
  client_services: "Client services ",
  receptionist: "Receptionist",
  health_plan_coordinator: "Health Plan Coordinator",
  medical_assistant: "Medical Assistant",
  lead_medical_assistant: "Lead Medical Assistant",
  radiologist_technologist: "Radiologist Technologist",
  athletic_trainer: "Athletic Trainer",
  urgent_cases_added_to_schedule: "# Urgent Cases Added to Schedule",
  unplanned_providers_absent: "# Unplanned Providers Absent",
  no_show_patients_same_day_cancelation:
    "# No-show Patients / Same day cancelation",
};

const PATIENT_ICON_LIGHT = {
  acuity: AcuityIcon,
  total_patients: BedIcon,
  total_patients_two: BedIcon,
  total_patients_three: BedIcon,
  two_one: twoOneIcon,
  one_one: oneOneIcon,
  one_two: oneTwoIcon,
  one_three: oneThreeIcon,
  one_four: oneFourIcon,
  one_five: oneFiveIcon,
  one_six: oneSixIcon,
  wit: AcuityIcon,
  wit_rn: AcuityIcon,
  rn_available_hours: varianceIcon,
  rn_variance: varianceIcon,
  observation_patients: obsIcon,
  inpatient_boarders: BedIcon,
  pt_type1_0_20: BedIcon,
  pt_type2_21_28: BedIcon,
  pt_type3_29_68: BedIcon,
  labor_patients_recovery: BedIcon,
  nicu_cpap: BedIcon,
  nicu_feeder_growers: BedIcon,
  post_op_pp_one_two: BedIcon,
  stable_pp_one_three: BedIcon,
  pp_rn_variance: varianceIcon,
  l_and_d_rn_variance: varianceIcon,
  nicu_variance: varianceIcon,
  custom_rn_variance: varianceIcon,
};

const DEV_PATIENT_ICON_LIGHT = {
  acuity: AcuityIcon,
  total_patients: BedIcon,
  total_patients_two: BedIcon,
  total_patients_three: BedIcon,
  two_one: twoOneIcon,
  one_one: oneOneIcon,
  one_two: oneTwoIcon,
  one_three: oneThreeIcon,
  one_four: oneFourIcon,
  one_five: oneFiveIcon,
  one_six: oneSixIcon,
  wit: AcuityIcon,
  wit_rn: AcuityIcon,
  rn_available_hours: varianceIcon,
  rn_variance: varianceIcon,
  observation_patients: obsIcon,
  inpatient_boarders: BedIcon,
  pt_type1_0_20: BedIcon,
  pt_type2_21_28: BedIcon,
  pt_type3_29_68: BedIcon,
  labor_patients_recovery: BedIcon,
  nicu_cpap: BedIcon,
  nicu_feeder_growers: BedIcon,
  post_op_pp_one_two: BedIcon,
  stable_pp_one_three: BedIcon,
  pp_rn_variance: varianceIcon,
  l_and_d_rn_variance: varianceIcon,
  nicu_variance: varianceIcon,
  custom_rn_variance: varianceIcon,
  total_uos: BedIcon,
  of_cardiac_procedures: BedIcon,
  front_desk_reg: ChargeIcon,
  ivs_encounters_processed: BedIcon,
  pts_scheduled: ChargeIcon,
  new_referral: ChargeIcon,
  work_queue_calls: ChargeIcon,
  procedures: UnitIcon,
  echo: varianceIcon,
  dobutamine_stress_echo: varianceIcon,
  stress_echo: varianceIcon,
  transesopageal_echo: varianceIcon,
  gi: varianceIcon,
  ercp: varianceIcon,
  bronchoscopy: varianceIcon,
  planned_procedures: UnitIcon,
  of_minutes: AcuityIcon,
  of_cases: AcuityIcon,
  items: AcuityIcon,
  unprocessed_trays: BedIcon,
  cases_scheduled_today: BedIcon,
  cases_picked: varianceIcon,
  actual_minutes: AcuityIcon,
  planned_minutes: AcuityIcon,
  iol_pain_local_cases: BedIcon,
  mac_cases: BedIcon,
  pacu_cases: BedIcon,
  cath_lab_cases: BedIcon,
  in_clinic_visits: BedIcon,
  level_1_visits: BedIcon,
  level_2_visits: BedIcon,
  level_3_visits: BedIcon,
  level_4_visits: BedIcon,
  level_5_visits: BedIcon,
  of_roomed_er_patients: BedIcon,
  psych_pts_awaiting_placement: BedIcon,
  capped_pts_awaiting_hospitalist_assignment: BedIcon,
  admits_awaiting_bed_assignment_boarders: BedIcon,
  pts_in_waiting_room: BedIcon,
  calculated_total_registrations: BedIcon,
  calculated_units_of_service: BedIcon,
  calculated_procedures_1: UnitIcon,
  calculated_procedures_2: UnitIcon,
  calculated_items: BedIcon,
  calculated_minutes: OrientationIcon,
  seven_ave_reg_patient: BedIcon,
  ed_reg_patient: BedIcon,
  iol_mac_pacu_minutes: OrientationIcon,
  uos_24: BedIcon,
  in_chair_minutes: AcuityIcon,
  pain_management: BedIcon,
};

const PATIENT_ICON_DARK = {
  acuity: AcuityIcon1,
  total_patients: BedIcon1,
  total_patients_two: BedIcon1,
  total_patients_three: BedIcon1,
  two_one: twoOneIcon1,
  one_one: oneOneIcon1,
  one_two: oneTwoIcon1,
  one_three: oneThreeIcon1,
  one_four: oneFourIcon1,
  one_five: oneFiveIcon1,
  one_six: oneSixIcon1,
  wit: AcuityIcon1,
  wit_rn: AcuityIcon1,
  rn_available_hours: varianceIcon1,
  rn_variance: varianceIcon1,
  observation_patients: obsIcon1,
  inpatient_boarders: BedIcon1,
  pt_type1_0_20: BedIcon1,
  pt_type2_21_28: BedIcon1,
  pt_type3_29_68: BedIcon1,
  labor_patients_recovery: BedIcon1,
  nicu_cpap: BedIcon1,
  nicu_feeder_growers: BedIcon1,
  post_op_pp_one_two: BedIcon1,
  stable_pp_one_three: BedIcon1,
  pp_rn_variance: varianceIcon1,
  l_and_d_rn_variance: varianceIcon1,
  nicu_variance: varianceIcon1,
  custom_rn_variance: varianceIcon1,
};

const DEV_PATIENT_ICON_DARK = {
  acuity: AcuityIcon1,
  total_patients: BedIcon1,
  total_patients_two: BedIcon1,
  total_patients_three: BedIcon1,
  two_one: twoOneIcon1,
  one_one: oneOneIcon1,
  one_two: oneTwoIcon1,
  one_three: oneThreeIcon1,
  one_four: oneFourIcon1,
  one_five: oneFiveIcon1,
  one_six: oneSixIcon1,
  wit: AcuityIcon1,
  wit_rn: AcuityIcon1,
  rn_available_hours: varianceIcon1,
  rn_variance: varianceIcon1,
  observation_patients: obsIcon1,
  inpatient_boarders: BedIcon1,
  pt_type1_0_20: BedIcon1,
  pt_type2_21_28: BedIcon1,
  pt_type3_29_68: BedIcon1,
  labor_patients_recovery: BedIcon1,
  nicu_cpap: BedIcon1,
  nicu_feeder_growers: BedIcon1,
  post_op_pp_one_two: BedIcon1,
  stable_pp_one_three: BedIcon1,
  pp_rn_variance: varianceIcon1,
  l_and_d_rn_variance: varianceIcon1,
  nicu_variance: varianceIcon1,
  custom_rn_variance: varianceIcon1,
  total_uos: BedIcon1,
  of_cardiac_procedures: BedIcon1,
  front_desk_reg: ChargeIcon1,
  ivs_encounters_processed: BedIcon1,
  pts_scheduled: ChargeIcon1,
  new_referral: ChargeIcon1,
  work_queue_calls: ChargeIcon1,
  procedures: UnitIcon1,
  echo: varianceIcon1,
  dobutamine_stress_echo: varianceIcon1,
  stress_echo: varianceIcon1,
  transesopageal_echo: varianceIcon1,
  gi: varianceIcon1,
  ercp: varianceIcon1,
  bronchoscopy: varianceIcon1,
  planned_procedures: UnitIcon1,
  of_minutes: AcuityIcon1,
  of_cases: AcuityIcon1,
  items: AcuityIcon1,
  unprocessed_trays: BedIcon1,
  cases_scheduled_today: BedIcon1,
  cases_picked: varianceIcon1,
  actual_minutes: AcuityIcon1,
  planned_minutes: AcuityIcon1,
  iol_pain_local_cases: BedIcon1,
  mac_cases: BedIcon1,
  pacu_cases: BedIcon1,
  cath_lab_cases: BedIcon1,
  in_clinic_visits: BedIcon1,
  level_1_visits: BedIcon1,
  level_2_visits: BedIcon1,
  level_3_visits: BedIcon1,
  level_4_visits: BedIcon1,
  level_5_visits: BedIcon1,
  of_roomed_er_patients: BedIcon1,
  psych_pts_awaiting_placement: BedIcon1,
  capped_pts_awaiting_hospitalist_assignment: BedIcon1,
  admits_awaiting_bed_assignment_boarders: BedIcon1,
  pts_in_waiting_room: BedIcon1,
  calculated_total_registrations: BedIcon1,
  calculated_units_of_service: BedIcon1,
  calculated_procedures_1: UnitIcon1,
  calculated_procedures_2: UnitIcon1,
  calculated_items: BedIcon1,
  calculated_minutes: OrientationIcon1,
  seven_ave_reg_patient: BedIcon1,
  ed_reg_patient: BedIcon1,
  iol_mac_pacu_minutes: OrientationIcon1,
  uos_24: BedIcon1,
  in_chair_minutes: AcuityIcon1,
  pain_management: BedIcon1,
};

const STAFFING_ICON_LIGHT = {
  charge: ChargeIcon,
  cna: TcnaIcon,
  orientation: OrientationIcon,
  nurses: NurseIcon,
  res_brk_rn: ReseIcon,
  unit_sec: UnitIcon,
  sitter: SitterIcon,
  techs_cna: TcnaIcon,
  psa_si: SitterIcon,
  psa_medical: SitterIcon,
  blt: ReseIcon,
  other: otherIcon,
  soc_worker_ft: workerIcon,
  soc_worker_pt: workerIcon,
  occ_therapist: workerIcon,
  pp_rn: NurseIcon,
  l_and_d_rn: NurseIcon,
  nicu_peds_rn: NurseIcon,
  triage_rn: NurseIcon,
};

const DEV_STAFFING_ICON_LIGHT = {
  charge: ChargeIcon,
  cna: TcnaIcon,
  orientation: OrientationIcon,
  nurses: NurseIcon,
  res_brk_rn: ReseIcon,
  unit_sec: UnitIcon,
  sitter: SitterIcon,
  techs_cna: TcnaIcon,
  psa_si: SitterIcon,
  psa_medical: SitterIcon,
  blt: ReseIcon,
  other: otherIcon,
  soc_worker_ft: workerIcon,
  soc_worker_pt: workerIcon,
  occ_therapist: workerIcon,
  pp_rn: NurseIcon,
  l_and_d_rn: NurseIcon,
  nicu_peds_rn: NurseIcon,
  triage_rn: NurseIcon,
  lead_tech: ChargeIcon,
  clinical_coordinator: workerIcon,
  front_desk: workerIcon,
  ivs: workerIcon,
  ed_reg: workerIcon,
  seven_ave_reg: workerIcon,
  rt: workerIcon,
  support: workerIcon,
  scrub_tech: workerIcon,
  rn_preceptor: NurseIcon,
  rn_board_runner: NurseIcon,
  scrub_tech_preceptor: workerIcon,
  scrub_tech_board_runner: workerIcon,
  rnfa_stfa: otherIcon,
  rcu_rn: NurseIcon,
  lab_technologist: workerIcon,
  phlebotomist_clinical_services: workerIcon,
  education_orientation: workerIcon,
  contract_labor_tech: workerIcon,
  contract_labor_phlebotomy: workerIcon,
  histology: workerIcon,
  client_services: workerIcon,
  receptionist: workerIcon,
  health_plan_coordinator: workerIcon,
  medical_assistant: workerIcon,
  lead_medical_assistant: workerIcon,
  radiologist_technologist: workerIcon,
  athletic_trainer: workerIcon,
};


const STAFFING_ICON_DARK = {
  charge: ChargeIcon1,
  cna: TcnaIcon1,
  orientation: OrientationIcon1,
  nurses: NurseIcon1,
  res_brk_rn: ReseIcon1,
  unit_sec: UnitIcon1,
  sitter: SitterIcon1,
  techs_cna: TcnaIcon1,
  psa_si: SitterIcon1,
  psa_medical: SitterIcon1,
  blt: ReseIcon1,
  other: otherIcon1,
  soc_worker_ft: workerIcon1,
  soc_worker_pt: workerIcon1,
  occ_therapist: workerIcon1,
  pp_rn: NurseIcon1,
  l_and_d_rn: NurseIcon1,
  nicu_peds_rn: NurseIcon1,
  triage_rn: NurseIcon1,
};

const DEV_STAFFING_ICON_DARK = {
  charge: ChargeIcon1,
  cna: TcnaIcon1,
  orientation: OrientationIcon1,
  nurses: NurseIcon1,
  res_brk_rn: ReseIcon1,
  unit_sec: UnitIcon1,
  sitter: SitterIcon1,
  techs_cna: TcnaIcon1,
  psa_si: SitterIcon1,
  psa_medical: SitterIcon1,
  blt: ReseIcon1,
  other: otherIcon1,
  soc_worker_ft: workerIcon1,
  soc_worker_pt: workerIcon1,
  occ_therapist: workerIcon1,
  pp_rn: NurseIcon1,
  l_and_d_rn: NurseIcon1,
  nicu_peds_rn: NurseIcon1,
  triage_rn: NurseIcon1,
  lead_tech: ChargeIcon1,
  clinical_coordinator: workerIcon1,
  front_desk: workerIcon1,
  ivs: workerIcon1,
  ed_reg: workerIcon1,
  seven_ave_reg: workerIcon1,
  rt: workerIcon1,
  support: workerIcon1,
  scrub_tech: workerIcon1,
  rn_preceptor: NurseIcon1,
  rn_board_runner: NurseIcon1,
  scrub_tech_preceptor: workerIcon1,
  scrub_tech_board_runner: workerIcon1,
  rnfa_stfa: otherIcon1,
  rcu_rn: NurseIcon1,
  lab_technologist: workerIcon1,
  phlebotomist_clinical_services: workerIcon1,
  education_orientation: workerIcon1,
  contract_labor_tech: workerIcon1,
  contract_labor_phlebotomy: workerIcon1,
  histology: workerIcon1,
  client_services: workerIcon1,
  receptionist: workerIcon1,
  health_plan_coordinator: workerIcon1,
  medical_assistant: workerIcon1,
  lead_medical_assistant: workerIcon1,
  radiologist_technologist: workerIcon1,
  athletic_trainer: workerIcon1,
};

const STAFFING_GRID_VALUE = {
  charge: "required_charge",
  cna: "required_cna",
  orientation: "orientation",
  nurses: "required_nurses",
  res_brk_rn: "required_res_brk_rn",
  unit_sec: "unit_sec",
  sitter: "sitter",
  techs_cna: "techs_cna",
  psa_si: "psa_si",
  psa_medical: "psa_medical",
  blt: "blt",
  other: "other",
  soc_worker_ft: "soc_worker_ft",
  soc_worker_pt: "soc_worker_pt",
  occ_therapist: "occ_therapist",
  pp_rn: "pp_rn",
  l_and_d_rn: "l_and_d_rn",
  nicu_peds_rn: "nicu_peds_rn",
  triage_rn: "triage_rn",
  lead_tech: "lead_tech",
  clinical_coordinator: "clinical_coordinator",
  front_desk: "front_desk",
  ivs: "ivs",
  ed_reg: "ed_reg",
  seven_ave_reg: "seven_ave_reg",
  rt: "rt",
  support: "support",
  scrub_tech: "scrub_tech",
  rn_preceptor: "rn_preceptor",
  rn_board_runner: "rn_board_runner",
  scrub_tech_preceptor: "scrub_tech_preceptor",
  scrub_tech_board_runner: "scrub_tech_board_runner",
  rnfa_stfa: "rnfa_stfa",
  rcu_rn: "rcu_rn",
  lab_technologist: "lab_technologist",
  phlebotomist_clinical_services: "phlebotomist_clinical_services",
  education_orientation: "education_orientation",
  contract_labor_tech: "contract_labor_tech",
  contract_labor_phlebotomy: "contract_labor_phlebotomy",
  histology: "histology",
  client_services: "client_services",
  receptionist: "receptionist",
  health_plan_coordinator: "health_plan_coordinator",
  medical_assistant: "medical_assistant",
  lead_medical_assistant: "lead_medical_assistant",
  radiologist_technologist: "radiologist_technologist",
  athletic_trainer: "athletic_trainer",
};

const STAFFING_FIXED_POSITION = {
     fixed_position_5_1 : 8,
     fixed_position_5_2 : 8,
     fixed_position_5_3 : 8,
     fixed_position_5_4 : 8,
     fixed_position_5_5 : 4,
     fixed_position_5_5_2 : 4,
     fixed_position_5_8 : 6.4,
     fixed_position_7_1 : 5.71 ,
     fixed_position_7_2 : 5.71 ,
     fixed_position_7_3 : 5.71 ,
     fixed_position_7_4 : 5.71 ,
     fixed_position_7_5 : 2.85,
     fixed_position_7_5_2 : 2.85,
     fixed_position_7_8 : 4.56,

};

const ADDITIONAL_ICON_LIGHT = {
  ant_admit: AdmitsIcon,
  actual_admit: DischargesIcon,
  telemetry: TpatientsIcon,
  vaso_active_drips: DripsIcon,
  isolation_patients: IsolationLight,
  of_isolation: IsolationLight,
  of_rrs_codes: rrsIcon,
  of_video_monitors: monitorIcon,
  did_blt_cover_obs: SitterIcon,
  of_patients_w_video_monitor_last_six_hours: hashIcon,
  of_patients_in_seclusion_last_six_hours: hashIcon,
  of_patients_in_restraints_last_six_hours: hashIcon,
  of_icu_patients_waiting_in_er: hashIcon,
  of_anticipated_transfers_in_next_four_hours: hashIcon,
  of_anticipated_transfers_out_next_four_hours: hashIcon,
  of_rns_wa_wit_higher_than_shift_standard: hashIcon,
  of_pcu_pts_waiting_in_er: hashIcon,
  of_c_sections_since_last_time_stamp: hashIcon,
  of_inductions_since_last_time_stamp: hashIcon,
  of_births_since_last_time_stamp: hashIcon,
  of_triage_patients_since_last_time_stamp: hashIcon,
  of_pp_hemorrhage_ob_emergency: hashIcon,
  of_resuscitations: hashIcon,
  of_op_dialysis_patients: hashIcon,
};

const DEV_ADDITIONAL_ICON_LIGHT = {
  ant_admit: AdmitsIcon,
  actual_admit: DischargesIcon,
  telemetry: TpatientsIcon,
  vaso_active_drips: DripsIcon,
  isolation_patients: IsolationLight,
  of_isolation: IsolationLight,
  of_rrs_codes: rrsIcon,
  of_video_monitors: monitorIcon,
  did_blt_cover_obs: SitterIcon,
  of_patients_w_video_monitor_last_six_hours: hashIcon,
  of_patients_in_seclusion_last_six_hours: hashIcon,
  of_patients_in_restraints_last_six_hours: hashIcon,
  of_icu_patients_waiting_in_er: hashIcon,
  of_anticipated_transfers_in_next_four_hours: hashIcon,
  of_anticipated_transfers_out_next_four_hours: hashIcon,
  of_rns_wa_wit_higher_than_shift_standard: hashIcon,
  of_pcu_pts_waiting_in_er: hashIcon,
  of_c_sections_since_last_time_stamp: hashIcon,
  of_inductions_since_last_time_stamp: hashIcon,
  of_births_since_last_time_stamp: hashIcon,
  of_triage_patients_since_last_time_stamp: hashIcon,
  of_pp_hemorrhage_ob_emergency: hashIcon,
  of_resuscitations: hashIcon,
  of_op_dialysis_patients: hashIcon,
  cx_no_shows_w_i_24_hrs: hashIcon,
  downtime_hrs: hashIcon,
  hrs_non_cath_proc: hashIcon,
  of_proc_cx_no_shows_w_i_24_hrs: hashIcon,
  hrs_equip_down: hashIcon,
  on_call_hrs_paid: hashIcon,
  times_called_in: hashIcon,
  of_hrs_in_surgery: hashIcon,
  active_work_queue: hashIcon,
  total_work_queue: hashIcon,
  uncontacted_referral_grater_7_days: hashIcon,
  surgery_hrs: hashIcon,
  fluoro_hrs: hashIcon,
  ip_same_day_add_ons: hashIcon,
  actual_rn_worked_hrs: hashIcon,
  uae_echos: hashIcon,
  providers_out: hashIcon,
  cancellations_within_24_hrs: hashIcon,
  ip_add_ons: hashIcon,
  dac_hrs_spent: hashIcon,
  no_shows: hashIcon,
  cx_w_i_24_hrs: hashIcon,
  patients_w_stay_grater_70_min: hashIcon,
  crash_carts_set_up_restocked: hashIcon,
  instruments_returned_unused: hashIcon,
  flashed_items: hashIcon,
  procedures_requiring_staff_grater_normal_ortho: hashIcon,
  procedures_requiring_grater_normal_plastics: hashIcon,
  procedures_requiring_grater_normal_for_ophthamolgy: hashIcon,
  procedures_requiring_grater_normal_general_baritics_other: hashIcon,
  ip_status_patients_held_over: hashIcon,
  of_hours_the_rcu_was_open_last_4_hrs: hashIcon,
  one_1_and_1_2_pts_currently_awaiting_placement: hashIcon,
  code_3s_stemi_or_strokes_last_4_hours: hashIcon,
  of_psa_si_pts: hashIcon,
  of_lost_doses: hashIcon,
  active_cases_in_the_work_queue: hashIcon,
  of_ir_vas_procedures: hashIcon,
  hi_flow_in_us: hashIcon,
  of_vents_in_use: hashIcon,
  of_bi_paps_in_use: hashIcon,
  of_c_paps_in_use_noc_only: hashIcon,
  of_loaner_trays_processed: hashIcon,
  urgent_cases_added_to_schedule: hashIcon,
  unplanned_providers_absent: hashIcon,
  no_show_patients_same_day_cancelation: hashIcon,
};


const ADDITIONAL_ICON_DARK = {
  ant_admit: AdmitsIcon1,
  actual_admit: DischargesIcon1,
  telemetry: TpatientsIcon1,
  vaso_active_drips: DripsIcon1,
  isolation_patients: IsolationDark,
  of_isolation: IsolationDark,
  of_rrs_codes: rrsIcon1,
  of_video_monitors: monitorIcon1,
  did_blt_cover_obs: SitterIcon1,
  of_patients_w_video_monitor_last_six_hours: hashIcon1,
  of_patients_in_seclusion_last_six_hours: hashIcon1,
  of_patients_in_restraints_last_six_hours: hashIcon1,
  of_icu_patients_waiting_in_er: hashIcon1,
  of_anticipated_transfers_in_next_four_hours: hashIcon1,
  of_anticipated_transfers_out_next_four_hours: hashIcon1,
  of_rns_wa_wit_higher_than_shift_standard: hashIcon1,
  of_pcu_pts_waiting_in_er: hashIcon1,
  of_c_sections_since_last_time_stamp: hashIcon1,
  of_inductions_since_last_time_stamp: hashIcon1,
  of_births_since_last_time_stamp: hashIcon1,
  of_triage_patients_since_last_time_stamp: hashIcon1,
  of_pp_hemorrhage_ob_emergency: hashIcon1,
  of_resuscitations: hashIcon1,
  of_op_dialysis_patients: hashIcon1,
};

const DEV_ADDITIONAL_ICON_DARK = {
  ant_admit: AdmitsIcon1,
  actual_admit: DischargesIcon1,
  telemetry: TpatientsIcon1,
  vaso_active_drips: DripsIcon1,
  isolation_patients: IsolationDark,
  of_isolation: IsolationDark,
  of_rrs_codes: rrsIcon1,
  of_video_monitors: monitorIcon1,
  did_blt_cover_obs: SitterIcon1,
  of_patients_w_video_monitor_last_six_hours: hashIcon1,
  of_patients_in_seclusion_last_six_hours: hashIcon1,
  of_patients_in_restraints_last_six_hours: hashIcon1,
  of_icu_patients_waiting_in_er: hashIcon1,
  of_anticipated_transfers_in_next_four_hours: hashIcon1,
  of_anticipated_transfers_out_next_four_hours: hashIcon1,
  of_rns_wa_wit_higher_than_shift_standard: hashIcon1,
  of_pcu_pts_waiting_in_er: hashIcon1,
  of_c_sections_since_last_time_stamp: hashIcon1,
  of_inductions_since_last_time_stamp: hashIcon1,
  of_births_since_last_time_stamp: hashIcon1,
  of_triage_patients_since_last_time_stamp: hashIcon1,
  of_pp_hemorrhage_ob_emergency: hashIcon1,
  of_resuscitations: hashIcon1,
  of_op_dialysis_patients: hashIcon1,
  cx_no_shows_w_i_24_hrs: hashIcon1,
  downtime_hrs: hashIcon1,
  hrs_non_cath_proc: hashIcon1,
  of_proc_cx_no_shows_w_i_24_hrs: hashIcon1,
  hrs_equip_down: hashIcon1,
  on_call_hrs_paid: hashIcon1,
  times_called_in: hashIcon1,
  of_hrs_in_surgery: hashIcon1,
  active_work_queue: hashIcon1,
  total_work_queue: hashIcon1,
  uncontacted_referral_grater_7_days: hashIcon1,
  surgery_hrs: hashIcon1,
  fluoro_hrs: hashIcon1,
  ip_same_day_add_ons: hashIcon1,
  actual_rn_worked_hrs: hashIcon1,
  uae_echos: hashIcon1,
  providers_out: hashIcon1,
  cancellations_within_24_hrs: hashIcon1,
  ip_add_ons: hashIcon1,
  dac_hrs_spent: hashIcon1,
  no_shows: hashIcon1,
  cx_w_i_24_hrs: hashIcon1,
  patients_w_stay_grater_70_min: hashIcon1,
  crash_carts_set_up_restocked: hashIcon1,
  instruments_returned_unused: hashIcon1,
  flashed_items: hashIcon1,
  procedures_requiring_staff_grater_normal_ortho: hashIcon1,
  procedures_requiring_grater_normal_plastics: hashIcon1,
  procedures_requiring_grater_normal_for_ophthamolgy: hashIcon1,
  procedures_requiring_grater_normal_general_baritics_other: hashIcon1,
  ip_status_patients_held_over: hashIcon1,
  of_hours_the_rcu_was_open_last_4_hrs: hashIcon1,
  one_1_and_1_2_pts_currently_awaiting_placement: hashIcon1,
  code_3s_stemi_or_strokes_last_4_hours: hashIcon1,
  of_psa_si_pts: hashIcon1,
  of_lost_doses: hashIcon1,
  active_cases_in_the_work_queue: hashIcon1,
  of_ir_vas_procedures: hashIcon1,
  hi_flow_in_us: hashIcon1,
  of_vents_in_use: hashIcon1,
  of_bi_paps_in_use: hashIcon1,
  of_c_paps_in_use_noc_only: hashIcon1,
  of_loaner_trays_processed: hashIcon1,
  urgent_cases_added_to_schedule: hashIcon1,
  unplanned_providers_absent: hashIcon1,
  no_show_patients_same_day_cancelation: hashIcon1,
};



const TABLE_NAMES = {
  cost_center_number: "Cost Center",
  overtime: "Overtime % of  Worked Hours",
  critical_exception_count: "Critical Exception",
  schedule_variance: "Schedule Variance",
  missed_meal_break: "Missed Meal Break",
  hours_variance: "Hours Variance",
  agency: "Agency % of  Worked Hours",
};

const ADM_SUMMARY_TABLE = {
  date_show: "Date and DOW",
  uos: "UOS",
  worked_hours: "Worked Hours",
  paid_hours: "Paid Hours",
  target_paid_hours: "Target Paid Hours",
  paid_hours_variance: "Variance",
  target_hours: "Target Hours",
  hpuos: "HPUOS",
  hours_variance: "Variance",
};

const EXCEPTION_SUMMARY_TABLE = {
  cost_center_number: "Cost Center",
  critical_exception_count: "Critical Exception Count",
  when_posted_days_interval_avg: "Average Exception Age",
};

const EXCEPTION_DETAIL_TABLE = {
  employee_name: "Name",
  critical_exceptions: "Critical",
  warning_exceptions: "Warning",
  informational_exceptions: "Informational",
  total_exceptions: "Total Exceptions",
  exception_description: "Exception Description",
};

const SCHEDULE_SUMMARY_TABLE = {
  cost_center_name: "Cost Center",
  worked_hours: "Worked Hours",
  scheduled_hours: "Scheduled Hours",
  scheduled_variance: "Schedule Variance",
  staff: " Staff W/O Schedule",
};

const SCHEDULE_DETAIL_TABLE = {
  employee_name: "Staff",
  total_scheduled_variance: "Total",
};

const OVERTIME_SUMMARY_TABLE = {
  cost_center_name: "Cost Center",
  overtime_worked_hours: "Overtime % of Worked Hours",
  incidental: "Incidental %",
  total_overtime_hours: "Total Overtime Hours",
};

const OVERTIME_DETAIL_TABLE = {
  employee_name: "Employee",
  total_overtime_hours: "Total",
  overtime_worked_hours: "Overtime % of Worked Hours",
  incidental: "Incidental %",
};

const MMB_SUMMARY_TABLE = {
  cost_center_name: "Cost Center",
  employes: "Staff",
  mmbHoursum: "MMB Hours",
};

const MMB_DETAIL_TABLE = {
  employee_name: "Employee",
  total_hours: "Total",
};

const AGENCY_SUMMARY_TABLE = {
  cost_center_name: "Cost Center",
  agency_worked_hours: "Agency % of Worked Hours",
  agency_hours: "Agency Hours",
};

const AGENCY_DETAIL_TABLE = {
  employee_name: "Employee",
  total_agency_hours: "Total",
};

const AGENCY_DAILY_MODAL = {
  job_code: "Job Code",
  job_description: "Job Description",
  pay_code: "Pay Code",
  description: "Pay Code Description",
  paid_hours: "Paid Hours",
  worked_hours: "Worked Hours",
};

const REALTIME_TABLE = {
  cost_center_name: "Cost Center",
};

const SHIFT_FORM = {
  VALIDATION_NAMES,
  TABLE_NAMES,
  ADM_SUMMARY_TABLE,
  EXCEPTION_SUMMARY_TABLE,
  EXCEPTION_DETAIL_TABLE,
  SCHEDULE_SUMMARY_TABLE,
  SCHEDULE_DETAIL_TABLE,
  OVERTIME_SUMMARY_TABLE,
  OVERTIME_DETAIL_TABLE,
  MMB_SUMMARY_TABLE,
  MMB_DETAIL_TABLE,
  AGENCY_SUMMARY_TABLE,
  AGENCY_DETAIL_TABLE,
  AGENCY_DAILY_MODAL,
  REALTIME_TABLE,
  PATIENT_ICON_DARK,
  PATIENT_ICON_LIGHT,
  STAFFING_ICON_LIGHT,
  STAFFING_ICON_DARK,
  STAFFING_GRID_VALUE,
  ADDITIONAL_ICON_LIGHT,
  ADDITIONAL_ICON_DARK,
  STAFFING_FIXED_POSITION,
  DEV_ADDITIONAL_ICON_LIGHT,
  DEV_ADDITIONAL_ICON_DARK,
  DEV_PATIENT_ICON_LIGHT,
  DEV_PATIENT_ICON_DARK,
  DEV_STAFFING_ICON_LIGHT,
  DEV_STAFFING_ICON_DARK,
};

export default SHIFT_FORM;
