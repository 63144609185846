"use strict";
import React, { useEffect, useState, useContext } from "react";
import { AgChartsReact } from "ag-charts-react";
import { Link } from "react-router-dom";
import { getPreviousShiftData } from "../../../services";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { useLocation, useNavigate } from 'react-router-dom';

export const DoughnutChart = ({
    data,
    callbackfunc,
    data1,
    boxColor,
    isdetail,
    directId,
    isCostcenter
}) => {
    const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
    );
    const [urltype, setUrltype] = useState("realtime");
    const [themeMode, setTheme] = useContext(ThemeContext);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [hide, setHide] = useState(pathname === '/realtime' ? true : false);
    const colorStatus = {
        entry_on_time: "#48b461",
        entry_missing: "#ff2626",
        entry_upcoming: "#d0cece",
        entry_delayed: " #48b461",
        entry_due: "#FFEF00",
    };

    const inner_ring = data?.inner_ring;
    const arr1 = [];
    const arr2 = [];

    const outerringval = (outer_ring_val) => {
        const seriesarr = [];
        if (outer_ring_val >= 100) {
            arr1.push("#0a9d2c");
            arr1.push("#48b461");
            let caldata = outer_ring_val % 100;
            //setOutervalue(outer_ring_val);
            seriesarr.push({ calval: outer_ring_val, count: caldata });
            seriesarr.push({ calval: outer_ring_val, count: 100 - caldata });
        } else if (outer_ring_val < 100 && outer_ring_val >= 98) {
            arr1.push("#ffff26");
            arr1.push("#ffffb1");
            //setOutervalue(outer_ring_val);
            seriesarr.push({ calval: outer_ring_val, count: outer_ring_val });
            seriesarr.push({ calval: outer_ring_val, count: 100 - outer_ring_val });
        } else {
            arr1.push("#ff2626");
            // arr1.push("#fde9e9"); ////////////////////////////////////////
            arr1.push(themeMode.theme === "DARK" ? "#cdcecf" : "#f1f1f1");
            //setOutervalue(outer_ring_val);
            seriesarr.push({
                name: "Covered",
                calval: outer_ring_val,
                count: outer_ring_val,
            });
            seriesarr.push({
                name: "Not Covered",
                calval: outer_ring_val,
                count: 100 - outer_ring_val,
            });
        }
        return seriesarr;
    };

    const outerringval1 = (outer_ring_val) => {
        const seriesarr = [];
        if (outer_ring_val >= 100) {
            arr2.push("#0a9d2c");
            arr2.push("#48b461");
            let caldata = outer_ring_val % 100;
            //setOutervalue(outer_ring_val);
            seriesarr.push({ calval: outer_ring_val, count: caldata });
            seriesarr.push({ calval: outer_ring_val, count: 100 - caldata });
        } else if (outer_ring_val < 100 && outer_ring_val >= 98) {
            arr2.push("#ffff26");
            arr2.push("#ffffb1");
            //setOutervalue(outer_ring_val);
            seriesarr.push({ calval: outer_ring_val, count: outer_ring_val });
            seriesarr.push({ calval: outer_ring_val, count: 100 - outer_ring_val });
        } else {
            arr2.push("#ff2626");
            // arr2.push("#fde9e9"); ////////////////////////////////////////
            arr2.push(themeMode.theme === "DARK" ? "#cdcecf" : "#f1f1f1");
            //setOutervalue(outer_ring_val);
            seriesarr.push({
                name: "Covered",
                calval: outer_ring_val,
                count: outer_ring_val,
            });
            seriesarr.push({
                name: "Not Covered",
                calval: outer_ring_val,
                count: 100 - outer_ring_val,
            });
        }
        return seriesarr;
    };

    const seriesProductivity = outerringval(data?.productivity);
    const seriesCompliance = outerringval1(data?.compliance);


    // if (data?.inner_ring) {
    //     data?.inner_ring.map((v) => {
    //         //console.log({ v })
    //         if (colorStatus[v.status]) {
    //             arr2.push(colorStatus[v.status]);
    //         }
    //     });
    // }

    const options = {
      series: [
        {
          type: "pie",
          angleKey: "value",
          labelKey: "category",

          theme: "ag-default-dark",
          calloutLabelKey: "",
          data: seriesProductivity,
          angleKey: "count",
          outerRadiusRatio: 0.78,
          innerRadiusRatio: 0.58,
          showInLegend: false,
          strokeWidth: 0,
          strokeOpacity: [1, 0, 0, 0],
          calloutLine: "round",
          //  strokes:  ["#000", "#000"],
          //   strokes: themeMode.theme === "DARK" ? ["#FFF", "#CCCCCC00"] : ["#000", "#CCCCCC00"],
          fills: ["#000", "#000"],
          tooltip: { renderer: renderer1 },
          highlightStyle: {
            item: {
              fill: "#CCCCCC00",
              //    stroke: 'maroon',
              strokeWidth: 0,
            },
            series: {
              dimOpacity: 1,
              strokeWidth: 0,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%",
                // background: "#293450",
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
                stroke: {
                  calloutLine: "round",
                },
              },
              dataLabels: {
                name: {
                  offsetY: -10,
                  color: "#fff",
                  // fontSize: "13px",
                },
                value: {
                  color: "#fff",
                  fontSize: "30px",
                  show: true,
                },
              },
              pie: {
                expandOnClick: true,
              },
            },
          },
          fills: arr1,
          //data: arr1,
        },
        {
          type: "pie",

          theme: "ag-default-dark",
          calloutLabelKey: "",
          data: seriesCompliance,
          angleKey: "count",
          outerRadiusRatio: 0.82,
          innerRadiusRatio: 0.99,
          showInLegend: false,
          strokeWidth: 0,
          // calloutLine: "round",
          // strokes:  ["#CCCCCC00", "#000","#CCCCCC00", "#CCCCCC00"],
          fills: ["red", "yellow"],
          tooltip: { renderer: renderer2 },
          highlightStyle: {
            item: {
              fill: "#CCCCCC00",
              //    stroke: 'maroon',
              strokeWidth: 0,
            },
            series: {
              dimOpacity: 1,
              strokeWidth: 0,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%",
                // background: "#293450",
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
                stroke: {
                  lineCap: "round",
                },
              },
              dataLabels: {
                name: {
                  offsetY: -10,
                  color: "#fff",
                  // fontSize: "13px",
                },
                value: {
                  color: "#fff",
                  fontSize: "30px",
                  show: true,
                },
              },
              pie: {
                expandOnClick: true,
              },
            },
          },
          fills: arr2,
        },
      ],
      // backgroundColor: { fill: "yellow" },
      background: {
        fill: themeMode.theme === "DARK" ? "#191C24" : "white",
      },
    };



    const chartOptions = {
        container: { background: { fill: 'yellow' } }, // Set the background color for the chart container
    };

    function renderer(params) {
        //getColorFun(params.color)
        return `<div class="ag-chart-tooltip-title" >
          ${params.datum.message}
        </div>`;
    }

    function renderer1(params) {
        return `<div class="ag-chart-tooltip-title" >
         ${params.datum?.calval}% Productivity
        </div>`;
    }

    function renderer2(params) {
        return `<div class="ag-chart-tooltip-title" >
         ${params.datum?.calval}% Complete
        </div>`;
    }

    return (
        <div
            className={isdetail ? "" : "col-md-12 UU"}
            style={{ backgroundColor: boxColor }}
        >

            <div
                className={
                    isdetail
                        ? "white-bg undefined p-0"
                        : "white-bg shadow-box undefined p-0"
                }
            >
                <div className=" text-center pt-2 pb-2">
                    <div className="row align-items-center">
                        <div className={hide && "twopx col-7"}
                        >
                            <Link
                                className="boxlink"
                            >
                                <AgChartsReact
                                    options={options}
                                    backgroundColor="yellow"
                                // nodeMouseEnter={(event) =>
                                //   handleHover(event, true, "black")
                                // }
                                // nodeMouseLeave={(event) => handleHover(event, false)}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
