import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import "./index.css";
import logo from "../../../assets/img/perfectshift-logo.png";
import { Formik, Field, Form } from "formik";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { API_CALL_URL_PUBLIC } from "../../../constants/compensate";
import { resetValidator } from "../../../utils/validator";
import SuccessModal from "../../../components/shared/SuccessModal";
import { decryptedFunction, dataEnCrypt } from '../../../utils/helper';

function Reset({ history }) {
  const location = useLocation();
  const stateData = location.state.data;
  const stateMessage = location.state.message;

  const [otp, setOtp] = useState("");
  const [resetValues, setResetValues] = useState({
    email: stateData?.employee_id,
    password: "",
    confirm_password: "",
    otp: "",
  });
  const [showsucess, setShowsucess] = useState(false);
  const [showPopsucess, setShowPopsucess] = useState(false);
  const [sucessmsg, setSucessmsg] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const handleLogin = async (values) => {


    try {

      const paramData = {
        employee_id: values.email,
        otp: values.otp,
        password: values.password,
      }
      const encryptData = dataEnCrypt(paramData);

      const response = await axios.post(
        `${API_CALL_URL_PUBLIC}/authentication/resetPassword`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setSucessmsg(data.message);
      setShowsucess(true);
      RedirectFun();
    } catch (error) {
      const errorResponse = decryptedFunction(error?.response?.data?.responseData)
      setErrormsg(errorResponse?.message);
      console.log(error);
    }
  };
  const handleResend = async () => {
    try {
      const paramData = {
        username: stateData?.employee_email,
      }
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PUBLIC}/authentication/forgotPassword`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setSucessmsg(data.message);
      setShowsucess(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowsucess(false);
    }, 2000);
  }, [showsucess]);

  const RedirectFun = () => {
    setTimeout(() => {
      setShowPopsucess(false);
      history("/");
    }, 2000);
  };

  return (
    <>
      <div className="dashboard-wrapper full-width h-100vh overflow-hidden">
        <div className="container py-3 pb-0 h-100vh ">
          <div className="row height-100 justify-content-center align-items-center h-100vh">
            <div className="login-screen max-width-300 ">
              <div className="text-center">
                <img src={logo} alt="" className="img-fluid mb-5" />
              </div>
              <Formik
                enableReinitialize
                initialValues={resetValues}
                validateOnChange
                validate={resetValidator}
                onSubmit={(values) => handleLogin(values, false)}
              >
                {(formikBag) => {
                  return (
                    <Form style={{ width: "100%" }}>
                      <div className="col-12 d-flex align-items-center justify-content-left text-success f-14 mb-2">
                        {stateMessage}
                      </div>
                      <div className="mb-3 otp">
                        <OtpInput
                          value={formikBag.values.otp}
                          onChange={(e) => formikBag.setFieldValue("otp", e)}
                          numInputs={6}
                          htmlAttrs={{
                            autocomplete: 'off',
                            name: 'number'
                          }}
                          autoComplete="off"
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                        {formikBag.touched.otp && formikBag.errors.otp ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.otp}
                            </p>
                          </>
                        ) : null}
                        <div className="col-12 d-flex align-items-center justify-content-left text-danger f-12 mt-2">
                          {errormsg}
                        </div>
                      </div>
                      <div
                        className="col-12 d-flex align-items-center justify-content-end cursor-pointer text-danger hover-text"
                        onClick={handleResend}
                      >
                        Resend
                      </div>
                      <div className="mb-3">
                        <label>New Password *</label>
                        <Field name="password">
                          {({ field }) => (
                            <input
                              {...field}
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="New Password"
                              autoComplete="off"
                              value={formikBag.values.password}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "password",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Field>
                        {formikBag.touched.password &&
                        formikBag.errors.password ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.password}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label>Confirm New Password *</label>
                        <Field name="confirm_password">
                          {({ field }) => (
                            <input
                              {...field}
                              type="password"
                              name="confirm_password"
                              className="form-control"
                              autoComplete="off"
                              placeholder="Confirm New Password"
                              value={formikBag.values.confirm_password}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "confirm_password",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Field>
                        {formikBag.touched.confirm_password &&
                        formikBag.errors.confirm_password ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.confirm_password}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btm btn-success w-100 text-capitalize"
                          style={{ marginBottom: "8px" }}
                        >
                          Reset Password
                        </button>
                      </div>
                      <div
                        class="col-12 d-flex align-items-center justify-content-center"
                        style={{ color: "lightgray", marginBottom: "5px" }}
                      >
                        or
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-center hover-text">
                        <Link
                          to="/"
                          class="forgot-pass f-18"
                          style={{ color: "#5e8e28" }}
                        >
                          Login Here
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isShow={showsucess}
        callbackfunc={() => setShowsucess(false)}
        title="Success"
        content={sucessmsg}
      />
      <SuccessModal
        isShow={showPopsucess}
        callbackfunc={() => setShowPopsucess(false)}
        title="Success"
        content={sucessmsg}
      />
    </>
  );
}

export default Reset;
