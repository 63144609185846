// export default ExceptionsDetails;
import "./index.css";
import React from "react";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";

import ContentLoader from "react-content-loader";
import Form from "react-bootstrap/Form";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Done from "../../../assets/img/done.png";
import Close from "../../../assets/img/close.png";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import ReactApexChart from "react-apexcharts";
import ActiveChart from "../../../components/ActiveChart/ActiveChart";
import StackedBarChart from "../../../components/ActiveChart/OvertimeStackedBarChart";
import AgeChart from "../../../components/ActiveChart/AgeChart";
import { getTableRecordWithSorting } from "../../../services";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { THEME_TYPE } from "../../../constants/theme";
import { DOMAIN_URL } from "../../../constants/compensate";
import PsLogo from "../../../assets/img/pslogo.png";
import ADMImage from "../../../assets/img/active_daily_mgmt.svg";
import Bi1 from "../../../assets/img/bi1.png";
import Bi2 from "../../../assets/img/bi2.png";
import Bi3 from "../../../assets/img/bi3.png";
import Bi4 from "../../../assets/img/bi4.png";
import SCHDULE from "../../../assets/img/schduling.png";
import GRPH from "../../../assets/img/overtime-graph.jpg";
import More from "../../../assets/img/ui.png";
import Approve from "../../../assets/img/done.png";
import UnApprove from "../../../assets/img/done1.png";
import Reject from "../../../assets/img/close1.png";
import UnReject from "../../../assets/img/close2.png";
import Select from "react-select";
import Button from "@atlaskit/button";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import moment from "moment";
import SuccessModal from "../../shared/SuccessModal";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);

function ActiveManagement({ history }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isGraphLoadingTwo, setIsGraphLoadingTwo] = useState(false);
  const [graph1Data, setGraph1Data] = useState([]);
  const [graph2Data, setGraph2Data] = useState([]);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [emptyDate, setEmptyDate] = useState(false);
  const [graphMaxData, setGraphMaxData] = useState("");
  const [graphMaxtwo, setGraphMaxtwo] = useState("");
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
   const [showMore, setShowMore] = useState(false);
  const momoDate = useSelector((state) => state.compensate.updateDate);
  const momoIndex = useSelector((state) => state.compensate.updatedIndex);
  const exceptionAge = useSelector((state) => state.compensate.exceptionAge);
  const exceptionIndex = useSelector(
    (state) => state.compensate.exceptionIndex
  );

  const [graphData, setGraphData] = useState([]);
  const [handleGraph, setHandleGraph] = useState(true);
  //   const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [expandedComments, setExpandedComments] = useState({});

    const toggleComment = (index) => {
      setExpandedComments((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    };

  const getTableData = async (eDate, eCost, eAge) => {
    setIsTableLoading(true);
    const parData = {};
    if (eDate) {
      parData.filter_date = eDate;
    }
    if (eCost) {
      parData.cost_center_number = eCost;
    }
    if (eAge) {
      parData.start = eAge.start;
      parData.end = eAge.end;
    }

    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/adm/getData`,
        parData
      );
      setIsTableLoading(false);
      setTableData(data.data);

      setCostCenterList(
        data?.data?.cost_centers.map((item) => {
          return {
            label: item.cost_center_name?.toUpperCase(),
            value: item.cost_center_slug,
          };
        })
      );

      const sum = data.data?.cost_centers.reduce(function (
        previousValue,
        currentValue
      ) {
        return {
          critical_exception_count:
            previousValue.critical_exception_count +
            currentValue.critical_exception_count,
          when_posted_days_interval_avg:
            (previousValue?.when_posted_days_interval_avg || 0) +
            (currentValue?.when_posted_days_interval_avg || 0),
          hours_variance:
            (previousValue?.hours_variance || 0) +
            (currentValue?.hours_variance || 0),
        };
      });
      setTotalvalue(sum);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };
  const getHeadData = async (eDate, eCost, eAge) => {
    setIsLoading(true);
    const parData = {};
    if (eDate) {
      parData.filter_date = eDate;
    }
    if (eCost) {
      parData.cost_center_number = eCost;
    }
    if (eAge) {
      parData.start = eAge.start;
      parData.end = eAge.end;
    }

    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/adm/getheaderData`,
        parData
      );
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getChartFirst = async (eDate, eCost, eAge) => {
    setIsGraphLoading(true);
    const parData = {};
    if (eDate) {
      parData.filter_date = eDate;
    }
    if (eCost) {
      parData.cost_center_number = eCost;
    }
    if (eAge) {
      parData.start = eAge.start;
      parData.end = eAge.end;
    }
    try {
      const url = `${API_CALL_URL_PRIVATE}/exceptionSummary/getGraphData1`;
      const requestData = {};

      const { data } = await axios.post(url, parData);
      const g1 = data?.data?.graph?.map((item) => ({ ...item, type: "g1" }));

      setGraph1Data(g1);
      setGraphMaxData(data.data.graph_max_val);
    } catch (error) {
      console.log(error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const getChartSecond = async (eDate, eCost, eAge) => {
    setIsGraphLoadingTwo(true);
    try {
      const url = `${API_CALL_URL_PRIVATE}/exceptionSummary/getGraphData2`;
      const parData = {};
      if (eDate) {
        parData.filter_date = eDate;
      }
      if (eCost) {
        parData.cost_center_number = eCost;
      }
      if (eAge) {
        parData.start = eAge.start;
        parData.end = eAge.end;
      }

      const { data } = await axios.post(url, parData);
      const g2 = data.data.graph.map((item) => ({ ...item, type: "g2" }));
      setGraph2Data(g2);
      setGraphMaxtwo(data.data.graph_max_val);
    } catch (error) {
      console.log(error);
    } finally {
      setIsGraphLoadingTwo(false);
    }
  };

  useEffect(() => {
    // dispatch(setUpdateDate(""));
    // dispatch(setUpdateIndex(""));
    // dispatch(setUpdateIndex(""));
    // dispatch(setExceptionIndex(""));
    // setClickCount(0);
    // getMetaData();
    // getTableData();
    // getHeadData();
    // getChartFirst();
    // getChartSecond();
    getRefreshSchedule();
  }, []);

  // useEffect(() => {
  //   getTableData(momoDate, selectedRow, exceptionAge);
  //   getHeadData(momoDate, selectedRow, exceptionAge);
  // }, [momoDate]);

  // useEffect(() => {
  //   getTableData(momoDate, selectedRow, exceptionAge);
  //   getHeadData(momoDate, selectedRow, exceptionAge);
  //   getChartFirst(momoDate, selectedRow, exceptionAge);
  // }, [exceptionAge]);

  const returnZero = (val) => {
    if (val) {
      const empWithCriticalException = val;
      const formattedValue = empWithCriticalException?.toFixed(1);
      return formattedValue;
    } else {
      return 0;
    }
  };

  const sortingHandle = (key, bindname) => {
    const sortdata = getTableRecordWithSorting(
      get(tableData, bindname, []),
      key,
      sortType
    );
    setSortType(sortType === "asc" ? "desc" : "asc");
    setTableData({ ...tableData, cost_centers: sortdata });
    setsortName(key);
  };

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    getChartFirst();
    getChartSecond();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    navigate(
      `/activedailymanagement/adm-summary/${val?.cost_center_slug}`
    );
    // setSelectedRow(val?._id);
    // setClickCount(clickCount + 1);

    // if (clickCount === 0) {
    //   setSelectedRow(val?._id);
    //   momoIndex ? OppositeCosHandle(val?.cost_center_number) : fetchSingleData(val?.cost_center_number);
    // } else if (clickCount === 1) {
    //   if (selectedRow !== val?._id) {
    //     setSelectedRow(val?._id);
    //     momoIndex ? OppositeCosHandle(val?.cost_center_number) : fetchSingleData(val?.cost_center_number);
    //     setClickCount(1);
    //   } else {
    //     navigate(`/activedailymanagement/biweekly-summary`);
    //   }
    // } else {
    //   console.log("third click")
    // }
  };

  const fetchSingleData = async (e) => {
    getTableData(momoDate, e, exceptionAge);
    getHeadData(momoDate, e, exceptionAge);
    getChartFirst(momoDate, e, exceptionAge);
    getChartSecond(momoDate, e, exceptionAge);
  };

  const OppositeCosHandle = async (e) => {
    await getHeadData(momoDate, e, exceptionAge);
    // await getChartData(momoDate, e)
    await getTableData(momoDate, e, exceptionAge);
  };

  const getMetaData = async () => {
    try {
      const { data } = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        application_id: 105,
      });
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

 const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
 };
  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const options = [
    { value: "18/01/20024", label: "18/01/20024" },
    { value: "18/01/20024", label: "19/01/20024" },
    { value: "18/01/20024", label: "20/01/20024" },
  ];

  // Get Comments
  const [commentDatas, setCommentDatas] = useState([]);
  const [showsucess, setShowsucess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const getComment = async () => {
    setIsTableLoading(true);
    try {
       const paramData = {
         category: "OVERTIME",
         application_id: 105,
         cost_center_number: state?.slug?.value,
         when_posted: state?.periodDate,
       };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/comment/getComments`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setCommentDatas(data.data);
      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const manageReview = async (_id, _manager_review) => {
    try {
      const paramData = {
        _id: _id,
        manager_review: _manager_review,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/comment/managerReview`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      getComment();
      setSuccessMessage(data.message);
      setShowsucess(true);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };
  // const getComment = async () => {
  //   try {
  //     const { data } = await axios.post(
  //       `${API_CALL_URL_PRIVATE}/comment/getComments`,
  //       {
  //         category: "OVERTIME",
  //         application_id: 105,
  //         cost_center_number: state?.slug?.value,
  //         when_posted: state?.periodDate,
  //       }
  //     );
  //     console.log(data.data, "commentys data");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    getComment();
  }, [state]);

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year.slice(-2); // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${shortYear}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  function convertDateFormated(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year; // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${shortYear}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2 mt-0 align-items-center">
          <Col md={{ span: 1 }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </div>
          </Col>
          <Col md={{ span: 10 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              <div className="">
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                  Comments Center -
                </h3>
              </div>
              {/* <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {convertDateFormat(state?.periodDate)}
                    </Button>
                  }
                >
                </CustomDropdown>
              </div> */}
              <div style={{ marginLeft: "1rem" }}>
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                  {state?.slug?.label}
                </h3>
              </div>
              <div style={{ marginLeft: "1.3rem" }}>
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                  {convertDateFormated(state?.periodDate)}
                </h3>
              </div>
            </div>
          </Col>
          <Col md={1}></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2 row">
          <Col md={12} className="mt-2 h-700">
            <div className="bg-white p-3 h-100 overflow-auto scroll-height-600">
              <table class="table activity-tabel mb-0  spacing-table tb1 table-hover comment-table-design ">
                <thead>
                  <tr>
                    <th class="coll text-center width-100p">Date Submitted</th>
                    <th class="coll text-center">Submitted By</th>
                    <th class="coll text-center">Employee</th>
                    <th class="coll text-center">Category</th>
                    <th class="coll text-center">Activity Date</th>
                    <th class="coll text-center">Reason</th>
                    <th class="coll text-center">Location</th>
                    <th class="coll text-center">Comment</th>
                    <th class="coll text-center w-100px">
                      Approve
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {!isTableLoading ? (
                    commentDatas.length > 0 ? (
                      commentDatas?.map((val, ind) => {
                    const isExpanded = expandedComments[ind];
                    const truncatedComment = val.comment.substring(0,45);
                    const displayComment = isExpanded ? val.comment : truncatedComment;
                        return (
                          <tr key={ind}>
                            <td class="text-center width-100p">
                              <div class="">
                                {moment(val.createdAt).format("MM/DD/YY")}
                              </div>
                            </td>
                            <td class="text-center">
                              <span class="f-12 v-m">
                                {val.submitted_by_name}
                              </span>
                            </td>
                            <td class="text-center">{val.employee_name}</td>
                            <td class="text-center">{val.category}</td>
                            <td class="text-center">
                              {convertDateFormat(val.transaction_date)}
                            </td>
                            <td class="text-center">{val.reason}</td>
                            <td class="text-center">{val.location}</td>
                            <td class=" minheight60 text-center">
                              <div class="flex-grow-1 text-center">
                                {displayComment}
                                {val.comment.length > 45 && (
                                  <span
                                    className="shwmore"
                                    onClick={() => toggleComment(ind)}
                                  >
                                    {isExpanded ? (
                                      "Less"
                                    ) : (
                                      <img
                                        src={More}
                                        width={18}
                                        title="Read More"
                                      />
                                    )}
                                  </span>
                                )}
                              </div>
                              <div class="d-flex">
                                <span class="mb-2"></span>
                                <span class="mb-2"></span>
                              </div>
                            </td>
                            <td class="text-center img-cursor-pointer">
                                {val?.manager_review === 1 ? (
                                  <>
                                    <img
                                      src={Approve}
                                      alt="Approve"
                                      onClick={(e) => {
                                        if (val?.manager_review === 1) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 1);
                                        }
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <img
                                      src={Reject}
                                      alt="Reject"
                                      onClick={(e) => {
                                        if (val?.manager_review === 2) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 2);
                                        }
                                      }}
                                    />
                                  </>
                                ) : val?.manager_review === 2 ? (
                                  <>
                                    <img
                                      src={UnApprove}
                                      alt="UnApprove"
                                      onClick={(e) => {
                                        if (val?.manager_review === 1) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 1);
                                        }
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <img
                                      src={UnReject}
                                      alt="UnReject"
                                      onClick={(e) => {
                                        if (val?.manager_review === 2) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 2);
                                        }
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={UnApprove}
                                      alt="UnApprove"
                                      onClick={(e) => {
                                        if (val?.manager_review === 1) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 1);
                                        }
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <img
                                      src={Reject}
                                      alt="Reject"
                                      onClick={(e) => {
                                        if (val?.manager_review === 2) {
                                          manageReview(val._id, 0);
                                        } else {
                                          manageReview(val._id, 2);
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                  {/* <tr>
                    <td class="text-center width-100p">
                      <div class="">1/7/24 </div>
                    </td>
                    <td class="text-center">
                      <span class="f-12 v-m">David Gehards</span>
                    </td>
                    <td class="text-center">Chase Cushman</td>
                    <td class="text-center">Overtime</td>
                    <td class="text-center">12/28/23</td>
                    <td class="text-center">Sick Call</td>
                    <td class="text-center">ED</td>
                    <td class="f-row minheight60 text-center">
                      <div class="flex-grow-1 text-center">
                        I asked Chase to take an extra shift in the ED - its
                        excused
                      </div>
                      <div class="d-flex">
                        <span class="mb-2"></span>
                        <span class="mb-2"></span>
                      </div>
                    </td>
                    <td className="w-100px text-center">
                      <Form.Check aria-label="option 1" className="d-block" />
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center width-100p">
                      <div class="">1/7/24 </div>
                    </td>
                    <td class="text-center">
                      <span class="f-12 v-m">David Gehards</span>
                    </td>
                    <td class="text-center">Chase Cushman</td>
                    <td class="text-center">Overtime</td>
                    <td class="text-center">12/28/23</td>
                    <td class="text-center">Sick Call</td>
                    <td class="text-center">ED</td>
                    <td class="f-row minheight60 text-center">
                      <div class="flex-grow-1 text-center">
                        I asked Chase to take an extra shift in the ED - its
                        excused
                      </div>
                      <div class="d-flex">
                        <span class="mb-2"></span>
                        <span class="mb-2"></span>
                      </div>
                    </td>
                    <td className="w-100px text-center">
                      <Form.Check aria-label="option 1" className="d-block" />
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </Col>

          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              {/* <span>{headData?.current_file_msg}</span> */}
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
              {/* <span className="col-6"><span className=" text-start  p-2 bg-white d-block">{headData?.upcomming_file_msg}</span></span> */}
            </div>
          </Col>
        </Row>
      </div>
      <SuccessModal
        isShow={showsucess}
        callbackfunc={setShowsucess}
        title="Success"
        content={successMessage}
      />
    </PageLayout>
  );
}

export default ActiveManagement;
