import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { API_CALL_URL_PRIVATE } from "../../../constants/compensate";
import axios from "../../../axios";
import Moment from "moment";
import "./index.css";
import LeftIcon from "../../../assets/img/left.png";
import RightIcon from "../../../assets/img/right.png";
import BedIcon from "../../../assets/img/bed-tb.png";
import AcuityIcon from "../../../assets/img/acuity.png";
import ChargeIcon from "../../../assets/img/charge.png";
import CnaIcon from "../../../assets/img/cna.png";
import OrientationIcon from "../../../assets/img/orientation.png";
import NurseIcon from "../../../assets/img/nurse.png";
import ReseIcon from "../../../assets/img/res-brk-en.png";
import UnitIcon from "../../../assets/img/unit_sec.png";
import SitterIcon from "../../../assets/img/sitter.png";
import AdmitsIcon from "../../../assets/img/admits.png";
import DischargesIcon from "../../../assets/img/discharges.png";
import TpatientsIcon from "../../../assets/img/telemetry_patients.png";
import DripsIcon from "../../../assets/img/drips.png";

// ----------------------Dark View Icon
import BedIcon1 from "../../../assets/img/bed-tb1.png";
import AcuityIcon1 from "../../../assets/img/acuity1.png";
import ChargeIcon1 from "../../../assets/img/charge1.png";
import CnaIcon1 from "../../../assets/img/cna1.png";
import OrientationIcon1 from "../../../assets/img/orientation1.png";
import NurseIcon1 from "../../../assets/img/nurse1.png";
import ReseIcon1 from "../../../assets/img/res-brk-en1.png";
import UnitIcon1 from "../../../assets/img/unit_sec1.png";
import SitterIcon1 from "../../../assets/img/sitter1.png";
import AdmitsIcon1 from "../../../assets/img/admits1.png";
import DischargesIcon1 from "../../../assets/img/discharges1.png";
import TpatientsIcon1 from "../../../assets/img/telemetry_patients1.png";
import DripsIcon1 from "../../../assets/img/drips1.png";
import IsolationLight from "../../../assets/img/Isolation_light.png";
import IsolationDark from "../../../assets/img/Isolation_dark.png";

import { ThemeContext } from "../../../providers/ThemeProvider";
import { THEME_TYPE } from "../../../constants/theme";

import ErrorModal from "../../../components/shared/SuccessModal/ErrorModal";

import { SocketContext } from "../../../socket";
import SHIFT_FORM from "../../../constants/shiftForm";

const init = {
  acuity: null,
  wit: null,
  actual_admit: null,
  ant_admit: null,
  charge: null,
  cna: null,
  comment: null,
  cost_center_number: null,
  currentShiftNumber: null,
  one_five: null,
  one_four: null,
  one_six: null,
  id: null,
  nurses: null,
  one_one: null,
  two_one: null,
  orientation: null,
  res_brk_rn: null,
  shift_time: null,
  sitter: null,
  telemetry: null,
  one_three: null,
  shift_date: null,
  total_patients: null,
  total_patients_two: null,
  one_two: null,
  unit_sec: null,
  vaso_active_drips: null,
  isolation_patients: null,
};

function AddModal({
  isShow,
  callbackfunc,
  reloaddata,
  cost_center_number,
  contentId,
  previousShiftData,
  getprevAndCurrentShift,
  addgridData,
  cost_center_name,
  numbers,
  getPreAndCurr,
  getPrevShiftData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const shiftLength = 4;
  const selector = useSelector((state) => state);
  const socket = useContext(SocketContext);
  const detail_data = selector.compensate.departmentdetail;
  let gridDataApiData = selector.compensate.getPreviousShiftData?.gridData;

  const directDataApiData =
    selector.compensate.getPreviousShiftData?.directData;
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const [formdata, setFormdata] = useState(init);
  const userType = JSON.parse(localStorage.getItem("psdata"))?.data?.user_type; //userType 0 for admin
  // const autoFillformdata = autoFillformdata
  const [successMsg, setSuccessmsg] = useState("");
  const [directData, setDirectData] = useState({});
  const [variance, setVariance] = useState({});
  const [patientData, setPatientData] = useState({});
  const [totalvariance, setTotalVar] = useState(0);
  const [totalPatientvariance, setTotalPatientVar] = useState(0);
  const [gridData, setGridData] = useState({});
  const activedatefilter = selector.compensate?.activedatefilter;
  const [showError, setShowError] = useState(false);
  const [isKeyEmpty, setIsKeyEmpty] = useState(false);
  const [rnTotal, setRnTotal] = useState(0);
  const [rnAvailableData, setRnAvailableData] = useState({});
  const [witRn, setWitRn] = useState(0);
  const [defaultMode, setDefaultMode] = useState(true);

  const [previousPatientData, setpreviousPatientData] =
    useState(directDataApiData);
  const [previousAdditionalDataTracking, setPreviousAdditionalDataTracking] =
    useState(directDataApiData);
  const [previousFormData, setPreviousFormData] = useState(directDataApiData);
  const param = {
    cost_center_number: cost_center_number,
    fromdate: { date: "21-04-2023", shift_time: "13:00" },
    todate: { date: "21-04-2023", shift_time: "16:00" },
  };
  const dateselected = directDataApiData?.shift_date
    ? directDataApiData.shift_date
    : "";

  const timestamp = Date(dateselected);
  const date = Moment(Date(dateselected)).format("DD/MM/YYYY");

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });

    setIsKeyEmpty(false);
    let el = document.querySelectorAll(".apicall");
    setpartValue(event);
    setvarianceValue(event);
    setRnHourValue(event);
  };

  useEffect(() => {
    setGridData(gridDataApiData);
    setIsKeyEmpty(false);
    setTotalPatientVar(0);
    setPatientData({});
    setRnAvailableData({});
    setRnTotal(0);
  }, [gridDataApiData]);


  const setpartValue = (event) => {
    if (event.target.name == "two_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { two_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_two") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_two, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_three") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_three, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_four") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_four, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_five") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_five, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_six") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_six, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "wit") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { wit, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    }
  };

  const setvarianceValue = (event) => {
    if (event.target.name == "charge") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_charge,
      });
      const { charge, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_charge);
      setTotalVar(sum);
    } else if (event.target.name == "cna") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_cna,
      });
      const { cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_cna);
      setTotalVar(sum);
    } else if (event.target.name == "orientation") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.orientation,
      });
      const { orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.orientation);
      setTotalVar(sum);
    } else if (event.target.name == "nurses") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_nurses,
      });
      const { nurses, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_nurses);
      setTotalVar(sum);
    } else if (event.target.name == "res_brk_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_res_brk_rn,
      });
      const { res_brk_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_res_brk_rn);
      setTotalVar(sum);
    } else if (event.target.name == "unit_sec") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.unit_sec,
      });
      const { unit_sec, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.unit_sec);
      setTotalVar(sum);
    } else if (event.target.name == "sitter") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.sitter,
      });
      const { sitter, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.sitter);
      setTotalVar(sum);
    }
  };

  const setRnHourValue = (event) => {
    if (event.target.name == "two_one") {
      const rnHourSum = 2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { two_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_one") {
      const rnHourSum = 1 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
      //setFormdata(...formdata, one_one);
    } else if (event.target.name == "one_two") {
      const rnHourSum = 0.5 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_two, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_three") {
      const rnHourSum = 0.33 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_three, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_four") {
      const rnHourSum = 0.25 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_four, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_five") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_five, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_six") {
      const rnHourSum = 0.16666 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_six, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses * shiftLength - sum * 4;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "nurses") {
      const { ...rest } = rnAvailableData;
      const sum =
        Number(event.target.value) * shiftLength -
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) * 4;
      setRnTotal(sum?.toFixed(2));
    }
  };

   const previousdataload = () => {
     //console.log("updated data", contentone);
     setFormdata(contentone);
   };

  const getFirstEmptyKey = (formdata) => {
    const keysToIgnore = [
      "cost_center_number",
      "shift_date",
      "shift_time",
      "total_patients",
      "id",
      "currentShiftNumber",
    ];

    const emptyKeys = Object.keys(formdata)
      .filter((key) => !keysToIgnore.includes(key))
      .filter(
        (key) =>
          formdata[key] === undefined ||
          formdata[key] === null ||
          formdata[key] === ""
      );

    if (emptyKeys.length > 1) {
      return `Please Enter Missing Data`;
    } else if (emptyKeys.length === 1) {
      return (
        "Please Enter Missing" + " " + SHIFT_FORM.VALIDATION_NAMES[emptyKeys[0]]
      );
    } else {
      return false;
    }
  };

  const submitHandle = async () => {
    let submitdata = formdata;
    let ischecked = [];
    submitdata["id"] = "";
    submitdata["grid_variance"] = totalvariance;
    submitdata["rn_available_hours"] = rnTotal;
    submitdata["wit_rn"] = witRn;
    submitdata["charge_variance"] = variance?.charge;
    submitdata["cna_variance"] = variance?.cna;
    submitdata["nurses_variance"] = variance?.nurses;
    submitdata["res_brk_rn_variance"] = variance?.res_brk_rn;
    submitdata["sitter_variance"] = variance?.sitter;
    submitdata["unit_sec_variance"] = variance?.unit_sec;
    submitdata["orientation_variance"] = variance?.orientation;

    let timestamp = activedatefilter?.shift_date
      ? activedatefilter.shift_date.split("/")
      : [];
    timestamp =
      timestamp.length > 0
        ? `${timestamp[1]}-${timestamp[0]}-${timestamp[2]}`
        : "";
    submitdata["cost_center_number"] = cost_center_number;
    submitdata["shift_date"] = timestamp; //getprevAndCurrentShift.currentShiftDateDash;
    submitdata["shift_time"] = activedatefilter?.shift_time + ":00"; //getprevAndCurrentShift.currentShiftTime;

    const checkval = Object.keys(submitdata).map((key, index) => {
      if (key != "id" && key != "currentShiftNumber") {
        if (submitdata[key] === null || submitdata[key] === "") {
          ischecked.push(false);
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          if (el != null) {
            setFormdata((prevFormdata) => ({
              ...prevFormdata,
              [key]: "",
            }));
            el.classList.add("redborder");
            const getResult = getFirstEmptyKey(formdata);
            setIsKeyEmpty(getResult);

            // const isAnyKeyEmptyOrNull = Object.keys(formdata).filter(key => !keysToIgnore.includes(key)).some(key => formdata[key] === "" || formdata[key] === null);
            // setIsKeyEmpty(isAnyKeyEmptyOrNull);
          }
        } else {
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          if (el != null) {
            el.classList.remove("redborder");
          }
          ischecked.push(true);
        }
      }
    });
    const checked = ischecked.every((element) => element === true);
    if (!checked) {
      return false;
    }

    if (+submitdata?.total_patients_two !== submitdata?.total_patients) {
      setIsKeyEmpty(
        "Please check Total Patients and Patient Acuity. Values do not match."
      );
      return false;
    }

    try {
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/addupdateDirectEntery",
        submitdata
      );
      const { data } = await resp;
      setSuccessmsg(data.message);
      socket.emit("csvAdd", { csvAdd: true, csvUpdate: false });

      reloaddata(data?.DirectEntery?._id);
      setFormdata(init);
      setTotalPatientVar(0);
      setVariance({});
      setTotalVar(0);
      callbackfunc(false, true, data.message);
    } catch (error) {
      console.log(error);
      setShowError(true);
      setSuccessmsg(error.response.data.message);
    }
  };

    const getDirectDataById = async (title, sum, event) => {
      try {
        //alert(title)
        // console.log(previousShiftData._id, "contentId");
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getDirectDataById",
          { id: directDataApiData?._id }
        );
        const { data } = await resp;
        const comment = data?.data?.directData?.comment;

        if (title !== "Grid") {
          if (title == "Staffing") {
            setDirectData(data.data);
            let StaffingDataObj = {
              charge:
                data?.data?.directData?.charge -
                (gridData?.required_charge ? gridData?.required_charge : 0),
              cna:
                data?.data?.directData?.cna -
                (gridData?.required_cna ? gridData?.required_cna : 0),
              orientation:
                data?.data?.directData?.orientation -
                (gridData?.orientation ? gridData?.orientation : 0),
              nurses:
                data?.data?.directData?.nurses -
                (gridData?.required_nurses ? gridData?.required_nurses : 0),
              res_brk_rn:
                data?.data?.directData?.res_brk_rn -
                (gridData?.required_res_brk_rn
                  ? gridData?.required_res_brk_rn
                  : 0),
              sitter:
                data?.data?.directData?.sitter -
                (gridData?.sitter ? gridData?.sitter : 0),
              unit_sec:
                data?.data?.directData?.unit_sec -
                (gridData?.unit_sec ? gridData?.unit_sec : 0),
            };
            let VarianceDataObj = {
              charge: data?.data?.directData?.charge,
              cna: data?.data?.directData?.cna,
              orientation: data?.data?.directData?.orientation,
              nurses: data?.data?.directData?.nurses,
              res_brk_rn: data?.data?.directData?.res_brk_rn,
              sitter: data?.data?.directData?.sitter,
              unit_sec: data?.data?.directData?.unit_sec,
            };
            setFormdata({
              ...formdata,
              ...VarianceDataObj,
              total_patients: totalPatientvariance,
            });
            setVariance(StaffingDataObj);
            setRnTotal(
              (
                data?.data?.directData?.nurses * shiftLength -
                rnTotal * 4
              )?.toFixed(2)
            );
            setTotalVar(
              Object.values(StaffingDataObj).reduce((a, b) => a + b, 0)
            );
          }

          if (title == "PatientData") {
            setPatientData(data?.data?.directData);
            let patientDataObj = {
              total_patients_two: data?.data?.directData?.total_patients_two,
              acuity: data?.data?.directData?.acuity,
              wit: data?.data?.directData?.wit,
              two_one: data?.data?.directData?.two_one,
              one_one: data?.data?.directData?.one_one,
              one_two: data?.data?.directData?.one_two,
              one_three: data?.data?.directData?.one_three,
              one_four: data?.data?.directData?.one_four,
              one_five: data?.data?.directData?.one_five,
              one_six: data?.data?.directData?.one_six,
            };
            const { acuity, total_patients_two, ...radio } = patientDataObj;
            setPatientData(radio);
            setTotalPatientVar(
              data?.data?.directData?.two_one +
                data?.data?.directData?.one_one +
                data?.data?.directData?.one_two +
                data?.data?.directData?.one_three +
                data?.data?.directData?.one_four +
                data?.data?.directData?.one_five +
                data?.data?.directData?.one_six +
                data?.data?.directData?.wit
            );

            //Rn variable calculation - Admin
            let RnHours = {
              two_one: 2 * Number(data?.data?.directData?.two_one),
              one_one: 1 * Number(data?.data?.directData?.one_one),
              one_two: 0.5 * Number(data?.data?.directData?.one_two),
              one_three: 0.33 * Number(data?.data?.directData?.one_three),
              one_four: 0.25 * Number(data?.data?.directData?.one_four),
              one_five: 0.2 * Number(data?.data?.directData?.one_five),
              one_six: 0.16666 * Number(data?.data?.directData?.one_six),
            };
            setRnAvailableData(RnHours);
            setRnTotal(
              (
                2 * Number(data?.data?.directData?.two_one) +
                1 * Number(data?.data?.directData?.one_one) +
                0.5 * Number(data?.data?.directData?.one_two) +
                0.33 * Number(data?.data?.directData?.one_three) +
                0.25 * Number(data?.data?.directData?.one_four) +
                0.2 * Number(data?.data?.directData?.one_five) +
                0.16666 * Number(data?.data?.directData?.one_six)
              )?.toFixed(2)
            );

            setFormdata({
              ...formdata,
              ...patientDataObj,
              total_patients: totalPatientvariance,
            });
          }

          if (title == "AdditionalDataTracking") {
            let obj = {
              ant_admit: data?.data?.directData?.ant_admit,
              actual_admit: data?.data?.directData?.actual_admit,
              telemetry: data?.data?.directData?.telemetry,
              vaso_active_drips: data?.data?.directData?.vaso_active_drips,
              isolation_patients: data?.data?.directData?.isolation_patients,
              of_isolation: data?.data?.directData?.of_isolation,
              of_rrs_codes: data?.data?.directData?.of_rrs_codes,
              of_video_monitors: data?.data?.directData?.of_video_monitors,
            };
            setFormdata({
              ...formdata,
              ...obj,
              total_patients: totalPatientvariance,
            });
          }
        } else {
          // let el = document.querySelectorAll('.apicall');
          // console.log(event);
          // if(event != undefined){
          //     if(event.target.value != ''){
          //         console.log(el, 'querySelectorAll');
          //         el.forEach(item => item.setAttribute('disabled', true));
          //     }
          // }
          const getgridparams = {
            cost_center_number: cost_center_number,
            shift_time: activedatefilter.shift_time + ":00", //getPreAndCurr.currentShiftTime,
            census: sum != null ? sum : totalPatientvariance,
          };

          let addgriddata = await axios.post(
            API_CALL_URL_PRIVATE + "/department/getGridData",
            getgridparams
          );
          let addgridresult = await addgriddata;
          const gridresult = addgridresult.data.data.gridData;
          setGridData(gridresult);
          setDirectData(data.data);
          let VarianceDataObj = {};
          
          let StaffingDataObj = {
            charge:
              (gridresult?.required_charge ||
                gridresult?.required_charge === 0) &&
              (formdata.charge || formdata.charge === 0)
                ? parseInt(formdata?.charge) - gridresult?.required_charge
                : 0,
            cna:
              (gridresult?.required_cna || gridresult?.required_cna === 0) &&
              (formdata.cna || formdata.cna === 0)
                ? parseInt(formdata?.cna) - gridresult?.required_cna
                : 0,
            orientation:
              (gridresult?.orientation || gridresult?.orientation === 0) &&
              (formdata?.orientation || formdata?.orientation === 0)
                ? parseInt(formdata?.orientation) - gridresult?.orientation
                : 0,
            nurses:
              (gridresult?.required_nurses ||
                gridresult?.required_nurses === 0) &&
              (formdata?.nurses || formdata?.nurses === 0)
                ? parseInt(formdata?.nurses) - gridresult?.required_nurses
                : 0,
            res_brk_rn:
              (gridresult?.required_res_brk_rn ||
                gridresult?.required_res_brk_rn === 0) &&
              (formdata?.res_brk_rn || formdata?.res_brk_rn === 0)
                ? parseInt(formdata?.res_brk_rn) -
                  gridresult?.required_res_brk_rn
                : 0,
            sitter:
              (gridresult?.sitter || gridresult?.sitter === 0) &&
              (formdata?.sitter || formdata?.sitter === 0)
                ? parseInt(formdata?.sitter) - gridresult?.sitter
                : 0,
            unit_sec:
              (gridresult?.unit_sec || gridresult?.unit_sec === 0) &&
              (formdata?.unit_sec || formdata?.unit_sec === 0)
                ? parseInt(formdata?.unit_sec) - gridresult?.unit_sec
                : 0,
          };
          // console.log('StaffingDataObj', StaffingDataObj);
          // console.log("gridresult", gridresult);

          if (event) {
            VarianceDataObj = {
              charge: "",
              cna: "",
              orientation: "",
              nurses: "",
              res_brk_rn: "",
              sitter: "",
              unit_sec: "",
            };
          } else {
            VarianceDataObj = {
              charge: gridresult?.required_charge
                ? gridresult?.required_charge
                : 0,
              cna: gridresult?.required_cna ? gridresult?.required_cna : 0,
              orientation: gridresult?.orientation
                ? gridresult?.orientation
                : 0,
              nurses: gridresult?.required_nurses
                ? gridresult?.required_nurses
                : 0,
              res_brk_rn: gridresult?.required_res_brk_rn
                ? gridresult?.required_res_brk_rn
                : 0,
              sitter: gridresult?.sitter ? gridresult?.sitter : 0,
              unit_sec: gridresult?.unit_sec ? gridresult?.unit_sec : 0,
            };
          }

          if (event) {
            const updateFormdata = async () => {
              return new Promise((resolve) => {
                setFormdata((prevFormdata) => {
                  const updatedFormdata = {
                    ...prevFormdata,
                    total_patients: sum,
                    [event.target.name]: event.target.value,
                  };

                  resolve(updatedFormdata);
                  return updatedFormdata;
                });
              });
            };

            await updateFormdata();
          } else {
            setFormdata({
              ...formdata,
              ...VarianceDataObj,
              total_patients: sum,
            });
          }
          // setVariance({});
          setVariance(StaffingDataObj);
          setTotalVar(
            Object.values(StaffingDataObj).reduce((a, b) => a + b, 0)
          );
          //el.forEach(item => item.removeAttribute('disabled'));
        }
      } catch (error) {
        console.log(error);
      }
    };

  const PreviousgetDirectData = async (title) => {
    try {
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getDirectDataById",
        contentId
      );
      const { data } = await resp;
      if (title == "PatientData") {
        setpreviousPatientData(data?.data?.directData);
      }

      if (title == "formData") {
        // setPreviousFormData(data?.data?.directData)
      }

      if (title == "AdditionalDataTracking") {
        setPreviousAdditionalDataTracking(data?.data?.directData);
      }

      setSuccessmsg(data.message);
      callbackfunc(false, true, data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // PreviousgetDirectData("PatientData")
    // PreviousgetDirectData("formData")
    // PreviousgetDirectData("AdditionalDataTracking")
  }, []);

  const modalsshide = () => {
    setFormdata(init);
    setTotalPatientVar(0);
    setVariance({});
    setTotalVar(0);
    reloaddata();
    callbackfunc(false, false, successMsg);
  };

  // for admin
  if (userType !== 0) {
    gridDataApiData = {
      ...gridDataApiData,
      dc_nurse: 0,
      orientation: 0,
      required_charge: 0,
      required_cna: 0,
      required_nurses: 0,
      required_res_brk_rn: 0,
      sitter: 0,
      unit_sec: 0,
    };
  }

    useEffect(() => {
      const witValue = formdata?.wit;
      const rnTotalValue = formdata?.nurses;

      if (isNaN(witValue) || isNaN(rnTotalValue) || rnTotalValue === 0) {
        setWitRn(0);
      } else {
        const witRnDivider = witValue / rnTotalValue;
        if (isFinite(witRnDivider)) {
          const roundedValue = +witRnDivider.toFixed(2);
          setWitRn(roundedValue);
        } else {
          setWitRn(0);
        }
      }
    }, [formdata?.wit, formdata?.nurses]);

  // const isAtLeastOneKeyEmptyOrNull = Object.keys(formdata).some(key => formdata[key] === "" || formdata[key] === null);

  const callBacksuccess = () => {};
  return (
    <Modal show={isShow} onHide={modalsshide} centered>
      <div className=" modal-dialog-centered modal-lg">
        {defaultMode ? (
          <div className="modal-content pb-5">
            <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
              <h3 className="modal-title fw-400">
                Direct Entry Tool is disabled.
              </h3>
              <button
                type="button"
                onClick={modalsshide}
                className="btn-close ms-5 position-absolute right-20 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body"></div>
          </div>
        ) : (
          <div className="modal-content pb-4">
            <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5 ">
              <h5 className="modal-title fw-400">
                Submitting - {cost_center_name?.toUpperCase()}&nbsp;-&nbsp;
                <span className="">
                  {activedatefilter?.shift_date} {activedatefilter?.shift_time}{" "}
                  INTERVAL
                  {/* getprevAndCurrentShift?.currentDateShift */}{" "}
                  {/* getprevAndCurrentShift?.currentShift */}
                </span>
              </h5>
              {/* <!-- <button type="button" className="ms-auto btn btn-blue text-capitalize f-12 fw-500"><i className="fa fa-plus-circle" aria-hidden="true"></i> Pull From Previous Shift (7AM)</button> --> */}
              <button
                type="button"
                onClick={modalsshide}
                className="btn-close ms-5 position-absolute right-20 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body inputnumbericonhide py-0 para-5">
              <div className="row">
                <div className="col-md-3 border-end w1">
                  <div className=" text-center">
                    <h6 className="fw-400 fw lh-1 primary-font">
                      Patient Data
                    </h6>
                  </div>
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        <th>
                          {userType === 0 ? "Previous" : ""}
                          {/* <br /> {getprevAndCurrentShift?.previousShift} */}
                        </th>
                        <th>{activedatefilter?.shift_time}</th>{" "}
                        {/* {getprevAndCurrentShift?.currentShift}  */}
                      </tr>
                      {process.env.React_App_ENVIRONMENT === "development" && (
                        <tr>
                          <td className="t">Wit</td>
                          <td class="img">
                            <span class="circle-i">WIT</span>
                          </td>
                          <td>
                            {userType === 0 && (
                              <input
                                className="form-control"
                                name="wit"
                                value={directDataApiData?.wit}
                                type="text"
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.wit === "" ? "redborder" : ""
                              }`}
                              name="wit"
                              value={formdata?.wit !== null ? formdata.wit : ""}
                              type="number"
                              onChange={handleChange}
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="t">Acuity</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? AcuityIcon1
                                : AcuityIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="acuity1"
                              value={directDataApiData?.acuity}
                              type="text"
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.acuity === "" ? "redborder" : ""
                            }`}
                            name="acuity"
                            value={
                              formdata?.acuity !== null ? formdata.acuity : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Total Patients</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? BedIcon1 : BedIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="total_patients_two"
                              value={directDataApiData?.total_patients_two}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.total_patients_two === ""
                                ? "redborder"
                                : ""
                            }`}
                            name="total_patients_two"
                            value={
                              formdata?.total_patients_two !== null
                                ? formdata.total_patients_two
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">2:1</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="two_one"
                              value={
                                directDataApiData?.two_one
                                  ? directDataApiData?.two_one
                                  : 0
                              }
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.two_one === "" ? "redborder" : ""
                            }`}
                            name="two_one"
                            value={
                              formdata?.two_one !== null ? formdata.two_one : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:1</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="one_one"
                              value={directDataApiData?.one_one}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_one === "" ? "redborder" : ""
                            }`}
                            name="one_one"
                            value={
                              formdata?.one_one !== null ? formdata.one_one : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:2</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="one_two1"
                              value={directDataApiData?.one_two}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_two === "" ? "redborder" : ""
                            }`}
                            name="one_two"
                            value={
                              formdata?.one_two != null ? formdata.one_two : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:3</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="one_three1"
                              value={directDataApiData?.one_three}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_three === "" ? "redborder" : ""
                            }`}
                            name="one_three"
                            value={
                              formdata?.one_three !== null
                                ? formdata?.one_three
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:4</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="one_four1"
                              value={directDataApiData?.one_four}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_four === "" ? "redborder" : ""
                            }`}
                            name="one_four"
                            value={
                              formdata?.one_four != null
                                ? formdata?.one_four
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:5</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="one_five1"
                              value={directDataApiData?.one_five}
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_five === "" ? "redborder" : ""
                            }`}
                            name="one_five"
                            value={
                              formdata?.one_five != null
                                ? formdata?.one_five
                                : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="t">Patient Acuity</td>
                        <td class="img">
                          <span class="circle-i">1:6</span>
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              name="one_six"
                              value={
                                directDataApiData?.one_six
                                  ? directDataApiData?.one_six
                                  : 0
                              }
                              type="text"
                              disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control apicall ${
                              formdata?.one_six === "" ? "redborder" : ""
                            }`}
                            name="one_six"
                            value={
                              formdata?.one_six != null ? formdata?.one_six : ""
                            }
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Total Patients</td>
                        <td class="img">
                          {/* <img src={BedIcon} alt="" /> */}
                          <img
                            src={
                              themeMode.theme === "DARK" ? BedIcon1 : BedIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("PatientData");
                                }}
                                className="arrowicon"
                              >
                                <img src={RightIcon} />
                              </div>
                              <span className="tooltiptext">
                                {directDataApiData?._id
                                  ? "Fill from previous shift?"
                                  : "No Data In Previous Shift"}
                              </span>
                            </button>
                          )}
                        </td>
                        <td>
                          <hr className="totalline my-1" />
                          <div
                            type="button"
                            className={`tooltip left-tool position-relative ${
                              totalPatientvariance < 0 ? "textdanger" : ""
                            }`}
                          >
                            {totalPatientvariance}
                            <span class="tooltiptext">Total</span>
                          </div>
                        </td>
                      </tr>
                      {process.env.React_App_ENVIRONMENT === "development" && (
                        <tr>
                          <td className="t ml-4">RN Available Hours:</td>
                          <td></td>
                          <td></td>
                          <td>
                            <input
                              className="form-control"
                              // name="one_six"
                              value={rnTotal}
                              type="text"
                              // disabled
                              onChange={handleChange}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        </tr>
                      )}
                      {process.env.React_App_ENVIRONMENT === "development" && (
                        <tr>
                          <td className="t ml-4">WIT/RN:</td>
                          <td></td>
                          <td></td>
                          <td>
                            <input
                              className="form-control"
                              // name="one_six"
                              value={witRn}
                              type="text"
                              // disabled
                              // onChange={handleWitRn}
                              aria-label=" input example"
                              readonly
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div class="col mt-4">
                    {/* <div class="f-14 text-uppercase text-start">
                    Submitted By: PerfectShift
                  </div> */}
                  </div>
                </div>
                <div className="col-md-5 w2">
                  <div className=" text-center">
                    <h6 className="fw-400 fw lh-1">Staffing</h6>
                  </div>
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        <th>
                          {userType === 0 ? "Previous" : ""}
                          {/* <br /> {getprevAndCurrentShift?.previousShift} */}
                        </th>
                        <th>{activedatefilter?.shift_time}</th>{" "}
                        {/* getprevAndCurrentShift?.currentShift */}
                        <th>Grid</th>
                        <th>Variance</th>
                      </tr>
                      <tr>
                        <td className="t">Charge:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? ChargeIcon1
                                : ChargeIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.charge}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            name="charge"
                            value={
                              formdata?.charge != null ? formdata?.charge : ""
                            }
                            className={`form-control ${
                              formdata?.charge === "" ? "redborder" : ""
                            }`}
                            onChange={handleChange}
                            type="number"
                            aria-label="input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridData?.required_charge
                                ? gridData?.required_charge
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>
                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.charge > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.charge}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Charge</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">CNA:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? CnaIcon1 : CnaIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.cna}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.cna === "" ? "redborder" : ""
                            }`}
                            name="cna"
                            value={formdata?.cna != null ? formdata?.cna : ""}
                            type="number"
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridData?.required_cna
                                ? gridData?.required_cna
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>

                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.cna > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.cna}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">CNA</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Orientation:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? OrientationIcon1
                                : OrientationIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.orientation}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.orientation === "" ? "redborder" : ""
                            }`}
                            name="orientation"
                            type="number"
                            value={
                              formdata?.orientation != null
                                ? formdata?.orientation
                                : ""
                            }
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridData?.orientation ? gridData?.orientation : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>

                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.orientation > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.orientation}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Orientation</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">RN:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? NurseIcon1
                                : NurseIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.nurses}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.nurses === "" ? "redborder" : ""
                            }`}
                            name="nurses"
                            type="number"
                            value={
                              formdata?.nurses != null ? formdata?.nurses : ""
                            }
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridData?.required_nurses
                                ? gridData?.required_nurses
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>

                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.nurses > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.nurses}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">RN</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Res/Brk RN:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? ReseIcon1 : ReseIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.res_brk_rn}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.res_brk_rn === "" ? "redborder" : ""
                            }`}
                            name="res_brk_rn"
                            type="number"
                            value={
                              formdata?.res_brk_rn != null
                                ? formdata?.res_brk_rn
                                : ""
                            }
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={
                              gridData?.required_res_brk_rn
                                ? gridData?.required_res_brk_rn
                                : 0
                            }
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>

                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.res_brk_rn > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.res_brk_rn}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Res/Brk RN</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Unit Sec:</td>
                        <td class="img">
                          <img
                            src={
                              themeMode.theme === "DARK" ? UnitIcon1 : UnitIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.unit_sec}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.unit_sec === "" ? "redborder" : ""
                            }`}
                            name="unit_sec"
                            type="number"
                            value={
                              formdata?.unit_sec != null
                                ? formdata?.unit_sec
                                : ""
                            }
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={gridData?.unit_sec ? gridData?.unit_sec : 0}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>

                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.unit_sec > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.unit_sec}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Unit Sec</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t">Sitter:</td>
                        <td class="img last-i">
                          <img
                            src={
                              themeMode.theme === "DARK"
                                ? SitterIcon1
                                : SitterIcon
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          {userType === 0 && (
                            <input
                              className="form-control"
                              type="text"
                              value={directDataApiData?.sitter}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                          )}
                        </td>
                        <td>
                          <input
                            className={`form-control ${
                              formdata?.sitter === "" ? "redborder" : ""
                            }`}
                            name="sitter"
                            type="number"
                            value={
                              formdata?.sitter != null ? formdata?.sitter : ""
                            }
                            onChange={handleChange}
                            aria-label=" input example"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={gridData?.sitter ? gridData?.sitter : 0}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                        </td>

                        <td>
                          <div
                            type="button"
                            class="tooltip left-tool position-relative"
                          >
                            <input
                              className={`form-control ${
                                variance?.sitter > 0 ? "red-text" : ""
                              }`}
                              type="text"
                              value={variance?.sitter}
                              disabled
                              aria-label=" input example"
                              readonly
                            />
                            <span class="tooltiptext">Sitter</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="t"></td>
                        <td></td>
                        <td className="pt-2">
                          {userType === 0 && (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("Staffing");
                                }}
                                className="arrowicon"
                              >
                                <img src={RightIcon} />
                              </div>
                              <span className="tooltiptext">
                                {directDataApiData?._id
                                  ? "Fill from previous shift?"
                                  : "No Data In Previous Shift"}
                              </span>
                            </button>
                          )}
                        </td>
                        <td></td>
                        <td className="pt-2">
                          {/* {totalPatientvariance > 0 ? ( */}
                          {userType === 0 && totalvariance > 0 ? (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("Grid");
                                }}
                                className="arrowicon"
                              >
                                <img src={LeftIcon} />
                              </div>
                              <span className="tooltiptext">
                                Use Grid for Actuals?
                              </span>
                            </button>
                          ) : null}
                        </td>
                        <td>
                          <hr className="totalline my-1" />
                          <div
                            type="button"
                            className={`tooltip left-tool position-relative ${
                              totalvariance > 0 ? "textdanger" : ""
                            }`}
                          >
                            {totalvariance}
                            <span class="tooltiptext">Total</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-4 border-start">
                  <div className=" text-center">
                    <h6 className="fw-400 fw lh-1">Additional Data Tracking</h6>
                  </div>
                  <div className="height-417">
                    <table className="w-100 mb-2 m-g-2">
                      <tbody>
                        <tr className="hd">
                          <th></th>
                          <th></th>
                          <th>
                            {userType === 0 ? "Previous" : ""}
                            {/* <br /> {getprevAndCurrentShift?.previousShift} */}
                          </th>
                          <th>{activedatefilter?.shift_time}</th>{" "}
                          {/* getprevAndCurrentShift?.currentShift */}
                        </tr>
                        <tr>
                          <td className="t">
                            # Anticipated Admits/Transfers in this Shift
                          </td>
                          <td class="img">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? AdmitsIcon1
                                  : AdmitsIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            {userType === 0 && (
                              <input
                                className="form-control"
                                type="text"
                                value={directDataApiData?.ant_admit}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.ant_admit === "" ? "redborder" : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="ant_admit"
                              value={
                                formdata?.ant_admit != null
                                  ? formdata?.ant_admit
                                  : ""
                              }
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">
                            # Actual Admits/Transfers in previous Shift
                          </td>
                          <td class="img">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? DischargesIcon1
                                  : DischargesIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            {userType === 0 && (
                              <input
                                className="form-control"
                                type="text"
                                value={directDataApiData?.actual_admit}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.actual_admit === "" ? "redborder" : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="actual_admit"
                              value={
                                formdata?.actual_admit != null
                                  ? formdata?.actual_admit
                                  : ""
                              }
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">Telemetry Patients</td>
                          <td class="img">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? TpatientsIcon1
                                  : TpatientsIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            {userType === 0 && (
                              <input
                                className="form-control"
                                type="text"
                                value={directDataApiData?.telemetry}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.telemetry === "" ? "redborder" : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="telemetry"
                              value={
                                formdata?.telemetry != null
                                  ? formdata?.telemetry
                                  : ""
                              }
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">Vaso-Active Drips</td>
                          <td class="img last-i">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? DripsIcon1
                                  : DripsIcon
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            {userType === 0 && (
                              <input
                                className="form-control"
                                type="text"
                                value={directDataApiData?.vaso_active_drips}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.vaso_active_drips === ""
                                  ? "redborder"
                                  : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="vaso_active_drips"
                              value={
                                formdata?.vaso_active_drips != null
                                  ? formdata?.vaso_active_drips
                                  : ""
                              }
                              aria-label=" input example"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="t">Isolation Patients</td>
                          <td class="img last-i">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? IsolationDark
                                  : IsolationLight
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            {userType === 0 && (
                              <input
                                className="form-control"
                                type="text"
                                value={directDataApiData?.isolation_patients}
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            )}
                          </td>
                          <td>
                            <input
                              className={`form-control ${
                                formdata?.isolation_patients === ""
                                  ? "redborder"
                                  : ""
                              }`}
                              type="number"
                              onChange={handleChange}
                              name="isolation_patients"
                              value={
                                formdata?.isolation_patients != null
                                  ? formdata?.isolation_patients
                                  : ""
                              }
                              aria-label=" input example"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="t"></td>
                          <td className="pt-2">
                            {userType === 0 && (
                              <button
                                type="button"
                                className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                              >
                                <div
                                  onClick={() => {
                                    directDataApiData?._id &&
                                      getDirectDataById(
                                        "AdditionalDataTracking"
                                      );
                                  }}
                                  className="arrowicon"
                                >
                                  <img src={RightIcon} />
                                </div>
                                <span className="tooltiptext">
                                  {directDataApiData?._id
                                    ? "Fill from previous shift?"
                                    : "No Data In Previous Shift"}
                                </span>
                              </button>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="m-f14">
                          Comments / Barriers / Action Plan{" "}
                        </div>
                        <div className="mt-1">
                          <textarea
                            className="form-control full"
                            onChange={handleChange}
                            name="comment"
                            value={
                              formdata?.comment != null ? formdata?.comment : ""
                            }
                            placeholder="Type Here..."
                            style={{ height: "70px" }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col">
                      <button
                        type="button"
                        onClick={modalsshide}
                        className="btn btn-red text-capitalize  f-12 fw-400 w-100"
                      >
                        {" "}
                        Cancel
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={submitHandle}
                        className="btn btn-success text-capitalize  f-12 fw-400 w-100"
                      >
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                  {isKeyEmpty && (
                    <div className="real-validation m-f14 mt-2">
                      {isKeyEmpty}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ErrorModal
        isShow={showError}
        title="Error"
        content={successMsg}
        callbackfunc={() => setShowError(false)}
      />
    </Modal>
  );
}

export default AddModal;
