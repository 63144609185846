import { useContext } from "react";

// global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const regNum = /[^a-zA-Z0-9 ]/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var numFeild = /^[+-]?\d+$/;
var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/;
// const { dictionary } = useContext(LanguageContext);
// console.log(messages);
const data = JSON.parse(localStorage.getItem("userData"));
const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};

export const loginValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Username or Email";
  }
  // else if (checkEmail(values.email)) {
  //   errors.email = 'Invalid Email'
  // }
  if (!values.password) {
    errors.password = "Enter Password";
  }
  return errors;
};
export const forgotValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Username or Email";
  }
  return errors;
};
export const loginSSOValidator = (values) => {
  let errors = {};

   if (!values.email) {
     errors.email = "Please Enter Email";
   } 
   else if (checkEmail(values.email)) {
     errors.email = "Please Enter a valid Email";
   }
  return errors;
};
export const otpValidator = (values) => {
  let errors = {};
  if (!values.otp) {
    errors.otp = "Enter OTP";
  } else if (values.otp.length > 4) {
    errors.otp = "Invalid OTP";
  }
  return errors;
};
export const resetValidator = (values, lang) => {
  let errors = {};
  if (!values.otp) {
    errors.otp = "Enter OTP";
  }
  if (!values.password) {
    errors.password = "Enter Password";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password does not match";
  }
  return errors;
};


export const profileValidator = (values) => {
  let errors = {};
  if (!values.employee_name) {
    errors.employee_name = "Enter Employee Name";
  }
  return errors;
};

export const passwordValidator = (values) => {
  let errors = {};
  if (!values.old_password) {
    errors.old_password = "Enter Old Password";
  }
  if (!values.password) {
    errors.password = "Enter New Password";
  }
  if (!values.new_password) {
    errors.new_password = "Enter New Password";
  }
  return errors;
};
