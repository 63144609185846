import "../index.css";
import React from "react";
import { useEffect, useContext, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";

import ContentLoader from "react-content-loader";
import Form from "react-bootstrap/Form";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty, debounce } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import ScheduleBasicChart from "../../../components/ActiveChart/ScheduleBasicChart";
import { getTableRecordWithSorting } from "../../../services";
import {
    setUpdateDate,
    setUpdateIndex,
    setUpdateStack,
    setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import Bi4 from "../../../assets/img/overtime/scheduling.svg";
import Select from "react-select";
import Button from "@atlaskit/button";
import {
    CustomDropdown,
    DropdownIndicator,
    ChevronDown,
} from "../../../Styled/DropdownStyle";
import {
    capitalizeAllLetters,
    formatNumberWithFraction,
} from "../../../utils/helper";
import SHIFT_FORM from "../../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";

const HeaderLoader = () => (
    <ContentLoader viewBox="0 0 280 17">
        {/* Only SVG shapes */}
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
    </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
    <ContentLoader
        viewBox="0 0 280 10"
        backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
        foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
        {/* Only SVG shapes */}
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
    </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
    <ContentLoader
        viewBox="0 0 280 8"
        style={{ padding: "0" }}
        backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
        foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
        {/* Only SVG shapes */}
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
    </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
    <ContentLoader
        viewBox="0 0 280 230"
        backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
        foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
        {/* Only SVG shapes */}
        <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="230" />
    </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
    <div style={{ width: 40, height: 20 }}>
        <ContentLoader
            viewBox="0 0 80 50"
            backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
            foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
        >
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
        </ContentLoader>
    </div>
);

function ActiveManagement({ history }) {
    const dispatch = useDispatch();
    const buisnessHeader = useSelector(
      (state) => state.compensate.buisnessHeader
    );
    const location = useLocation();
    const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
    const { state } = location;
    const [themeMode, setTheme] = useContext(ThemeContext);
    const [modalShow, setModalShow] = React.useState(false);
    const [footerShow, setFooterShow] = React.useState(false);
    const [tableData, setTableData] = useState([]);
    const [headData, setHeadData] = useState([]);
    const [totalvalue, setTotalvalue] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isGraphLoading, setIsGraphLoading] = useState(true);
    const [isGraphLoadingTwo, setIsGraphLoadingTwo] = useState(false);
    const [graph1Data, setGraph1Data] = useState([]);
    const [graph2Data, setGraph2Data] = useState([]);
    const [sortType, setSortType] = useState("asc");
    const [sortName, setsortName] = useState("");
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(null);
    const [clickCount, setClickCount] = useState(0);
    const [emptyDate, setEmptyDate] = useState(false);
    const [graphMaxData, setGraphMaxData] = useState("");
    const [graphMaxtwo, setGraphMaxtwo] = useState("");
    const [metaData, setMetaData] = useState([]);
    const [scheduleData, setScheduleData] = useState([]);
    const [searchBoxValue, setSearchBoxValue] = useState("");
    const [costCenterList, setCostCenterList] = useState([]);
    const momoDate = useSelector((state) => state.compensate.updateDate);
    const momoIndex = useSelector((state) => state.compensate.updatedIndex);
    const exceptionAge = useSelector((state) => state.compensate.exceptionAge);
    const exceptionIndex = useSelector(
        (state) => state.compensate.exceptionIndex
    );
    const [graphData, setGraphData] = useState({});
    const [handleGraph, setHandleGraph] = useState(true);
    //   const [selectedOption, setSelectedOption] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownValue, setDropdownValue] = useState(undefined);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [filterDateList, setFilterDateList] = useState([]);
    const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
    const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
    const [graphDate, setGraphDate] = useState(null);
    const [cancelToken, setCancelToken] = useState(null);


    const getTableData = async (
      pyDate,
      searchKey,
      filterDate,
      costCenter,
      cancelToken
    ) => {
      setIsTableLoading(true);

      try {
         const paramData = {
           payperiod_end_date: pyDate ? pyDate : dropdownValueTwo?.value,
           searchKeyword: searchKey,
           filter_date: filterDate ? filterDate : graphDate,
           cost_center_number: costCenter ? costCenter : null,
           businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
         };
        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/schedule/getData`,
          { payloadData: encryptData },
          { cancelToken: cancelToken }
        );
        const data = decryptedFunction(response?.data?.responseData);
        if (data?.data?.tableData) {
          setTableData(data?.data?.tableData);
          setIsTableLoading(false);
        }
      } catch (error) {
        setIsTableLoading(false);
        console.log(error);
      }
    };
    const getHeadData = async (e, costCenter, pyDate) => {
        setIsLoading(true);
        const parData = {
            filter_date: e,
            cost_center_number: costCenter ? costCenter : null,
        };

        if(state?.urldate) {
          parData.payperiod_end_date = pyDate ? pyDate : state?.urldate?.value;
        } else {
          parData.payperiod_end_date = pyDate
        }

        const encryptData = dataEnCrypt(parData);

        try {
            const response = await axios.post(
              `${API_CALL_URL_PRIVATE}/schedule/getheaderData`,
              { payloadData: encryptData }
            );
            const data = decryptedFunction(response?.data?.responseData);
            setIsLoading(false);
            setHeadData(data.data);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    const getChartFirst = async (e, costCenter) => {
        setIsGraphLoading(true);
        const parData = {
          payperiod_end_date: e,
          cost_center_number: costCenter ? costCenter : null,
          businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
        };
        const encryptData = dataEnCrypt(parData);
        try {
            const url = `${API_CALL_URL_PRIVATE}/schedule/getGraphData`;
            const response = await axios.post(url, {
              payloadData: encryptData,
            });
             const data = decryptedFunction(response?.data?.responseData);

            setGraphData(data?.graphData);
            setIsGraphLoading(false);
            // setGraphMaxData(data.data.graph_max_val);
        } catch (error) {
            console.log(error);
        } finally {
            setIsGraphLoading(false);
        }
    };

    useEffect(() => {
        dispatch(setUpdateDate(""));
        dispatch(setUpdateIndex(""));
        dispatch(setUpdateIndex(""));
        dispatch(setExceptionIndex(""));
        setClickCount(0);
        getFilterDate();
        getCostCenterList();
        getHeadData();
        getRefreshSchedule();
        getMetaData();
    }, []);

    // useEffect(() => {
    //   getTableData(momoDate, selectedRow, exceptionAge);
    //   getHeadData(momoDate, selectedRow, exceptionAge);
    // }, [momoDate]);

    // useEffect(() => {
    //   getTableData(momoDate, selectedRow, exceptionAge);
    //   getHeadData(momoDate, selectedRow, exceptionAge);
    //   getChartFirst(momoDate, selectedRow, exceptionAge);
    // }, [exceptionAge]);

    const returnZero = (val) => {
        if (val) {
            const empWithCriticalException = val;
            const formattedValue = empWithCriticalException?.toFixed(1);
            return formattedValue;
        } else {
            return 0;
        }
    };

    const sortingHandle = (key) => {
        const sortdata = getTableRecordWithSorting(tableData, key, sortType);
        setSortType(sortType === "asc" ? "desc" : "asc");
        setTableData(sortdata);
        setsortName(key);
        activityLog(SHIFT_FORM.SCHEDULE_SUMMARY_TABLE[key] ?? "", sortType);
    };

    const emptyActiveData = () => {
        getTableData();
        getHeadData();
        getChartFirst();
        setSelectedRow(null);
        dispatch(setUpdateDate(""));
        dispatch(setUpdateIndex(""));
        dispatch(setExceptionIndex(""));
        dispatch(setUpdateStack(""));
    };

    const handleDoubleExceptional = (val) => {
        // navigate(
        //   `/activedailymanagement/adm-summary/${val?.cost_center_slug}`
        // );
        setSelectedRow(val);
        setClickCount(clickCount + 1);

        if (clickCount === 0) {
            setSelectedRow(val);
            getTableData(dropdownValueTwo?.value, searchBoxValue, graphDate, val?.cost_center_number);
            getChartFirst(dropdownValueTwo?.value, val?.cost_center_number);
            getHeadData(graphDate, val?.cost_center_number, dropdownValueTwo?.value);
            // momoIndex ? OppositeCosHandle(val?.cost_center_number) : fetchSingleData(val?.cost_center_number);

        } else if (clickCount === 1) {
            if (selectedRow?._id !== val?._id) {
                setSelectedRow(val);

                getTableData(dropdownValueTwo?.value, searchBoxValue, graphDate, val?.cost_center_number);
                getChartFirst(dropdownValueTwo?.value, val?.cost_center_number);
                getHeadData(graphDate, val?.cost_center_number, dropdownValueTwo?.value);

                // momoIndex ? OppositeCosHandle(val?.cost_center_number) : fetchSingleData(val?.cost_center_number);
                setClickCount(1);
            } else {
                navigate(
                  `/activedailymanagement/schedule-details/${val?.slug}`,
                  {
                    state: {
                      urldate: {
                        label: dropdownValueTwo?.label,
                        value: dropdownValueTwo?.value,
                      },
                      homeslug: state?.urlslug === "homepage" ? true : false,
                    },
                  }
                );
            }
        } else {
            console.log("third click")
        }
    };

    const fetchSingleData = async (e) => {
        getTableData(dropdownValueTwo?.value, searchBoxValue, graphDate);
        // getHeadData(momoDate, e, exceptionAge);
        // getChartFirst(momoDate, e, exceptionAge);
    };

    const OppositeCosHandle = async (e) => {
        await getHeadData(momoDate, e, exceptionAge);
        // await getChartData(momoDate, e)
        await getTableData(momoDate, e, exceptionAge);
    };

    const getMetaData = async () => {
        try {
            const param = {
              application_id: 105,
            };
            const encryptData = dataEnCrypt(param);
            const response = await axios.post(
              `${API_CALL_URL_PRIVATE}/metaData`,
              {
                payloadData: encryptData,
              }
            );
            const data = decryptedFunction(response?.data?.responseData);
            setMetaData(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        document.title = metaData?.meta_title || "Active Daily Management";
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = `${metaData?.favicon}` || PsLogo;
    }, [metaData]);

    const getRefreshSchedule = async (refreshType) => {
      try {
        const parData = {};
        if (refreshType) {
          parData.clicked = 1;
        }

        const encryptData = dataEnCrypt(parData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        setScheduleData(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const toggleOpen = () => {
        setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
    };


    const getFilterDate = async () => {
        try {
            const response = await axios.post(
              `${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`
            );
            const data = decryptedFunction(response?.data?.responseData);
            setFilterDateList(
                data?.data?.payPeriodEndDates.map((item) => {
                    return {
                        label: item.display_date,
                        value: item.payload_date,
                    };
                })
            );

            if(state?.urldate) {
               getTableData(state?.urldate?.value, searchBoxValue, graphDate);
               getChartFirst(state?.urldate?.value);

                const matchingCostCenter = state?.urldate?.value;
                setDropdownValueTwo({
                  label: state?.urldate?.label,
                  value: state?.urldate?.value
                });
                return matchingCostCenter;
            } else {
                if (data?.data?.payPeriodEndDates[0].payload_date) {
                  getTableData(
                    data?.data?.payPeriodEndDates[0].payload_date,
                    searchBoxValue,
                    graphDate
                  );

                  getChartFirst(data?.data?.payPeriodEndDates[0].payload_date);
                 }

                  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
                  setDropdownValueTwo({
                    label: matchingCostCenter?.display_date,
                    value: matchingCostCenter?.payload_date,
                  });
                  return matchingCostCenter;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getClearDate = async () => {
        try {
            getTableData(
                dropdownValueTwo?.value,
                searchBoxValue,
                graphDate
            );

            getChartFirst(dropdownValueTwo?.value);

        } catch (err) {
            console.log(err);
        }
    };

    const toggleOpenTwo = () => {
        setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
    };

    const selectStyles = {
        control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
        menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    };



    const getCostCenterList = async (ccn) => {

        try {
           const paramData = {
             page_type: "overtime",
           };
           const encryptData = dataEnCrypt(paramData);

            const response = await axios.post(
              `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
              { payloadData: encryptData }
            );
          const data = decryptedFunction(response?.data?.responseData);
            setCostCenterList(
                data?.data?.costCenterResponse.map((item) => {
                    return {
                        label: `${item.cost_center_number} - ${capitalizeAllLetters(
                            item.cost_center_name
                        )}`,
                        value: item.cost_center_slug,
                    };
                })
            );
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (graphDate) {
            getHeadData(graphDate, selectedRow?.cost_center_number, dropdownValueTwo?.value);
            getTableData(dropdownValueTwo?.value, searchBoxValue, graphDate, selectedRow?.cost_center_number);
        }
    }, [graphDate]);

    const debouncedInputChange = useCallback(
        debounce((inputVal) => {
            console.log("Fetching data for:", inputVal);
            console.log("Fetching data for:", dropdownValueTwo);
            // getTableData(dropdownValueTwo?.value, inputVal, graphDate);
        }, 500),
        []
    );

    const handleInputChange = (newValue) => {
           setSearchBoxValue(newValue);
           setIsTableLoading(true);

           if (cancelToken) {
             cancelToken.cancel("Operation canceled due to new request.");
           }
           const newCancelToken = Axios.CancelToken.source();
           setCancelToken(newCancelToken);

           getTableData(
             dropdownValueTwo?.value,
             newValue,
             graphDate,
             null,
             newCancelToken.token
           );
    };


      useEffect(() => {
          getFilterDate();
          getCostCenterList();
          getRefreshSchedule();
          getHeadData();
        //    getHeadData();
      }, [buisnessHeader]);

       const activityLog = async (table_column, sortType) => {
         try {
          const paramData = {
            activity_id: 47,
            page_type: "Schedule Summary",
            application_id: 105,
            table_name: "Schedule Summary",
            table_column: table_column,
            sort_order: sortType === "asc" ? "Ascending" : "Descending",
            searchKeyword: searchBoxValue,
            businessUnit: isBuisnessUnitSet,
            payperiod_end_date: dropdownValueTwo?.label,
          };
          const encryptData = dataEnCrypt(paramData);
           const { data } = await axios.post(
             `${API_CALL_URL_PRIVATE}/activity-log/create`,
             { payloadData: encryptData }
           );
         } catch (err) {
           console.log(err);
         }
       };
       
    return (
      <PageLayout>
        <div className="activedailymanagement-s">
          <Row className="gx-2 mt-0 align-items-center">
            <Col md={{ span: 2 }}>
              <Link
                className="f-14 white-text-dark-mode"
                to={
                  state?.urlslug === "homepage"
                    ? `/home`
                    : `/activedailymanagement`
                }
              >
                <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
              </Link>
            </Col>
            <Col md={{ span: 8 }}>
              <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
                <div className="">
                  <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                    <img src={Bi4} alt="" height={38} /> &nbsp; Schedule Summary
                    -{" "}
                  </h3>
                </div>

                <div className="custom-select-v">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpentwo}
                    onClose={toggleOpenTwo}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpenTwo}
                        isSelected={isDropdownOpentwo}
                      >
                        {dropdownValueTwo ? dropdownValueTwo.label : ""}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpentwo}
                      options={filterDateList}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      onChange={(e) => {
                        toggleOpenTwo();
                        setDropdownValueTwo(e);
                        getTableData(e?.value, null, null);
                        getChartFirst(e?.value);
                        getHeadData(null, null, e?.value);
                        setSearchBoxValue("");
                        setGraphDate("");
                        setClickCount(0);
                        setSelectedRow(null);
                      }}
                      value={dropdownValueTwo}
                    />
                  </CustomDropdown>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {isLoading ? (
                  <HeaderLoaderText themeMode={themeMode} />
                ) : headData.filter_text ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "10px" }}
                  >
                    <div style={{ fontSize: "14px" }}>
                      {headData.filter_text}
                    </div>
                    {headData.show_cross_button ? (
                      <button
                        type="button"
                        class="custom-close-button"
                        onClick={() => {
                          setSearchBoxValue("");
                          setGraphDate("");
                          setClickCount(0);
                          setSelectedRow(null);
                          if (searchBoxValue === "" && graphDate === "") {
                            getClearDate();
                            getHeadData();

                            setGraphData({});
                            // setHeadData([]);
                            setIsTableLoading(true);
                            setIsGraphLoading(true);
                          }
                        }}
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-center align-items-center pay-period color-red">
                {get(headData, "imp_notes", "")}
              </div>
            </Col>
            <Col md={2} className="header-bar h-45p">
              <div className="col">
                {/* <Select
                  inputValue={searchBoxValue}
                  onChange={(e) => {
                    navigate(
                      `/activedailymanagement/schedule-details/${e.value}`
                    );
                  }}
                  onInputChange={handleInputChange}
                  options={costCenterList}
                  placeholder={"Search..."}
                  isSearchable
                  onBlurResetsInput={false}
                /> */}
                <input
                  type="text"
                  value={searchBoxValue}
                  className="form-control py-2 bg-transparent border-light"
                  placeholder="Search..."
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              </div>
            </Col>

            <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
            <FooterModal
              show={footerShow}
              dataFooter={scheduleData}
              onHide={() => setFooterShow(false)}
            />
          </Row>

          <Row className="gx-2 row">
            <Col md={6} className="mt-2 h-695">
              <div className="bg-white p-3 h-100">
                <Table
                  responsive
                  className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table schdule-table schdule-summerytable"
                >
                  <thead>
                    <tr className="vertical-align-middle">
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("cost_center_name")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Cost Center</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "cost_center_name" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("worked_hours")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          {/* <div>Critical Exceptions</div> */}
                          <div>
                            Worked <br /> Hours
                          </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "worked_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("scheduled_hours")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          {/* <div>Average Age {" "}</div> */}
                          <div>Scheduled Hours</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "scheduled_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("scheduled_variance")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          {/* <div>Scheduled Vs Worked{" "}</div> */}
                          <div>
                            Schedule
                            <br />
                            Variance
                          </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "scheduled_variance" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("staff")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          {/* <div>Scheduled Vs Worked{" "}</div> */}
                          <div>
                            Staff W/O
                            <br />
                            Schedule
                          </div>
                          <div className="ms-2">
                            {sortType === "asc" && sortName === "staff" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="crossi h-600">
                    {!isTableLoading ? (
                      tableData?.length > 0 ? (
                        tableData?.map((val, ind) => {
                          return (
                            <tr
                              key={ind}
                              style={{ cursor: "pointer" }}
                              className={
                                selectedRow?._id === val?._id
                                  ? "selected-active-row"
                                  : ""
                              }
                              onClick={
                                () =>
                                  // console.log("val", val)
                                  handleDoubleExceptional(val)
                                // state?.url !== "activedaily" &&
                                // navigate(
                                //   `/activedailymanagement/overtime-summary/overtimedetails/${val.slug}`,
                                // )
                              }
                            >
                              <td>
                                <div>
                                  {val.cost_center_number} -{" "}
                                  {val.cost_center_name}
                                </div>
                              </td>
                              <td className="text-center">
                                <div
                                // className={
                                //     val?.overtime_worked_hours <= 4
                                //         ? "text-success"
                                //         : "text-danger"
                                // }
                                >
                                  {" "}
                                  {formatNumberWithFraction(val?.worked_hours)}
                                </div>
                              </td>
                              <td className="text-center">
                                <div
                                // className={
                                //     val?.incidental <= 1
                                //         ? "text-success"
                                //         : "text-danger"
                                // }
                                >
                                  {formatNumberWithFraction(
                                    val?.scheduled_hours
                                  )}
                                  {/* {formatNumberWithFraction(val?.incidental)}% */}
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  {formatNumberWithFraction(
                                    val?.scheduled_variance
                                  )}
                                  {/* {val?.total_overtime_hours?.toFixed(1)} */}
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  {val?.staff?.toFixed(0)}
                                  {/* {val?.total_overtime_hours?.toFixed(1)} */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <p className="text-secondary display-6">
                            No Data Found
                          </p>
                        </div>
                      )
                    ) : (
                      <div>
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                      </div>
                    )}
                  </tbody>
                  <tbody className="ftr">
                    {isTableLoading ? (
                      <div>
                        <TableLoader themeMode={themeMode} />
                      </div>
                    ) : !isEmpty(totalvalue) ? (
                      <tr className="bt">
                        <td>
                          <strong>Total</strong>
                        </td>

                        <td className="text-center">
                          <strong>150%</strong>
                        </td>
                        <td className="text-center">
                          <strong>150%</strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(get(totalvalue, "hours_variance", 0))}
                          </strong>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={6} className="mt-2">
              <div className="h-100 bg-white" style={{ position: "relative" }}>
                <div class="text-center mb-2 pt-3">
                  <div class="box-heading text-center">
                    Scheduled vs Worked Hours
                  </div>
                  <div className="bxh d-flex justify-content-center mt-3">
                    <div
                      className={
                        themeMode.theme === "DARK"
                          ? "whitexyt legend-text"
                          : "blackxyt legend-text"
                      }
                    >
                      <span className="circle"></span>Scheduled
                    </div>
                    <div className="purplexyt legend-text">
                      <span className="circle"></span>Worked
                    </div>
                  </div>
                </div>
                {isGraphLoading ? (
                  <GraphLoader themeMode={themeMode} />
                ) : (
                  <ScheduleBasicChart
                    data={graphData}
                    //   GraphLoader
                    graphClick={setGraphDate}
                    className="w-100 "
                  />
                )}
                {/* <img src={themeMode.theme === "DARK" ? GRPH : GRPHLITE}
                alt="" style={{ maxWidth: "100%" }}
              /> */}
              </div>
            </Col>
            <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
              <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
                {/* <span>{headData?.current_file_msg}</span> */}
                <div className="col-12 text-end text-mobile-center bg-white">
                  <span
                    className="d-inline-block p-2  cursor-pointer"
                    onClick={() => {
                      getRefreshSchedule(true);
                      setFooterShow(true);
                    }}
                  >
                    {scheduleData?.exception?.currentFileMsg}
                  </span>
                </div>
                {/* <span className="col-6"><span className=" text-start  p-2 bg-white d-block">{headData?.upcomming_file_msg}</span></span> */}
              </div>
            </Col>
          </Row>
        </div>
      </PageLayout>
    );
}

export default ActiveManagement;
