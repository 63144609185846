import Chart from "react-apexcharts";
import moment from 'moment';
import { useContext } from "react";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { useSelector } from "react-redux";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 40"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);


function BltChart({
  barchartseries,
  barchartcat,
  ids,
  callbackfunc,
  toolData,
  isLoader,
  color,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );

  var options = {
    chart: {
      type: "bar",
      height: 150,
      // stacked: hasValueGreater,
      stacked: true,
      color: themeMode.theme === "DARK" ? "white" : "black",

      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
      },
      zoom: {
        enabled: true,
      },
    },
    colors: [color],
    series: [
      {
        data: [
          {
            x: "category A",
            y: 10,
          },
          {
            x: "category B",
            y: 18,
          },
          {
            x: "category C",
            y: 13,
          },
        ],
      },
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "70%",
        barHeight: "70%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },

      ids: ids,
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        if (value === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
        padding: {
          top: 30,
        },
      },
    },
    xaxis: {
      // position: 'bottom',
      labels: {
        show: true,
        style: {
          colors:
            themeMode.theme === "DARK"
              ? ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"]
              : [
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                ],
        },
      },
    },
    yaxis: {
      // position: 'bottom',

      labels: {
        show: false,
        formatter: function (value) {
          return value?.toFixed(1) == 1 ? "Yes" : "No";
        },
        style: {
          colors:
            themeMode.theme === "DARK"
              ? [
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                  "#FFF",
                ]
              : [
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                  "#191C24",
                ],
        },
      },
    },
    legend: {
      show: false,
      position: "right",
      offsetY: 0,
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "Did Blt Cover" + // You missed the concatenation operator (+) here
          " : " +
          (series[seriesIndex][dataPointIndex] === 1 ? "Yes" : "No") + // Added concatenation operator
          "</span>" +
          "</div>"
        );
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div
      className="mt- pb-0 bar-height"
      style={{ height: "16vh", minHeight: "117px" }}
    >
      {!isLoader ? (
        <Chart
          options={options}
          // series={[{
          //   name: toolData,
          //   data: barchartseries.map((dataPoint, index) => ({
          //     x: dataPoint?.shift_time,
          //     y: dataPoint?.value
          //   })),
          // }]}
          //  series={barchartseries}
          series={[
            {
              name: toolData,
              data: barchartseries.map((dataPoint, index) => ({
                x: dataPoint.shift_time,
                y: dataPoint.value,
                fillColor: dataPoint.value === 1 ? "#458FFF" : "#FF0000",
              })),
            },
          ]}
          type="bar"
          width={"100%"}
          //height={153}
          height={"100%"}
        />
      ) : (
        <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default BltChart;