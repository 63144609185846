import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import "font-awesome/css/font-awesome.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import Button from "@atlaskit/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { Form, InputGroup } from "react-bootstrap";
import PageLayout from "../../../layouts/PageLayout";
import InfoModal from "../shared/InfoModal";
import FooterModal from "../shared/footerModal";

import Ico7 from "../../../assets/img/clock-1.png";
import { get, isEmpty } from "lodash";
import { FormGroup } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import Axios from "axios";
import { DOMAIN_URL } from "../../../constants/compensate";
import { API_CALL_URL_PRIVATE } from "../../../constants/compensate";
import ContentLoader from "react-content-loader";
import * as ST from "../../../Styled/Styled";
import StackedBarChart from "../../../components/ActiveChart/StackedBarChart";
import Searchicon from "../../../assets/img/searchicon.png";
import { getTableRecordWithSorting } from "../../../services";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import PsLogo from "../../../assets/img/pslogo.png";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
} from "../../../store/actions/Compensate";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { THEME_TYPE } from "../../../constants/theme";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import Bi4 from "../../../assets/img/overtime/exceptions.svg";
import SHIFT_FORM from "../../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 15"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="15" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="195" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 200, height: 20, marginRight: 8, marginBottom: 17 }}>
    <ContentLoader
      viewBox="0 0 300 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" style={{ width: "100%" }} height="100" />
    </ContentLoader>
  </div>
);

function ExceptionsDetails({ active, fromdate, activetab, history }) {
  const dispatch = useDispatch();
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { department, costid } = useParams();
  const time = fromdate ? new Date(fromdate) : "";
  const [costArrData, setCostArrData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateData, setDateData] = useState([]);
  const [costData, setCostData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const [dropDateData, setDropDateData] = useState({});
  const [dropCostData, setDropCostData] = useState({});
  const [constCenter, setConstCenter] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(true);
  const [isLoadingTwo, setIsLoadingTwo] = useState(true);
  const [isLoadingThree, setIsLoadingThree] = useState(true);
  const [exceptionData, setExceptionData] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [totalvalueTwo, setTotalvalueTwo] = useState({});
  const [showdropdown, setShowdropdown] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortExceType, setSortExceType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const [sortExceName, setsortExceName] = useState("");
  const [dateFooter, setDateFooter] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectSeverity, setSelectSeverity] = useState(null);
  const [handleGraph, setHandleGraph] = useState(true);
  const [dropSupvisor, setDropSupvisor] = useState("");
  const [id, setId] = useState(null);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [cosCenterSlug, setCosCenterSlug] = useState(state?.urlslug);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [isDropdownOpenThree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [dropdownValueThree, setDropdownValueThree] = useState("");
  const [comLoading, setComLoading] = useState(true);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [isSearchable, setIsSearchable] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [mounted, setMounted] = useState(false);

  // supervisor
  const [supvisordropdown, setSupvisordropdown] = useState(false);
  const [supvisorData, setSupvisorData] = useState({});
  const [searchSupvisor, setSearchSupvisor] = useState([]);
  const [dropSupData, setDropSupData] = useState({});
  const outsupvisor = useRef(null);
  const outsupvisortwo = useRef(null);

  const outside = useRef(null);
  const outsidetwo = useRef(null);

  const momoStack = useSelector((state) => state.compensate.stackDate);


  const getFilteredData = async (
    centerNum,
    endData,
    filterDate,
    severityId,
    approvedId,
    searchKeyword,
    cancelToken
  ) => {
    setIsLoadingOne(true);

    var url = `${API_CALL_URL_PRIVATE}/exceptionDetail/getData`;
    let parData = {
      cost_center_number: centerNum,
      payperiod_end_date: endData,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };
    if (filterDate) {
      parData.filter_date = filterDate;
    }
    if (severityId) {
      parData.exception_definition_id = severityId;
    }

    if (approvedId) {
      parData.approver_id = approvedId;
    }

    if (searchKeyword) {
      parData.searchKeyword = searchKeyword;
    }

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        url,
        { payloadData: encryptData },
        { cancelToken: cancelToken }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setTableData(data.data.response);
      setIsLoadingOne(false);
      const sum = data.data?.response?.reduce(function (
        previousValue,
        currentValue
      ) {
        return {
          critical_exceptions:
            previousValue.critical_exceptions +
            currentValue.critical_exceptions,
          warning_exceptions:
            previousValue.warning_exceptions + currentValue.warning_exceptions,
          informational_exceptions:
            previousValue.informational_exceptions +
            currentValue.informational_exceptions,
          total_exceptions:
            previousValue.total_exceptions + currentValue.total_exceptions,
        };
      });

      // dispatch(setUpdateStack(null));
      setTotalvalue(sum);
    } catch (error) {
      console.log(error);
      setIsLoadingOne(false);
    }
  };

  const getExceptionDec = async (
    centerNum,
    endData,
    filterDate,
    employeID,
    severityId,
    approvedId
  ) => {
    setIsLoadingTwo(true);
    var url = `${API_CALL_URL_PRIVATE}/exceptionDetail/getExceptionDecriptionData`;
    let parData = {
      cost_center_number: centerNum,
      payperiod_end_date: endData,
    };
    if (filterDate) {
      parData.filter_date = filterDate;
    }
    if (employeID) {
      parData.employee_id = employeID;
    }

    if (severityId) {
      parData.exception_definition_id = severityId;
    }

    if (approvedId) {
      parData.approver_id = approvedId;
    }
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(url, { payloadData: encryptData });
      const data = decryptedFunction(response?.data?.responseData);
      setExceptionData(data.data.response);
      const sum = data.data?.response?.reduce(function (
        previousValue,
        currentValue
      ) {
        return {
          critical_exceptions:
            previousValue.critical_exceptions +
            currentValue.critical_exceptions,
          warning_exceptions:
            previousValue.warning_exceptions + currentValue.warning_exceptions,
          informational_exceptions:
            previousValue.informational_exceptions +
            currentValue.informational_exceptions,
          total_exceptions:
            previousValue.total_exceptions + currentValue.total_exceptions,
        };
      });
      setTotalvalueTwo(sum);
      setIsLoadingTwo(false);
    } catch (error) {
      console.log(error);
      setIsLoadingTwo(false);
    }
  };

  const getGraphData = async (
    centerNum,
    endData,
    employeID,
    severityId,
    filterDate,
    approvedId
  ) => {
    setIsGraphLoading(true);
    var url = `${API_CALL_URL_PRIVATE}/exceptionDetail/getGraphData`;
    let parData = {
      cost_center_number: centerNum,
      payperiod_end_date: endData,
    };
    if (employeID) {
      parData.employee_id = employeID;
    }
    if (severityId) {
      parData.exception_definition_id = severityId;
    }
    if (filterDate) {
      parData.filter_date = filterDate;
    }

    if (approvedId) {
      parData.approver_id = approvedId;
    }

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(url, { payloadData: encryptData });
      const data = decryptedFunction(response?.data?.responseData);
      setGraphData(data?.data?.graphData);
      setIsGraphLoading(false);
    } catch (error) {
      console.log(error);
      setIsGraphLoading(false);
    }
  };

  const opendropdownHandle = () => {
    setShowdropdown(true);
  };

  const searchHandle = (e) => {
    const val = e.target.value;
    if (val !== "") {
      const filterdata = costData.filter((item) =>
        item["label"].toLowerCase().includes(val.toLowerCase())
      );
      setSearchData(filterdata);
    } else {
      setSearchData(costData);
    }
  };

  const outsideClick = () => {
    function handleOutsideClick(event) {
      if (
        outside.current &&
        !outside.current.contains(event.target) &&
        outsidetwo.current &&
        !outsidetwo.current.contains(event.target)
      ) {
        setShowdropdown(false);
      }
    }

    // Adding click event listener
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  };

  useEffect(() => {
    getCostCenterNumber();
    getRefreshSchedule();
  }, []);

  useEffect(() => {
    outsideClick();
  }, [outside]);

  const jumpFun = (e) => {
    let filVal = costArrData.filter(
      (val) => val.cost_center_number === e.value
    );
    const url = `/activedailymanagement/exceptionsummary/${filVal[0]?.cost_center_slug}`;
    setCosCenterSlug(filVal[0]?.cost_center_slug);
    if (filVal[0]?.cost_center_slug) {
      navigate(url, {
        state: state?.urlslug ? { urlslug: filVal[0]?.cost_center_slug } : null,
      });

    } else {
      return "";
    }
  };
  const getHeadData = async (eDate, eCost) => {
    setIsLoading(true);
    if (eDate || eCost) {
      try {
        const paramData = {
          filter_date: eDate,
          cost_center_number: eCost,
        };
        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/exceptionSummary/getheaderData`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        setIsLoading(false);
        setHeadData(data.data);
      } catch (error) {
         const errorResponse = decryptedFunction(
           error?.response?.data?.responseData
         );
        setIsLoading(false);
        console.log(error);
      }
    } else {
      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/exceptionSummary/getheaderData`
        );
        const data = decryptedFunction(response?.data?.responseData);
        setIsLoading(false);
        setHeadData(data.data);
      } catch (error) {
         const errorResponse = decryptedFunction(
           error?.response?.data?.responseData
         );
        setIsLoading(false);
        console.log(error);
      }
    }
  };
  // useEffect(() => {
  //   getHeadData();
  // }, []);

  useEffect(() => {
    if (momoStack && !selectedRow) {
      getFilteredData(
        constCenter,
        dateVal,
        momoStack,
        selectSeverity?.exception_definition_id
      );
      getExceptionDec(
        constCenter,
        dateVal,
        momoStack,
        null,
        selectSeverity?.exception_definition_id
      );
    }
  }, [momoStack]);

  const defaultval = costData.filter(
    (val) => val.value === defaultValue[0]?.cost_center_number
  );

  const sortingHandle = (key, bindname) => {
    const sortdata = getTableRecordWithSorting(tableData, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    setsortName(key);
    setTableData(sortdata);
      activityLog(
        SHIFT_FORM.EXCEPTION_DETAIL_TABLE[key] ?? "",
        sortType,
        "Exception Detail"
      );

  };

  const sortingExceptionHandle = (key, bindname) => {
    const sortdata = getTableRecordWithSorting(
      exceptionData,
      key,
      sortExceType
    );
    setSortExceType(sortExceType === "asc" ? "desc" : "asc");
    setsortExceName(key);
    setExceptionData(sortdata);
     activityLog(
       SHIFT_FORM.EXCEPTION_DETAIL_TABLE[key] ?? "",
       sortType,
       "Exceptions by Severity Level"
     );
  };

  const transparentIconStyle = {
    color: "transparent",
  };

  const handlExceptional = (val, type) => {
    if (type === "exceptdetail") {
      if (!selectSeverity && !momoStack) {
        setSelectedRow(val);
        setSelectSeverity(null);
        setHandleGraph(false);
        getExceptionDec(constCenter, dateVal, momoStack, val?.employee_id);
        getGraphData(constCenter, dateVal, val?.employee_id);
      }
    } else if (type === "severity") {
      if (!selectedRow) {
        setSelectSeverity(val);
        setSelectedRow(null);
        //  setHandleGraph(false)
        getGraphData(
          constCenter,
          dateVal,
          null,
          val?.exception_definition_id,
          momoStack
        );
        getFilteredData(
          constCenter,
          dateVal,
          momoStack,
          val?.exception_definition_id
        );
      }
    }
  };

  const handleSeverity = (val) => {
    setSelectSeverity(val);
    setSelectedRow(null);
    getGraphData(constCenter, dateVal, null, val?.exception_definition_id);
    getFilteredData(constCenter, dateVal, null, val?.exception_definition_id);
  };

  function formatName(name) {
    const nameParts = name.split(" ");
    if (nameParts.length === 1) {
      return name;
    } else if (nameParts.length === 2) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        " " +
        nameParts.slice(1).join(" ")
      );
    } else {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[1].charAt(0).toUpperCase() +
        " " +
        nameParts.slice(2).join(" ")
      );
    }
  }



  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,

        color: isFocused ? (themeMode.theme === "DARK" ? "#FFF" : "#000") : "",
        backgroundColor: isFocused
          ? themeMode.theme === "DARK"
            ? "#000"
            : "#efefef"
          : "",
      };
    },
  };

  // supervisor

  const opendropdownSupvisor = () => {
    setSupvisordropdown(true);
  };

  const searchSupvisorHandle = (e) => {
    const val = e.target.value;
    if (val !== "") {
      const filterdata = supvisorData.filter((item) =>
        item["label"].toLowerCase().includes(val.toLowerCase())
      );
      setSearchSupvisor(filterdata);
    } else {
      setSearchSupvisor(supvisorData);
    }
  };

  const supoutsideClick = () => {
    function handleSupOutsideClick(event) {
      if (
        outsupvisor.current &&
        !outsupvisor.current.contains(event.target) &&
        outsupvisortwo.current &&
        !outsupvisortwo.current.contains(event.target)
      ) {
        setSupvisordropdown(false);
      }
    }

    // Adding click event listener
    document.addEventListener("click", handleSupOutsideClick);
    return () => document.removeEventListener("click", handleSupOutsideClick);
  };

  useEffect(() => {
    supoutsideClick();
  }, [outsupvisor]);

  const getCostCenterNumber = async () => {
    const para = {
      slug: department,
      facility_id: 1,
    };
    const encryptData = dataEnCrypt(para);
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getCostCenterData",
      { payloadData: encryptData }
    );
    const data = decryptedFunction(resp?.data?.responseData);
    const ccn = data.data?.cost_center?.cost_center_number;
    const secondApiResponse = await getMetaData(ccn);
    const dropDataResponse = await getDropData(ccn);
    setId(ccn);
    //fetchDetails();
  };


  const getDropData = async (ccn) => {
    setComLoading(true);
    try {
      const paramCostData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
     const encryptCostData = dataEnCrypt(paramCostData);
      const costResponse = await axios.post(
      `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
      { payloadData: encryptCostData }
    );
      const costCenterData = decryptedFunction(costResponse?.data?.responseData);

      const periodResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`
      );
      const periodData = decryptedFunction(periodResponse?.data?.responseData);

     const paramApproverData = {
       cost_center_number: +ccn,
     };

     const encryptApproverData = dataEnCrypt(paramApproverData);
     const shcApproverResponse = await axios.post(
       `${API_CALL_URL_PRIVATE}/admGeneral/shcApproversLit`,
       { payloadData: encryptApproverData }
     );
     const shcApproveData = decryptedFunction(
       shcApproverResponse?.data?.responseData
     );

      const matchingCostCenter =
        costCenterData?.data?.costCenterResponse.filter(
          (val) => val.cost_center_slug === department
        );

        if (isEmpty(matchingCostCenter)) {
          history(`/activedailymanagement/not-found`);
        }

      setCostArrData(costCenterData?.data?.costCenterResponse);
      setDateData(
        periodData?.data?.payPeriodEndDates.map((val) => {
          return {
            label: val.display_date,
            value: val.payload_date,
          };
        })
      );
      setCostData(
        costCenterData?.data?.costCenterResponse.map((val) => {
          return {
            label: `${val.cost_center_number} - ${val.cost_center_name}`,
            value: val.cost_center_number,
          };
        })
      );

      setSupvisorData(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );

      setSearchSupvisor(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );
      setSearchData(
        costCenterData?.data?.costCenterResponse.map((val) => {
          return {
            label: `${val.cost_center_number} - ${val.cost_center_name}`,
            value: val.cost_center_number,
          };
        })
      );
      setDefaultValue(
        costCenterData?.data?.costCenterResponse.filter(
          (val) => val.cost_center_slug === department
        )
      );

      setConstCenter(
        costCenterData?.data?.costCenterResponse.filter(
          (val) => val.cost_center_slug === department
        )[0]?.cost_center_number
      );

       if (state?.urldate) {

          getFilteredData(
            costCenterData?.data?.costCenterResponse.filter(
              (val) => val.cost_center_slug === department
            )[0]?.cost_center_number,
            state?.urldate?.value
          );
          getExceptionDec(
            costCenterData?.data?.costCenterResponse.filter(
              (val) => val.cost_center_slug === department
            )[0]?.cost_center_number,
            state?.urldate?.value
          );
          getGraphData(
            costCenterData?.data?.costCenterResponse.filter(
              (val) => val.cost_center_slug === department
            )[0]?.cost_center_number,
            state?.urldate?.value
          );

        setDateVal(state?.urldate?.value);
        setDropdownValueTwo({
          label: state?.urldate?.label,
          value: state?.urldate?.value,
        });

       } else {
          getFilteredData(
            costCenterData?.data?.costCenterResponse.filter(
              (val) => val.cost_center_slug === department
            )[0]?.cost_center_number,
            periodData?.data?.payPeriodEndDates[0]?.payload_date
          );
          getExceptionDec(
            costCenterData?.data?.costCenterResponse.filter(
              (val) => val.cost_center_slug === department
            )[0]?.cost_center_number,
            periodData?.data?.payPeriodEndDates[0]?.payload_date
          );
          getGraphData(
            costCenterData?.data?.costCenterResponse.filter(
              (val) => val.cost_center_slug === department
            )[0]?.cost_center_number,
            periodData?.data?.payPeriodEndDates[0]?.payload_date
          );
           setDateVal(
             periodData?.data?.payPeriodEndDates[0]?.payload_date
           );
          setDropdownValueTwo({
            label: periodData?.data?.payPeriodEndDates[0]?.display_date,
            value: periodData?.data?.payPeriodEndDates[0]?.payload_date,
          });
       }

      setComLoading(false);
    } catch (error) {
      setComLoading(false);
      console.log(error);
    }
  };


  const getBuisnesUnitFilter = async () => {
    setComLoading(true);
    try {
      const paramCostData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      const encryptCostData = dataEnCrypt(paramCostData);
      const costResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
        { payloadData: encryptCostData }
      );

      const costCenterData = decryptedFunction(
        costResponse?.data?.responseData
      );

      const periodResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`
      );

      const periodData = decryptedFunction(periodResponse?.data?.responseData);

      const matchingCostCenter =
        costCenterData?.data?.costCenterResponse[0];

      const url = `/activedailymanagement/exceptionsummary/${matchingCostCenter?.cost_center_slug}`;
      setCosCenterSlug(matchingCostCenter?.cost_center_slug);

      navigate(url, {
        state: state?.urlslug
          ? { urlslug: matchingCostCenter?.cost_center_slug }
          : null,
      });

      setDefaultValue(
        costCenterData?.data?.costCenterResponse
      );


      if (state?.urldate) {
            getFilteredData(
              costCenterData?.data?.costCenterResponse.filter(
                (val) => val.cost_center_slug === department
              )[0]?.cost_center_number,
              state?.urldate?.value
            );
            getExceptionDec(
              costCenterData?.data?.costCenterResponse.filter(
                (val) => val.cost_center_slug === department
              )[0]?.cost_center_number,
              state?.urldate?.value
            );
            getGraphData(
              costCenterData?.data?.costCenterResponse.filter(
                (val) => val.cost_center_slug === department
              )[0]?.cost_center_number,
              state?.urldate?.value
            );

              setDropdownValueTwo({
                label: state?.urldate?.label,
                value: state?.urldate?.value,
              });
               setDateVal(state?.urldate?.value);
        
      } else {
          getFilteredData(
            costCenterData?.data?.costCenterResponse[0]
              ?.cost_center_number,
            periodData?.data?.payPeriodEndDates[0]?.payload_date
          );
          getExceptionDec(
            costCenterData?.data?.costCenterResponse[0]
              ?.cost_center_number,
            periodData?.data?.payPeriodEndDates[0]?.payload_date
          );
          getGraphData(
            costCenterData?.data?.costCenterResponse[0]
              ?.cost_center_number,
            periodData?.data?.payPeriodEndDates[0]?.payload_date
          );
            setDropdownValueTwo({
              label: periodData?.data?.payPeriodEndDates[0]?.display_date,
              value: periodData?.data?.payPeriodEndDates[0]?.payload_date,
            });

            setDateVal(
              periodData?.data?.payPeriodEndDates[0]?.payload_date
            );
      }
      setConstCenter(
        costCenterData?.data?.costCenterResponse[0]?.cost_center_number
      );

      const paramApproverData = {
        cost_center_number: +matchingCostCenter?.cost_center_number,
      };

      const encryptApproverData = dataEnCrypt(paramApproverData);

      const shcApproverResponse = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/shcApproversLit`,
        { payloadData: encryptApproverData }
      );
      const shcApproveData = decryptedFunction(
        shcApproverResponse?.data?.responseData
      );


      setCostArrData(costCenterData?.data?.costCenterResponse);
      setDateData(
        periodData?.data?.payPeriodEndDates.map((val) => {
          return {
            label: val.display_date,
            value: val.payload_date,
          };
        })
      );
      setCostData(
        costCenterData?.data?.costCenterResponse.map((val) => {
          return {
            label: `${val.cost_center_number} - ${val.cost_center_name}`,
            value: val.cost_center_number,
          };
        })
      );

      setSupvisorData(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );

      setSearchSupvisor(
        shcApproveData?.data?.getSchApproverData.map((val) => {
          return {
            label: val.employee_name,
            value: val.approver_id,
          };
        })
      );
      setSearchData(
        costCenterData?.data?.costCenterResponse.map((val) => {
          return {
            label: `${val.cost_center_number} - ${val.cost_center_name}`,
            value: val.cost_center_number,
          };
        })
      );

      setComLoading(false);
    } catch (error) {
      setComLoading(false);
      console.log(error);
    }
  };



  const getSupervisorData = async (costId) => {
    const para = {
      cost_center_number: costId
    };
    const encryptApproverData = dataEnCrypt(para);
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/admGeneral/shcApproversLit",
      { payloadData: encryptApproverData }
    );
    const data = decryptedFunction(resp?.data?.responseData);
    setSupvisorData(
      data.data?.getSchApproverData.map((val) => {
        return {
          label: val.employee_name,
          value: val.approver_id,
        };
      })
    );

    setSearchSupvisor(
      data.data?.getSchApproverData.map((val) => {
        return {
          label: val.employee_name,
          value: val.approver_id,
        };
      })
    );
  };




  const getMetaData = async (ccn) => {
    try {
      const param = {
        application_id: 105,
        cost_center_number: ccn,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);

      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);


 const getRefreshSchedule = async (refreshType) => {
   try {
     const parData = {};
     if (refreshType) {
       parData.clicked = 1;
     }

     const encryptData = dataEnCrypt(parData);
     const response = await axios.post(
       `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
       { payloadData: encryptData }
     );
     const data = decryptedFunction(response?.data?.responseData);
     setScheduleData(data.data);
   } catch (error) {
     console.log(error);
   }
 };


  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }


  useEffect(() => { }, [isDropdownOpen, dropdownValue]);

  useEffect(() => {

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    const newCancelToken = Axios.CancelToken.source();
    setCancelToken(newCancelToken);

    isSearchable &&
      getFilteredData(
        constCenter,
        dateVal,
        null,
        null,
        null,
        searchBoxValue,
        newCancelToken.token
      );

    if(searchBoxValue === "") {
      setIsSearchable(false);
    }

  }, [searchBoxValue]);

   useEffect(() => {
    if (mounted) {
      getBuisnesUnitFilter();
    } else {
      setMounted(true);
    }
   }, [buisnessHeader]);

    const activityLogButton = async () => {
      try {
        const paramData = {
          activity_id: 46,
          page_type: "Exception Detail",
          application_id: 105,
        };
        const encryptData = dataEnCrypt(paramData);
        const { data } = await axios.post(
          `${API_CALL_URL_PRIVATE}/activity-log/create`,
          { payloadData: encryptData }
        );
      } catch (err) {
        console.log(err);
      }
    };

     const activityLog = async (table_column, sortType, table_name) => {
       try {
         const paramData = {
           activity_id: 47,
           page_type: "Exception Detail",
           application_id: 105,
           table_name: table_name,
           table_column: table_column,
           sort_order: sortType === "asc" ? "Ascending" : "Descending",
           businessUnit: isBuisnessUnitSet,
           payperiod_end_date: dateVal,
           cost_center_number: constCenter,
           approver_id: dropSupData?.value,
         };
         const encryptData = dataEnCrypt(paramData);
         const { data } = await axios.post(
           `${API_CALL_URL_PRIVATE}/activity-log/create`,
           { payloadData: encryptData }
         );
       } catch (err) {
         console.log(err);
       }
     };

  return (
    <PageLayout>
      <div className="overflow-section">
        <Row className="gx-2 align-items-center justify-content-between exception-header">
          <Col md={1} className="w-70">
            <Link
              to={
                state?.urlslug
                  ? `/activedailymanagement/adm-summary/${cosCenterSlug}`
                  : `/activedailymanagement/exceptions-summary`
              }
              state={{
                urlslug: state?.homeslug ? "homepage" : dropdownValue?.slug,
                urldate: {
                  label: dropdownValueTwo?.label,
                  value: dropdownValueTwo?.value,
                },
              }}
              className="f-14 white-text-dark-mode"
              onClick={() => {
                dispatch(setUpdateDate(""));
                dispatch(setUpdateIndex(""));
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={2} className="text-end ww2 mobile-only mb-2">
            <small
              className="cursor-pointer border-btn f-12  green-b"
              onClick={() => {
                setModalShow(true);
                activityLogButton();
              }}
            >
              Which Exceptions are Counted?{" "}
            </small>
          </Col>
          <Col md={6}>
            <div className="d-flex mobile-flex-wrap justify-content-end align-items-center Bi_Su mobile-content-center mobile-m-4">
              <div className="">
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-end">
                  <img src={Bi4} alt="" height={30} /> &nbsp; Exception Detail
                </h3>
              </div>
            </div>
          </Col>
          <Col md={3} className="text-end">
            {momoStack && !selectedRow && !selectSeverity && (
              <div className="d-flex justify-content-center align-items-center">
                <>
                  {momoStack && (
                    <p
                      className="mb-1 font-14"
                      style={{
                        marginTop: "12px",
                        fontSize: "13px",
                      }}
                    >
                      Filtering data for {momoStack}
                    </p>
                  )}
                  {selectSeverity && (
                    <p
                      className="mb-1 font-14"
                      style={{
                        marginTop: "12px",
                        fontSize: "13px",
                      }}
                    >
                      Filtering data for {selectSeverity?.severity_description}
                    </p>
                  )}

                  <button
                    type="button"
                    class="custom-close-button mt-6px"
                    onClick={() => {
                      getDropData(id);
                      setSelectSeverity("");
                      setHandleGraph(true);
                      dispatch(setUpdateStack(null));
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </>
              </div>
            )}

            {selectSeverity && !momoStack && (
              <div className="d-flex justify-content-center align-items-center">
                <>
                  <p
                    className="mb-1 font-14"
                    style={{
                      marginTop: "12px",
                      fontSize: "13px",
                    }}
                  >
                    Filtering data for
                    <b> {selectSeverity?.severity_description}</b>
                  </p>
                  <button
                    type="button"
                    class="custom-close-button mt-6px"
                    onClick={() => {
                      getDropData(id);
                      setSelectSeverity("");
                      setHandleGraph(true);
                      dispatch(setUpdateStack(null));
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </>
              </div>
            )}

            {selectedRow && (
              <div className="d-flex justify-content-center align-items-center">
                <>
                  <p
                    className="mb-1 font-14"
                    style={{
                      marginTop: "12px",
                      fontSize: "13px",
                    }}
                  >
                    Filtering data for {formatName(selectedRow?.employee_name)}
                  </p>
                  <button
                    type="button"
                    class="custom-close-button mt-6px"
                    onClick={() => {
                      getDropData(id);
                      setSelectedRow("");
                      setHandleGraph(true);
                      dispatch(setUpdateStack(null));
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </>
              </div>
            )}

            {momoStack && !selectedRow && selectSeverity && (
              <div className="d-flex justify-content-center align-items-center">
                <>
                  <p
                    className="mb-1 font-14"
                    style={{
                      marginTop: "12px",
                      fontSize: "13px",
                    }}
                  >
                    Filtering data for {selectSeverity?.severity_description}{" "}
                    and for {momoStack}
                  </p>
                  <button
                    type="button"
                    class="custom-close-button mt-6px"
                    onClick={() => {
                      getDropData(id);
                      setSelectSeverity("");
                      setHandleGraph(true);
                      dispatch(setUpdateStack(null));
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </>
              </div>
            )}
          </Col>
          <Col md={2} className="text-end  ">
            <div className="input-group mb-2">
              <input
                type="text"
                value={searchBoxValue}
                className="form-control py-2 bg-transparent border-light"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchBoxValue(e.target.value);
                  setIsSearchable(true);
                }}
              />
            </div>
          </Col>
          <Col md={2} className=""></Col>

          <Col md={8}>
            <div className="gx-2 mt-2 d-flex align-items-center justify-content-center mobile-flex-wrap  ">
              {/* <Col> */}
              {/* <div>
              hello
            </div> */}
              {comLoading ? (
                <LinkLoader themeMode={themeMode} />
              ) : (
                <div className="custom-select-v ">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpen}
                    onClose={toggleOpen}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpen}
                        isSelected={isDropdownOpen}
                      >
                        {dropCostData.label
                          ? dropCostData.label
                          : defaultval.length > 0
                          ? defaultval[0].label
                          : null}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpen}
                      onChange={(e) => {
                        toggleOpen();
                        getFilteredData(e.value, dateVal);
                        getExceptionDec(e.value, dateVal);
                        getGraphData(e.value, dateVal);
                        setDropCostData(e);
                        setConstCenter(e.value);
                        getSupervisorData(e.value);
                        getMetaData(e.value);
                        setDropSupData({});
                        jumpFun(e);
                        setIsSelect(true);
                        setSelectedRow(null);
                        setSelectSeverity(null);
                      }}
                      options={costData}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      value={
                        dropCostData.label
                          ? dropCostData
                          : costData.filter(
                              (val) =>
                                val.value ===
                                defaultValue[0]?.cost_center_number
                            )
                      }
                      // value="ICE 1"
                    />
                  </CustomDropdown>
                </div>
              )}

              {comLoading ? (
                <LinkLoader themeMode={themeMode} />
              ) : (
                <div className="custom-select-v ">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpentwo}
                    onClose={toggleOpenTwo}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpenTwo}
                        isSelected={isDropdownOpentwo}
                      >
                        {dropDateData?.label
                          ? dropDateData?.label
                          : dropdownValueTwo?.label}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpentwo}
                      onChange={(e) => {
                        toggleOpenTwo();
                        getFilteredData(constCenter, e.value);
                        getExceptionDec(constCenter, e.value);
                        getGraphData(constCenter, e.value);
                        setDateVal(e.value);
                        dispatch(setUpdateStack(null));
                        setDropDateData(e);
                        setDropdownValueTwo(e);
                        setSelectedRow(null);
                        setSelectSeverity(null);
                      }}
                      options={dateData}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      // value={
                      //   dropCostData.label
                      //     ? dropCostData
                      //     : costData.filter(
                      //       (val) =>
                      //         val.value === defaultValue[0]?.cost_center_number
                      //     )
                      // }
                      // value="ICE 1"
                    />
                  </CustomDropdown>
                </div>
              )}

              {comLoading ? (
                <LinkLoader themeMode={themeMode} />
              ) : (
                <div className="custom-select-v cap lt position-relative">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpenThree}
                    onClose={toggleOpenThree}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpenThree}
                        isSelected={isDropdownOpenThree}
                      >
                        {!isEmpty(dropSupData)
                          ? dropSupData.label
                          : "All Approvers"}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpenThree}
                      onChange={(item) => {
                        toggleOpenThree();
                        setDropSupData(item);
                        getFilteredData(
                          constCenter,
                          dateVal,
                          null,
                          null,
                          item.value
                        );
                        getExceptionDec(
                          constCenter,
                          dateVal,
                          null,
                          null,
                          null,
                          item.value
                        );
                        getGraphData(
                          constCenter,
                          dateVal,
                          null,
                          null,
                          null,
                          item.value
                        );
                        // jumpFun(item);
                        setIsSelect(true);
                        setSelectedRow(null);
                        setSelectSeverity(null);
                      }}
                      options={supvisorData}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      //  value={dropdownValue}
                      value="All Approvers"
                    />
                  </CustomDropdown>
                  {!isEmpty(dropSupData) && (
                    <span
                      onClick={() => {
                        navigate(location.pathname, {});
                        setDropDateData({});
                        setDropSupData({});
                        getCostCenterNumber();
                      }}
                      className="custom-cross"
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      x
                    </span>
                  )}
                </div>
              )}
              {/* </Col> */}
            </div>
          </Col>

          <Col md={2} className="text-end ww2 dekstop-only">
            <small
              className="cursor-pointer border-btn f-12  green-b"
              onClick={() => {
                setModalShow(true);
                activityLogButton();
              }}
            >
              Which Exceptions are Counted?{" "}
            </small>
          </Col>
          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            dataFooter={scheduleData}
            show={footerShow}
            onHide={() => setFooterShow(false)}
          />
        </Row>
        <Row className="gx-2">
          <Col md={6} className="mt-2">
            <div className="bg-white p-3 h-100 min-h7">
              <div className="text-center mb-2">
                <div class="box-heading text-center">Exception Detail </div>
              </div>
              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2 exceptions-table table-hover scroll-table long-table"
              >
                <thead>
                  <tr>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("employee_name", "")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Name</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "employee_name" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("critical_exceptions", "")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Critical</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "critical_exceptions" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("warning_exceptions", "")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Warning</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "warning_exceptions" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() =>
                        sortingHandle("informational_exceptions", "")
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Informational</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "informational_exceptions" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_exceptions", "")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Total Exceptions</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_exceptions" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoadingOne ? (
                    tableData?.length > 0 ? (
                      tableData?.map((val, ind) => {
                        return (
                          <tr
                            key={ind}
                            style={{ cursor: "pointer" }}
                            className={
                              selectedRow?._id === val?._id
                                ? "selected-active-row"
                                : ""
                            }
                            onClick={() =>
                              !momoStack
                                ? handlExceptional(val, "exceptdetail")
                                : ""
                            }
                            // onClick={() => {
                            //   setExceptionalFlow({ detail: true, date: false, severity: false })
                            //   exceptionalFlow.detail && handlExceptional(val)
                            // }}
                          >
                            <td style={{ textAlign: "" }}>
                              {val.employee_name}
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="text-center"
                            >
                              {val.critical_exceptions}{" "}
                              <span
                                className={
                                  val.critical_exceptions > 0
                                    ? "circle size-11 red-circle"
                                    : "circle size-11"
                                }
                              ></span>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="text-center"
                            >
                              {val.warning_exceptions}{" "}
                              <span
                                className={
                                  val.warning_exceptions > 0
                                    ? "circle size-11 yellow-circle"
                                    : "circle size-11"
                                }
                              ></span>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="text-center"
                            >
                              {val.informational_exceptions}{" "}
                              <span
                                className={
                                  val.informational_exceptions > 0
                                    ? "circle size-11 blue-circle"
                                    : "circle size-11"
                                }
                              ></span>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="text-center flag"
                            >
                              {val.total_exceptions} &nbsp;{" "}
                              {val.total_exceptions > 1 ? (
                                <FontAwesomeIcon icon="fa-solid fa-flag" />
                              ) : (
                                <FontAwesomeIcon
                                  icon="fa-solid fa-flag"
                                  style={transparentIconStyle}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>
                <tbody className="ftr">
                  {isLoadingOne ? (
                    <div>
                      <TableLoader themeMode={themeMode} />
                    </div>
                  ) : totalvalue ? (
                    <tr className="bt">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="text-center">
                        <strong style={{ marginRight: "6px" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                          }).format(get(totalvalue, "critical_exceptions", 0))}
                        </strong>
                        <span
                          className="circle size-11"
                          style={{ opacity: 0 }}
                        ></span>
                      </td>
                      <td className="text-center">
                        <strong style={{ marginRight: "6px" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                          }).format(get(totalvalue, "warning_exceptions", 0))}
                        </strong>
                        <span
                          className="circle size-11"
                          style={{ opacity: 0 }}
                        ></span>
                      </td>
                      <td className="text-center">
                        <strong style={{ marginRight: "6px" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                          }).format(
                            get(totalvalue, "informational_exceptions", 0)
                          )}
                        </strong>
                        <span
                          className="circle size-11"
                          style={{ opacity: 0 }}
                        ></span>
                      </td>
                      <td className="text-center">
                        <strong style={{ marginRight: "6px" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                          }).format(get(totalvalue, "total_exceptions", 0))}
                        </strong>
                        <span
                          className="circle size-11"
                          style={{ opacity: 0, marginLeft: "0" }}
                        ></span>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div
              className="border-bottom-5 h-half"
              style={{ position: "relative" }}
            >
              <div class="text-center mb-2 pt-3">
                <div class="box-heading text-center">Exceptions by Date</div>
                <div className="bxh d-flex justify-content-center mt-3">
                  <div className="redt legend-text">
                    <span className="circle"></span>Critical
                  </div>
                  <div className="yellowt legend-text">
                    <span className="circle"></span>Warning
                  </div>
                  <div className="bluet legend-text">
                    <span className="circle"></span>Informational
                  </div>
                </div>
              </div>
              {/* <img src={Ico7} className="w-100" /> */}
              {isGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                // <div style={{'pointerEvents': 'none'}}>
                <StackedBarChart
                  data={graphData.length > 0 ? graphData : []}
                  GraphLoader
                  graphClick={handleGraph}
                  className="w-100 "
                />
                // </div>
              )}
            </div>
            <div className="border-top-5 h-half" style={{ minHeight: "350px" }}>
              <div className="bg-white p-3">
                <div class="box-heading text-center mb-2">
                  Exceptions by Severity Level
                </div>
                <div className="table-responsive">
                  <Table className="table activity-tabel mb-0 ind-table tb2  table-hover scroll-table small-scroll custom-with-c">
                    <thead>
                      <tr>
                        <th
                          className="text-center cursor-pointer"
                          onClick={() =>
                            sortingExceptionHandle("exception_description", "")
                          }
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Exception Description</div>
                            <div className="ms-1">
                              {sortExceType === "asc" &&
                              sortExceName === "exception_description" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          className="text-center cursor-pointer"
                          onClick={() =>
                            sortingExceptionHandle("critical_exceptions", "")
                          }
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Critical</div>
                            <div className="ms-1">
                              {sortExceType === "asc" &&
                              sortExceName === "critical_exceptions" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          className="text-center cursor-pointer"
                          onClick={() =>
                            sortingExceptionHandle("warning_exceptions", "")
                          }
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Warning</div>
                            <div className="ms-1">
                              {sortExceType === "asc" &&
                              sortExceName === "warning_exceptions" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          className="text-center cursor-pointer"
                          onClick={() =>
                            sortingExceptionHandle(
                              "informational_exceptions",
                              ""
                            )
                          }
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Informational</div>
                            <div className="ms-1">
                              {sortExceType === "asc" &&
                              sortExceName === "informational_exceptions" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {/* <tbody>
                  {exceptionData?.map((val, ind) => {
                    return (
                      <tr>
                        <td>{val.exception_description}</td>
                        <td className="text-center">
                          {val.critical_exceptions}
                          <span className="circle size-11 red-circle"></span>
                        </td>
                        <td className="text-center">
                          {val.warning_exceptions}
                          <span className="circle size-11 yellow-circle"></span>
                        </td>
                        <td className="text-center flag">
                          {val.informational_exceptions}
                          <span className="circle size-11 blue-circle"></span>
                        </td>
                        <td className="text-center flag">
                          {val.total_exceptions}
                          <span className="circle size-11 blue-circle"></span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody> */}
                    <tbody>
                      {!isLoadingTwo ? (
                        exceptionData?.length > 0 ? (
                          exceptionData
                            ?.filter((item) => item.total_exceptions !== 0)
                            ?.map((val, ind) => {
                              return (
                                <tr
                                  style={{ cursor: "pointer" }}
                                  className={
                                    selectSeverity?._id === val?._id
                                      ? "selected-active-row"
                                      : ""
                                  }
                                  // onClick={() => !momoStack ? handlExceptional(val, "severity") : ""}
                                  onClick={() =>
                                    handlExceptional(val, "severity")
                                  }
                                  // onClick={() => {
                                  //   setExceptionalFlow({ detail: false, date: false, severity: true })
                                  //   exceptionalFlow.severity && handlExceptional(val)
                                  // }}
                                >
                                  <td>{val.exception_description}</td>
                                  <td className="text-center">
                                    {val.critical_exceptions > 0 ? (
                                      <>
                                        {val.critical_exceptions}
                                        <span className="circle size-11 red-circle"></span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {val.warning_exceptions > 0 ? (
                                      <>
                                        {val.warning_exceptions}
                                        <span className="circle size-11 yellow-circle"></span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  {/* <td></td> */}
                                  <td className="text-center flag">
                                    {val.informational_exceptions > 0 ? (
                                      <>
                                        {val.informational_exceptions}
                                        <span className="circle size-11 blue-circle"></span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "200px" }}
                          >
                            <p className="text-secondary display-6">
                              No Data Found
                            </p>
                          </div>
                        )
                      ) : (
                        <div style={{ width: "100%" }}>
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                        </div>
                      )}
                    </tbody>
                    <tbody className="ftr">
                      {isLoadingTwo ? (
                        <div>
                          <TableLoader themeMode={themeMode} />
                        </div>
                      ) : totalvalueTwo ? (
                        <tr className="bt">
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td className="text-center">
                            <strong style={{ marginRight: "12px" }}>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                              }).format(
                                get(totalvalueTwo, "critical_exceptions", 0)
                              )}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong style={{ marginRight: "12px" }}>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                              }).format(
                                get(totalvalueTwo, "warning_exceptions", 0)
                              )}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong style={{ marginRight: "12px" }}>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                              }).format(
                                get(
                                  totalvalueTwo,
                                  "informational_exceptions",
                                  0
                                )
                              )}
                            </strong>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              {/* <span>{headData?.current_file_msg}</span> */}
              <div className="col-12 text-end bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
              {/* <span className="col-6"><span className=" text-start  p-2 bg-white d-block">{headData?.upcomming_file_msg}</span></span> */}
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default ExceptionsDetails;
